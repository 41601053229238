export const shrinkMarks = {
  0: "none",
  1: "5ft",
  2: "3ft",
  3: "1ft",
  4: "6in",
  5: "3in",
  6: "1in",
  7: "1/2in",
  8: "1/16in↓",
};
