import { AuthState } from "@aws-amplify/ui-components";
import React from "react";
import { atom, selector } from "recoil";

export const authAtom = atom<AuthState | undefined>({
  key: "authState",
  default: undefined,
});

export interface User {
  username: string;
  email: string;
  groups: string[];
}

export const userAtom = atom<User | undefined>({
  key: "userState",
  default: undefined,
});

export const isAdminAtom = selector({
  key: "isAdmin",
  get: ({ get }) => {
    const user = get(userAtom);
    return user?.groups.includes("admin");
  },
});

export const modalAtom = atom<React.ReactElement | undefined>({
  key: "modal",
  default: undefined,
});
