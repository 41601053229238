/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAuthor = /* GraphQL */ `
  query GetAuthor($id: ID!) {
    getAuthor(id: $id) {
      id
      name
      nameLowerCase
      cognitoUsername
      createdAt
      updatedAt
      owner
      FaveStories {
        nextToken
      }
      Ratings {
        nextToken
      }
      Stories {
        nextToken
      }
    }
  }
`;
export const listAuthors = /* GraphQL */ `
  query ListAuthors(
    $filter: ModelAuthorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuthors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      typename
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const listCategorys = /* GraphQL */ `
  query ListCategorys(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        typename
        name
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const categoriesSrtByName = /* GraphQL */ `
  query CategoriesSrtByName(
    $typename: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    categoriesSrtByName(
      typename: $typename
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        typename
        name
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChapter = /* GraphQL */ `
  query GetChapter($id: ID!) {
    getChapter(id: $id) {
      id
      title
      body
      authorNotesBefore
      authorNotesAfter
      isPublished
      order
      storyID
      createdAt
      updatedAt
      owner
      Story {
        id
        title
        description
        authorID
        categories
        rating
        growth
        shrink
        sizeRoles
        isComplete
        isPublished
        score
        hotScore
        chapterCount
        wordCount
        authorDisplayName
        lastChapterAdded
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const listChapters = /* GraphQL */ `
  query ListChapters(
    $filter: ModelChapterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChapters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        body
        authorNotesBefore
        authorNotesAfter
        isPublished
        order
        storyID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const chaptersByOrder = /* GraphQL */ `
  query ChaptersByOrder(
    $storyID: ID
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChapterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chaptersByOrder(
      storyID: $storyID
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        body
        authorNotesBefore
        authorNotesAfter
        isPublished
        order
        storyID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      body
      delta
      authorID
      authorDisplayName
      storyID
      chapterID
      parentID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        body
        delta
        authorID
        authorDisplayName
        storyID
        chapterID
        parentID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const commentsByStory = /* GraphQL */ `
  query CommentsByStory(
    $storyID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByStory(
      storyID: $storyID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        body
        delta
        authorID
        authorDisplayName
        storyID
        chapterID
        parentID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const commentsByChapter = /* GraphQL */ `
  query CommentsByChapter(
    $chapterID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByChapter(
      chapterID: $chapterID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        body
        delta
        authorID
        authorDisplayName
        storyID
        chapterID
        parentID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getFaveAuthor = /* GraphQL */ `
  query GetFaveAuthor($id: ID!) {
    getFaveAuthor(id: $id) {
      id
      favedBy
      authorID
      createdAt
      updatedAt
      Author {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const listFaveAuthors = /* GraphQL */ `
  query ListFaveAuthors(
    $filter: ModelFaveAuthorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFaveAuthors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        favedBy
        authorID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getFaveAuthorStoryUpdate = /* GraphQL */ `
  query GetFaveAuthorStoryUpdate($id: ID!) {
    getFaveAuthorStoryUpdate(id: $id) {
      id
      storyID
      authorID
      isNew
      createdAt
      updatedAt
      Author {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      owner
      Story {
        id
        title
        description
        authorID
        categories
        rating
        growth
        shrink
        sizeRoles
        isComplete
        isPublished
        score
        hotScore
        chapterCount
        wordCount
        authorDisplayName
        lastChapterAdded
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const listFaveAuthorStoryUpdates = /* GraphQL */ `
  query ListFaveAuthorStoryUpdates(
    $filter: ModelFaveAuthorStoryUpdateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFaveAuthorStoryUpdates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        storyID
        authorID
        isNew
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getFaveStory = /* GraphQL */ `
  query GetFaveStory($id: ID!) {
    getFaveStory(id: $id) {
      id
      storyID
      authorID
      totalChapters
      readChapters
      hasUnreadChapters
      createdAt
      updatedAt
      Author {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      owner
      Story {
        id
        title
        description
        authorID
        categories
        rating
        growth
        shrink
        sizeRoles
        isComplete
        isPublished
        score
        hotScore
        chapterCount
        wordCount
        authorDisplayName
        lastChapterAdded
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const listFaveStorys = /* GraphQL */ `
  query ListFaveStorys(
    $filter: ModelFaveStoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFaveStorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        storyID
        authorID
        totalChapters
        readChapters
        hasUnreadChapters
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const faveStoriesByAuthor = /* GraphQL */ `
  query FaveStoriesByAuthor(
    $authorID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFaveStoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    faveStoriesByAuthor(
      authorID: $authorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        storyID
        authorID
        totalChapters
        readChapters
        hasUnreadChapters
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getMention = /* GraphQL */ `
  query GetMention($id: ID!) {
    getMention(id: $id) {
      id
      mentionerID
      mentionedID
      mentionedIDandStatus
      commentID
      status
      storyID
      chapterID
      mentionText
      createdAt
      updatedAt
      Mentioner {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      Chapter {
        id
        title
        body
        authorNotesBefore
        authorNotesAfter
        isPublished
        order
        storyID
        createdAt
        updatedAt
        owner
      }
      Comment {
        id
        body
        delta
        authorID
        authorDisplayName
        storyID
        chapterID
        parentID
        createdAt
        updatedAt
        owner
      }
      owner
      Story {
        id
        title
        description
        authorID
        categories
        rating
        growth
        shrink
        sizeRoles
        isComplete
        isPublished
        score
        hotScore
        chapterCount
        wordCount
        authorDisplayName
        lastChapterAdded
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const listMentions = /* GraphQL */ `
  query ListMentions(
    $filter: ModelMentionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMentions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mentionerID
        mentionedID
        mentionedIDandStatus
        commentID
        status
        storyID
        chapterID
        mentionText
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const mentionsByMentionedIDandStatus = /* GraphQL */ `
  query MentionsByMentionedIDandStatus(
    $mentionedIDandStatus: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMentionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mentionsByMentionedIDandStatus(
      mentionedIDandStatus: $mentionedIDandStatus
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mentionerID
        mentionedID
        mentionedIDandStatus
        commentID
        status
        storyID
        chapterID
        mentionText
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getRating = /* GraphQL */ `
  query GetRating($id: ID!) {
    getRating(id: $id) {
      id
      storyID
      authorID
      rating
      createdAt
      updatedAt
      Author {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      owner
      Story {
        id
        title
        description
        authorID
        categories
        rating
        growth
        shrink
        sizeRoles
        isComplete
        isPublished
        score
        hotScore
        chapterCount
        wordCount
        authorDisplayName
        lastChapterAdded
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const listRatings = /* GraphQL */ `
  query ListRatings(
    $filter: ModelRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRatings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        storyID
        authorID
        rating
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getReadStory = /* GraphQL */ `
  query GetReadStory($id: ID!) {
    getReadStory(id: $id) {
      id
      storyID
      authorID
      maxChapterIndex
      createdAt
      updatedAt
      Author {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      owner
      Story {
        id
        title
        description
        authorID
        categories
        rating
        growth
        shrink
        sizeRoles
        isComplete
        isPublished
        score
        hotScore
        chapterCount
        wordCount
        authorDisplayName
        lastChapterAdded
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const listReadStorys = /* GraphQL */ `
  query ListReadStorys(
    $filter: ModelReadStoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReadStorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        storyID
        authorID
        maxChapterIndex
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const readStoriesByAuthor = /* GraphQL */ `
  query ReadStoriesByAuthor(
    $authorID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelReadStoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    readStoriesByAuthor(
      authorID: $authorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        storyID
        authorID
        maxChapterIndex
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getStory = /* GraphQL */ `
  query GetStory($id: ID!) {
    getStory(id: $id) {
      id
      title
      description
      authorID
      categories
      rating
      growth
      shrink
      sizeRoles
      isComplete
      isPublished
      score
      hotScore
      chapterCount
      wordCount
      authorDisplayName
      lastChapterAdded
      createdAt
      updatedAt
      Author {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      Chapters {
        nextToken
      }
      FaveStories {
        nextToken
      }
      Ratings {
        nextToken
      }
      owner
    }
  }
`;
export const listStorys = /* GraphQL */ `
  query ListStorys(
    $filter: ModelStoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        authorID
        categories
        rating
        growth
        shrink
        sizeRoles
        isComplete
        isPublished
        score
        hotScore
        chapterCount
        wordCount
        authorDisplayName
        lastChapterAdded
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getSuggestion = /* GraphQL */ `
  query GetSuggestion($id: ID!) {
    getSuggestion(id: $id) {
      id
      toID
      authorID
      storyID
      chapterID
      body
      status
      message
      reply
      createdAt
      updatedAt
      Author {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      Chapter {
        id
        title
        body
        authorNotesBefore
        authorNotesAfter
        isPublished
        order
        storyID
        createdAt
        updatedAt
        owner
      }
      Story {
        id
        title
        description
        authorID
        categories
        rating
        growth
        shrink
        sizeRoles
        isComplete
        isPublished
        score
        hotScore
        chapterCount
        wordCount
        authorDisplayName
        lastChapterAdded
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const listSuggestions = /* GraphQL */ `
  query ListSuggestions(
    $filter: ModelSuggestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSuggestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        toID
        authorID
        storyID
        chapterID
        body
        status
        message
        reply
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const suggestionsByToID = /* GraphQL */ `
  query SuggestionsByToID(
    $toID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSuggestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    suggestionsByToID(
      toID: $toID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        toID
        authorID
        storyID
        chapterID
        body
        status
        message
        reply
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
