import { Auth } from "aws-amplify";
import React from "react";
import { useSetRecoilState } from "recoil";
import useCurrentAuthor from "../../api/useCurrentAuthor";
import { modalAtom } from "../../atoms/atoms";
import EditAuthorNameModal from "../../components/AuthorDisplayNameModal/EditAuthorNameModal";
import { routes } from "../../ui/routes";
import history from "../../util/history";

const Profile: React.FC = () => {
  const currentAuthor = useCurrentAuthor();
  const setModal = useSetRecoilState(modalAtom);
  const signOut = async () => {
    await Auth.signOut();
    history.push(routes.authenticate.path);
  };
  if (!currentAuthor) return <div className="h-screen flex justify-center items-center">Loading...</div>;
  return (
    <div className="p-4 max-w-screen-lg mx-auto">
      <div className="flex justify-between pt-3.5">
        <h1 className="text-lg font-bold">{currentAuthor?.name}'s Profile</h1>
        <button onClick={signOut} className="btn-default">
          Sign Out
        </button>
      </div>
      <button onClick={() => setModal(<EditAuthorNameModal />)} className="btn-primary">
        Change Display Name
      </button>
    </div>
  );
};

export default Profile;
