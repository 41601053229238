/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthState } from "@aws-amplify/ui-components";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authAtom, userAtom } from "../../atoms/atoms";
import { routes } from "../../ui/routes";

const ProtectedRoute = ({ comp: Component, redirectMessage, path, redirectTo, requiredGroup, ...rest }: any) => {
  const authState = useRecoilValue(authAtom);
  const userState = useRecoilValue(userAtom);
  const to = redirectTo ? redirectTo : routes.authenticate.path;
  const redirectLocationUrl = encodeURIComponent(path);
  const redirectMessageUrl =
    redirectMessage === undefined ? "Please sign in first" : encodeURIComponent(redirectMessage);
  const isNotAllowed = (() => {
    if (requiredGroup && !userState?.groups.includes(requiredGroup)) return true;
    if (authState !== AuthState.SignedIn) return true;
    return false;
  })();

  return (
    <Route
      {...rest}
      render={(props) =>
        isNotAllowed ? (
          <Redirect to={`${to}?redirect=${redirectLocationUrl}&message=${redirectMessageUrl}`} />
        ) : (
          <Component path={path} {...props} />
        )
      }
    />
  );
};
export default ProtectedRoute;
