export const listStorys = /* GraphQL */ `
  query ListStorys($filter: ModelStoryFilterInput, $limit: Int, $nextToken: String) {
    listStorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        authorID
        categories
        createdAt
        updatedAt
        owner
        Author {
          id
          name
        }
      }
      nextToken
    }
  }
`;
export const listFaveStorys = /* GraphQL */ `
  query ListFaveStorys($filter: ModelFaveStoryFilterInput, $limit: Int, $nextToken: String) {
    listFaveStorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        storyID
        authorID
        totalChapters
        readChapters
        hasUnreadChapters
        Story {
          id
          title
          updatedAt
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;

export const listFaveWithFullStory = /* GraphQL */ `
  query ListFaveStorys($filter: ModelFaveStoryFilterInput, $limit: Int, $nextToken: String) {
    listFaveStorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Story {
          id
          title
          description
          authorID
          authorDisplayName
          categories
          rating
          growth
          shrink
          sizeRoles
          isComplete
          isPublished
          score
          chapterCount
          wordCount
          createdAt
          updatedAt
          owner
        }
      }
      nextToken
    }
  }
`;

export const listFaveAuthorsWithAuthorName = /* GraphQL */ `
  query ListFaveAuthors($filter: ModelFaveAuthorFilterInput, $limit: Int, $nextToken: String) {
    listFaveAuthors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        favedBy
        authorID
        createdAt
        updatedAt
        owner
        Author {
          name
        }
      }
      nextToken
    }
  }
`;

export const listFaveAuthorStoryUpdatesWithStory = /* GraphQL */ `
  query ListFaveAuthorStoryUpdates($filter: ModelFaveAuthorStoryUpdateFilterInput, $limit: Int, $nextToken: String) {
    listFaveAuthorStoryUpdates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        storyID
        authorID
        createdAt
        Story {
          id
          title
          authorID
          authorDisplayName
        }
      }
      nextToken
    }
  }
`;

export const suggestionsByToID = /* GraphQL */ `
  query SuggestionsByToID(
    $toID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSuggestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    suggestionsByToID(
      toID: $toID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        toID
        authorID
        storyID
        chapterID
        body
        status
        message
        createdAt
        updatedAt
        owner
        Story {
          title
        }
        Author {
          name
        }
      }
      nextToken
    }
  }
`;
