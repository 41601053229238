import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Comment, CommentsByChapterQueryVariables, CommentsByStoryQueryVariables, ModelSortDirection } from "../../API";
import useListQuery from "../../api/useListQuery";
import { commentsByChapter, commentsByStory } from "../../graphql/queries";
import { createTree } from "../../util/createTree";
import CommentForm from "./CommentForm";
import CommentItem from "./CommentItem";

interface Props {
  chapterID: string;
}

const Comments: React.FC<Props> = ({ chapterID }) => {
  const { id } = useParams<{ id: string }>();
  const [display, setDisplay] = useState("story");
  const [comments, setComments] = useState<Comment[]>([]);
  const byStory = useListQuery<Comment, CommentsByStoryQueryVariables>(commentsByStory, "commentsByStory", {
    storyID: id,
    sortDirection: ModelSortDirection.ASC,
  });
  const byChapter = useListQuery<Comment, CommentsByChapterQueryVariables>(commentsByChapter, "commentsByChapter", {
    chapterID: chapterID,
    sortDirection: ModelSortDirection.ASC,
  });

  useEffect(() => {
    if (display === "story" && byStory.data) {
      setComments(createTree(byStory.data));
    }
    if (display === "chapter" && byChapter.data) {
      setComments(createTree(byChapter.data));
    }
  }, [byStory.data, byChapter.data, display]);

  const storyButtonClasses = classNames("py-1 text-sm focus:outline-none", {
    "font-bold text-pink-600": display === "story",
  });
  const chapterButtonClasses = classNames("py-1 text-sm focus:outline-none", {
    "font-bold text-pink-600": display === "chapter",
  });

  return (
    <div className="py-4">
      <div className="flex mb-2 space-x-4">
        <button onClick={() => setDisplay("story")} className={storyButtonClasses}>
          {byStory && byStory.data ? byStory.data.length : 0} Comments
        </button>
        <button onClick={() => setDisplay("chapter")} className={chapterButtonClasses}>
          {byChapter && byChapter.data ? byChapter.data.length : 0} Chapter Comments
        </button>
      </div>
      <div className="mb-4">
        <CommentForm chapterID={chapterID} showForm={true} rootForm={true} />
      </div>
      <div className="flex flex-col space-y-6">
        {comments.map((comment) => (
          <CommentItem
            key={comment.id}
            comment={{ ...comment, __typename: "Comment" }}
            chapterID={chapterID}
            showForm={true}
          />
        ))}
      </div>
    </div>
  );
};

export default Comments;
