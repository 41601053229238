import { useQueryClient } from "react-query";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { UpdateAuthorInput, UpdateAuthorMutation } from "../../API";
import useApiMutation from "../../api/useApiMutation";
import { modalAtom, userAtom } from "../../atoms/atoms";
import * as mutations from "../../graphql/mutations";
import AuthorNameForm from "../AuthorNameForm/AuthorNameForm";
import ModalWrapper from "../ModalWrapper/ModalWrapper";

const EditAuthorNameModal: React.FC = () => {
  const queryClient = useQueryClient();
  const setModal = useSetRecoilState(modalAtom);
  const user = useRecoilValue(userAtom);

  const updateAuthor = useApiMutation<UpdateAuthorMutation, UpdateAuthorInput>(mutations.updateAuthor);

  const save = (name: string, nameIsTaken: boolean) => {
    if (!user || !user.username) {
      alert(
        "Well this is awkward. We could not retrieve your cognito userID. This should hopefully never happen...but if it does, good luck!",
      );
      return;
    }
    updateAuthor.mutate(
      {
        id: user.username,
        name: name,
        nameLowerCase: name.toLowerCase(),
      },
      {
        onSuccess: (data) => {
          queryClient.setQueryData(
            ["listAuthors", { filter: { cognitoUsername: { eq: user.username } } }],
            data.updateAuthor,
          );
          queryClient.invalidateQueries("listAuthors");
          setModal(undefined);
        },
        onError: (err) => {
          // eslint-disable-next-line
          console.log(err);
          alert("Error. Could not create profile.");
        },
      },
    );
  };

  return (
    <ModalWrapper showCloseButton>
      <div>
        <h1 className="text-3xl font-bold">Display Name</h1>
        <h2 className="text-lg text-gray-700 dark:text-gray-400">Change your display name below</h2>
        <AuthorNameForm onSave={save} />
        <button onClick={() => setModal(undefined)} className="btn-default ml-2">
          Cancel
        </button>
      </div>
    </ModalWrapper>
  );
};

export default EditAuthorNameModal;
