import classNames from "classnames";
import React from "react";
import { NavLink } from "react-router-dom";
import { routes } from "../ui/routes";

interface Props {
  children: React.ReactNode;
}

const AdminLayout: React.FC<Props> = ({ children }) => {
  const activeNavClasses = classNames("text-pink-600");
  return (
    <div className="flex">
      <div className=" side-bar">
        <NavLink to={routes.admin.path} activeClassName={activeNavClasses} exact>
          Admin Dashboard
        </NavLink>
        <NavLink to={routes.admin.storyCategories.path} activeClassName={activeNavClasses}>
          Story Categories
        </NavLink>
      </div>
      <div className="flex-grow">{children}</div>
    </div>
  );
};

export default AdminLayout;
