/* eslint-disable @typescript-eslint/no-explicit-any */
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { AuthState } from "@aws-amplify/ui-components";
import { API } from "aws-amplify";
import { useRecoilValue } from "recoil";
import { Author } from "../API";
import { authAtom } from "../atoms/atoms";
import { listAuthors } from "../graphql/queries";

const useSearchAuthors = () => {
  //const usersearch = useApiMutation<ListAuthorsQuery, ListAuthorsQueryVariables>(listAuthors);
  const authState = useRecoilValue(authAtom);

  const search = async (searchTerm: string) => {
    const query = await API.graphql({
      authMode:
        authState === AuthState.SignedIn ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS : GRAPHQL_AUTH_MODE.AWS_IAM,
      query: listAuthors,
      variables: { filter: { nameLowerCase: { contains: searchTerm.toLowerCase() } }, limit: 25, nextToken: null },
    });

    console.log(query.data);
    //If no data, return empty array
    if (!query.data) return [];

    const formattedData = query.data.listAuthors.items.map((author: Author) => ({ id: author.id, value: author.name }));
    return formattedData;
  };

  return search;
};

export default useSearchAuthors;
