import React from "react";
import { useQueryClient } from "react-query";
import { useSetRecoilState } from "recoil";
import { CreateCategoryInput, CreateCategoryMutation } from "../../API";
import useApiMutation from "../../api/useApiMutation";
import { modalAtom } from "../../atoms/atoms";
import ModalWrapper from "../../components/ModalWrapper/ModalWrapper";
import * as mutations from "../../graphql/mutations";
import StoryCategoryForm, { StoryCategory } from "./StoryCategoryForm";

const StoryCategoryNew: React.FC = () => {
  const setModal = useSetRecoilState(modalAtom);
  const queryClient = useQueryClient();
  const createCategory = useApiMutation<CreateCategoryMutation, CreateCategoryInput>(mutations.createCategory);

  const handleSave = ({ name, description }: StoryCategory) => {
    createCategory.mutate(
      { name, description, typename: "Category" },
      {
        onSuccess: () => {
          setModal(undefined);
          queryClient.invalidateQueries("categoriesSrtByName");
        },
        onError: (err) => alert(err),
      },
    );
  };
  return (
    <ModalWrapper showCloseButton title="Add New Category">
      <div className=" sm:w-96">
        <StoryCategoryForm initState={{ name: "", description: "" }} onSubmit={handleSave} buttonText="Save" />
      </div>
    </ModalWrapper>
  );
};

export default StoryCategoryNew;
