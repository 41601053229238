import InfoTwoToneIcon from "@material-ui/icons/InfoTwoTone";
import React from "react";
import useQueryParam from "../../hooks/useQueryParam";

const Authenticator: React.FC = () => {
  const [message] = useQueryParam("message", "");
  if (!message) return null;
  return (
    <div className="p-4">
      <div className="flex items-center space-x-2 justify-center max-w-[460px] mx-auto bg-pink-50 text-center text-pink-600 rounded border border-pink-600 py-2 mb-4 text-lg">
        <InfoTwoToneIcon />
        <span>{message}</span>
      </div>
    </div>
  );
};

export default Authenticator;
