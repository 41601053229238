/* eslint-disable @typescript-eslint/no-explicit-any */
import { GraphQLResult, GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { AuthState } from "@aws-amplify/ui-components";
import { API } from "aws-amplify";
import { useQuery, UseQueryOptions } from "react-query";
import { useRecoilValue } from "recoil";
import { authAtom } from "../atoms/atoms";

type GenericQuery = {
  [key: string]: {
    __typename: string;
    items?: any[];
    nextToken?: string | null;
    startedAt?: number | null;
  };
};

function useGetQuery<Model>(
  graphQlQuery: string,
  key: keyof GenericQuery,
  id: string,
  options?: UseQueryOptions<Model>,
) {
  const authState = useRecoilValue(authAtom);

  const fetch = async (): Promise<Model> => {
    //Query amplify
    const query = (await API.graphql({
      authMode:
        authState === AuthState.SignedIn ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS : GRAPHQL_AUTH_MODE.AWS_IAM,
      query: graphQlQuery,
      variables: { id },
    })) as GraphQLResult<GenericQuery>;

    //If no data, return empty array
    if (!query.data) throw new Error("Could not find");

    //Return results
    return (query.data[key] as unknown) as Model;
  };

  const query = useQuery<Model>([key, id], fetch, { keepPreviousData: true, ...options });
  return query;
}

export default useGetQuery;
