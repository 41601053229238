/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateAuthorInput = {
  id?: string | null,
  name: string,
  nameLowerCase: string,
  cognitoUsername?: string | null,
};

export type ModelAuthorConditionInput = {
  name?: ModelStringInput | null,
  nameLowerCase?: ModelStringInput | null,
  cognitoUsername?: ModelStringInput | null,
  and?: Array< ModelAuthorConditionInput | null > | null,
  or?: Array< ModelAuthorConditionInput | null > | null,
  not?: ModelAuthorConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type Author = {
  __typename: "Author",
  id: string,
  name: string,
  nameLowerCase: string,
  cognitoUsername?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
  FaveStories?: ModelFaveStoryConnection | null,
  Ratings?: ModelRatingConnection | null,
  Stories?: ModelStoryConnection | null,
};

export type ModelFaveStoryConnection = {
  __typename: "ModelFaveStoryConnection",
  items?:  Array<FaveStory | null > | null,
  nextToken?: string | null,
};

export type FaveStory = {
  __typename: "FaveStory",
  id: string,
  storyID: string,
  authorID: string,
  totalChapters: number,
  readChapters: number,
  hasUnreadChapters: boolean,
  createdAt: string,
  updatedAt: string,
  Author: Author,
  owner?: string | null,
  Story: Story,
};

export type Story = {
  __typename: "Story",
  id: string,
  title: string,
  description?: string | null,
  authorID?: string | null,
  categories?: Array< string > | null,
  rating?: string | null,
  growth?: Array< string > | null,
  shrink?: Array< string > | null,
  sizeRoles?: Array< string > | null,
  isComplete?: boolean | null,
  isPublished: boolean,
  score: number,
  hotScore?: number | null,
  chapterCount?: number | null,
  wordCount?: number | null,
  authorDisplayName: string,
  lastChapterAdded?: string | null,
  createdAt: string,
  updatedAt: string,
  Author?: Author | null,
  Chapters?: ModelChapterConnection | null,
  FaveStories?: ModelFaveStoryConnection | null,
  Ratings?: ModelRatingConnection | null,
  owner?: string | null,
};

export type ModelChapterConnection = {
  __typename: "ModelChapterConnection",
  items?:  Array<Chapter | null > | null,
  nextToken?: string | null,
};

export type Chapter = {
  __typename: "Chapter",
  id: string,
  title: string,
  body?: string | null,
  authorNotesBefore?: string | null,
  authorNotesAfter?: string | null,
  isPublished: boolean,
  order: number,
  storyID: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
  Story: Story,
};

export type ModelRatingConnection = {
  __typename: "ModelRatingConnection",
  items?:  Array<Rating | null > | null,
  nextToken?: string | null,
};

export type Rating = {
  __typename: "Rating",
  id: string,
  storyID: string,
  authorID: string,
  rating: number,
  createdAt: string,
  updatedAt: string,
  Author: Author,
  owner?: string | null,
  Story: Story,
};

export type ModelStoryConnection = {
  __typename: "ModelStoryConnection",
  items?:  Array<Story | null > | null,
  nextToken?: string | null,
};

export type UpdateAuthorInput = {
  id: string,
  name?: string | null,
  nameLowerCase?: string | null,
  cognitoUsername?: string | null,
};

export type DeleteAuthorInput = {
  id: string,
};

export type CreateCategoryInput = {
  id?: string | null,
  typename: string,
  name: string,
  description?: string | null,
};

export type ModelCategoryConditionInput = {
  typename?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelCategoryConditionInput | null > | null,
  or?: Array< ModelCategoryConditionInput | null > | null,
  not?: ModelCategoryConditionInput | null,
};

export type Category = {
  __typename: "Category",
  id: string,
  typename: string,
  name: string,
  description?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateCategoryInput = {
  id: string,
  typename?: string | null,
  name?: string | null,
  description?: string | null,
};

export type DeleteCategoryInput = {
  id: string,
};

export type CreateChapterInput = {
  id?: string | null,
  title: string,
  body?: string | null,
  authorNotesBefore?: string | null,
  authorNotesAfter?: string | null,
  isPublished: boolean,
  order: number,
  storyID: string,
};

export type ModelChapterConditionInput = {
  title?: ModelStringInput | null,
  body?: ModelStringInput | null,
  authorNotesBefore?: ModelStringInput | null,
  authorNotesAfter?: ModelStringInput | null,
  isPublished?: ModelBooleanInput | null,
  order?: ModelIntInput | null,
  storyID?: ModelIDInput | null,
  and?: Array< ModelChapterConditionInput | null > | null,
  or?: Array< ModelChapterConditionInput | null > | null,
  not?: ModelChapterConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type UpdateChapterInput = {
  id: string,
  title?: string | null,
  body?: string | null,
  authorNotesBefore?: string | null,
  authorNotesAfter?: string | null,
  isPublished?: boolean | null,
  order?: number | null,
  storyID?: string | null,
};

export type DeleteChapterInput = {
  id: string,
};

export type CreateCommentInput = {
  id?: string | null,
  body: string,
  delta: string,
  authorID: string,
  authorDisplayName: string,
  storyID: string,
  chapterID: string,
  parentID?: string | null,
  createdAt?: string | null,
};

export type ModelCommentConditionInput = {
  body?: ModelStringInput | null,
  delta?: ModelStringInput | null,
  authorID?: ModelIDInput | null,
  authorDisplayName?: ModelStringInput | null,
  storyID?: ModelIDInput | null,
  chapterID?: ModelIDInput | null,
  parentID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCommentConditionInput | null > | null,
  or?: Array< ModelCommentConditionInput | null > | null,
  not?: ModelCommentConditionInput | null,
};

export type Comment = {
  __typename: "Comment",
  id: string,
  body: string,
  delta: string,
  authorID: string,
  authorDisplayName: string,
  storyID: string,
  chapterID: string,
  parentID?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateCommentInput = {
  id: string,
  body?: string | null,
  delta?: string | null,
  authorID?: string | null,
  authorDisplayName?: string | null,
  storyID?: string | null,
  chapterID?: string | null,
  parentID?: string | null,
  createdAt?: string | null,
};

export type DeleteCommentInput = {
  id: string,
};

export type CreateFaveAuthorInput = {
  id?: string | null,
  favedBy: string,
  authorID: string,
};

export type ModelFaveAuthorConditionInput = {
  favedBy?: ModelIDInput | null,
  authorID?: ModelIDInput | null,
  and?: Array< ModelFaveAuthorConditionInput | null > | null,
  or?: Array< ModelFaveAuthorConditionInput | null > | null,
  not?: ModelFaveAuthorConditionInput | null,
};

export type FaveAuthor = {
  __typename: "FaveAuthor",
  id: string,
  favedBy: string,
  authorID: string,
  createdAt: string,
  updatedAt: string,
  Author: Author,
  owner?: string | null,
};

export type UpdateFaveAuthorInput = {
  id: string,
  favedBy?: string | null,
  authorID?: string | null,
};

export type DeleteFaveAuthorInput = {
  id: string,
};

export type CreateFaveAuthorStoryUpdateInput = {
  id?: string | null,
  storyID: string,
  authorID: string,
  isNew: boolean,
};

export type ModelFaveAuthorStoryUpdateConditionInput = {
  storyID?: ModelIDInput | null,
  authorID?: ModelIDInput | null,
  isNew?: ModelBooleanInput | null,
  and?: Array< ModelFaveAuthorStoryUpdateConditionInput | null > | null,
  or?: Array< ModelFaveAuthorStoryUpdateConditionInput | null > | null,
  not?: ModelFaveAuthorStoryUpdateConditionInput | null,
};

export type FaveAuthorStoryUpdate = {
  __typename: "FaveAuthorStoryUpdate",
  id: string,
  storyID: string,
  authorID: string,
  isNew: boolean,
  createdAt: string,
  updatedAt: string,
  Author: Author,
  owner?: string | null,
  Story: Story,
};

export type UpdateFaveAuthorStoryUpdateInput = {
  id: string,
  storyID?: string | null,
  authorID?: string | null,
  isNew?: boolean | null,
};

export type DeleteFaveAuthorStoryUpdateInput = {
  id: string,
};

export type CreateFaveStoryInput = {
  id?: string | null,
  storyID: string,
  authorID: string,
  totalChapters: number,
  readChapters: number,
  hasUnreadChapters: boolean,
};

export type ModelFaveStoryConditionInput = {
  storyID?: ModelIDInput | null,
  authorID?: ModelIDInput | null,
  totalChapters?: ModelIntInput | null,
  readChapters?: ModelIntInput | null,
  hasUnreadChapters?: ModelBooleanInput | null,
  and?: Array< ModelFaveStoryConditionInput | null > | null,
  or?: Array< ModelFaveStoryConditionInput | null > | null,
  not?: ModelFaveStoryConditionInput | null,
};

export type UpdateFaveStoryInput = {
  id: string,
  storyID?: string | null,
  authorID?: string | null,
  totalChapters?: number | null,
  readChapters?: number | null,
  hasUnreadChapters?: boolean | null,
};

export type DeleteFaveStoryInput = {
  id: string,
};

export type CreateMentionInput = {
  id?: string | null,
  mentionerID: string,
  mentionedID: string,
  mentionedIDandStatus: string,
  commentID: string,
  status: MENTION_STATUS,
  storyID: string,
  chapterID: string,
  mentionText: string,
  createdAt?: string | null,
};

export enum MENTION_STATUS {
  UNSEEN = "UNSEEN",
  SEEN = "SEEN",
}


export type ModelMentionConditionInput = {
  mentionerID?: ModelIDInput | null,
  mentionedID?: ModelIDInput | null,
  mentionedIDandStatus?: ModelStringInput | null,
  commentID?: ModelIDInput | null,
  status?: ModelMENTION_STATUSInput | null,
  storyID?: ModelIDInput | null,
  chapterID?: ModelIDInput | null,
  mentionText?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMentionConditionInput | null > | null,
  or?: Array< ModelMentionConditionInput | null > | null,
  not?: ModelMentionConditionInput | null,
};

export type ModelMENTION_STATUSInput = {
  eq?: MENTION_STATUS | null,
  ne?: MENTION_STATUS | null,
};

export type Mention = {
  __typename: "Mention",
  id: string,
  mentionerID: string,
  mentionedID: string,
  mentionedIDandStatus: string,
  commentID: string,
  status: MENTION_STATUS,
  storyID: string,
  chapterID: string,
  mentionText: string,
  createdAt: string,
  updatedAt: string,
  Mentioner?: Author | null,
  Chapter?: Chapter | null,
  Comment?: Comment | null,
  owner?: string | null,
  Story?: Story | null,
};

export type UpdateMentionInput = {
  id: string,
  mentionerID?: string | null,
  mentionedID?: string | null,
  mentionedIDandStatus?: string | null,
  commentID?: string | null,
  status?: MENTION_STATUS | null,
  storyID?: string | null,
  chapterID?: string | null,
  mentionText?: string | null,
  createdAt?: string | null,
};

export type DeleteMentionInput = {
  id: string,
};

export type CreateRatingInput = {
  id?: string | null,
  storyID: string,
  authorID: string,
  rating: number,
};

export type ModelRatingConditionInput = {
  storyID?: ModelIDInput | null,
  authorID?: ModelIDInput | null,
  rating?: ModelIntInput | null,
  and?: Array< ModelRatingConditionInput | null > | null,
  or?: Array< ModelRatingConditionInput | null > | null,
  not?: ModelRatingConditionInput | null,
};

export type UpdateRatingInput = {
  id: string,
  storyID?: string | null,
  authorID?: string | null,
  rating?: number | null,
};

export type DeleteRatingInput = {
  id: string,
};

export type CreateReadStoryInput = {
  id?: string | null,
  storyID: string,
  authorID: string,
  maxChapterIndex: number,
};

export type ModelReadStoryConditionInput = {
  storyID?: ModelIDInput | null,
  authorID?: ModelIDInput | null,
  maxChapterIndex?: ModelIntInput | null,
  and?: Array< ModelReadStoryConditionInput | null > | null,
  or?: Array< ModelReadStoryConditionInput | null > | null,
  not?: ModelReadStoryConditionInput | null,
};

export type ReadStory = {
  __typename: "ReadStory",
  id: string,
  storyID: string,
  authorID: string,
  maxChapterIndex: number,
  createdAt: string,
  updatedAt: string,
  Author: Author,
  owner?: string | null,
  Story: Story,
};

export type UpdateReadStoryInput = {
  id: string,
  storyID?: string | null,
  authorID?: string | null,
  maxChapterIndex?: number | null,
};

export type DeleteReadStoryInput = {
  id: string,
};

export type CreateStoryInput = {
  id?: string | null,
  title: string,
  description?: string | null,
  authorID?: string | null,
  categories?: Array< string > | null,
  rating?: string | null,
  growth?: Array< string > | null,
  shrink?: Array< string > | null,
  sizeRoles?: Array< string > | null,
  isComplete?: boolean | null,
  isPublished: boolean,
  score: number,
  hotScore?: number | null,
  chapterCount?: number | null,
  wordCount?: number | null,
  authorDisplayName: string,
  lastChapterAdded?: string | null,
};

export type ModelStoryConditionInput = {
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  authorID?: ModelIDInput | null,
  categories?: ModelStringInput | null,
  rating?: ModelStringInput | null,
  growth?: ModelStringInput | null,
  shrink?: ModelStringInput | null,
  sizeRoles?: ModelStringInput | null,
  isComplete?: ModelBooleanInput | null,
  isPublished?: ModelBooleanInput | null,
  score?: ModelIntInput | null,
  hotScore?: ModelFloatInput | null,
  chapterCount?: ModelIntInput | null,
  wordCount?: ModelIntInput | null,
  authorDisplayName?: ModelStringInput | null,
  lastChapterAdded?: ModelStringInput | null,
  and?: Array< ModelStoryConditionInput | null > | null,
  or?: Array< ModelStoryConditionInput | null > | null,
  not?: ModelStoryConditionInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateStoryInput = {
  id: string,
  title?: string | null,
  description?: string | null,
  authorID?: string | null,
  categories?: Array< string > | null,
  rating?: string | null,
  growth?: Array< string > | null,
  shrink?: Array< string > | null,
  sizeRoles?: Array< string > | null,
  isComplete?: boolean | null,
  isPublished?: boolean | null,
  score?: number | null,
  hotScore?: number | null,
  chapterCount?: number | null,
  wordCount?: number | null,
  authorDisplayName?: string | null,
  lastChapterAdded?: string | null,
};

export type DeleteStoryInput = {
  id: string,
};

export type CreateSuggestionInput = {
  id?: string | null,
  toID: string,
  authorID: string,
  storyID: string,
  chapterID: string,
  body: string,
  status: SUGGESTION_STATUS,
  message: string,
  reply?: string | null,
};

export enum SUGGESTION_STATUS {
  PENDING = "PENDING",
  REJECTED = "REJECTED",
  MERGED = "MERGED",
}


export type ModelSuggestionConditionInput = {
  toID?: ModelIDInput | null,
  authorID?: ModelIDInput | null,
  storyID?: ModelIDInput | null,
  chapterID?: ModelIDInput | null,
  body?: ModelStringInput | null,
  status?: ModelSUGGESTION_STATUSInput | null,
  message?: ModelStringInput | null,
  reply?: ModelStringInput | null,
  and?: Array< ModelSuggestionConditionInput | null > | null,
  or?: Array< ModelSuggestionConditionInput | null > | null,
  not?: ModelSuggestionConditionInput | null,
};

export type ModelSUGGESTION_STATUSInput = {
  eq?: SUGGESTION_STATUS | null,
  ne?: SUGGESTION_STATUS | null,
};

export type Suggestion = {
  __typename: "Suggestion",
  id: string,
  toID: string,
  authorID: string,
  storyID: string,
  chapterID: string,
  body: string,
  status: SUGGESTION_STATUS,
  message: string,
  reply?: string | null,
  createdAt: string,
  updatedAt: string,
  Author: Author,
  Chapter?: Chapter | null,
  Story?: Story | null,
  owner?: string | null,
};

export type UpdateSuggestionInput = {
  id: string,
  toID?: string | null,
  authorID?: string | null,
  storyID?: string | null,
  chapterID?: string | null,
  body?: string | null,
  status?: SUGGESTION_STATUS | null,
  message?: string | null,
  reply?: string | null,
};

export type DeleteSuggestionInput = {
  id: string,
};

export type ModelAuthorFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  nameLowerCase?: ModelStringInput | null,
  cognitoUsername?: ModelStringInput | null,
  and?: Array< ModelAuthorFilterInput | null > | null,
  or?: Array< ModelAuthorFilterInput | null > | null,
  not?: ModelAuthorFilterInput | null,
};

export type ModelAuthorConnection = {
  __typename: "ModelAuthorConnection",
  items?:  Array<Author | null > | null,
  nextToken?: string | null,
};

export type ModelCategoryFilterInput = {
  id?: ModelIDInput | null,
  typename?: ModelStringInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  and?: Array< ModelCategoryFilterInput | null > | null,
  or?: Array< ModelCategoryFilterInput | null > | null,
  not?: ModelCategoryFilterInput | null,
};

export type ModelCategoryConnection = {
  __typename: "ModelCategoryConnection",
  items?:  Array<Category | null > | null,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelChapterFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  body?: ModelStringInput | null,
  authorNotesBefore?: ModelStringInput | null,
  authorNotesAfter?: ModelStringInput | null,
  isPublished?: ModelBooleanInput | null,
  order?: ModelIntInput | null,
  storyID?: ModelIDInput | null,
  and?: Array< ModelChapterFilterInput | null > | null,
  or?: Array< ModelChapterFilterInput | null > | null,
  not?: ModelChapterFilterInput | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelCommentFilterInput = {
  id?: ModelIDInput | null,
  body?: ModelStringInput | null,
  delta?: ModelStringInput | null,
  authorID?: ModelIDInput | null,
  authorDisplayName?: ModelStringInput | null,
  storyID?: ModelIDInput | null,
  chapterID?: ModelIDInput | null,
  parentID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCommentFilterInput | null > | null,
  or?: Array< ModelCommentFilterInput | null > | null,
  not?: ModelCommentFilterInput | null,
};

export type ModelCommentConnection = {
  __typename: "ModelCommentConnection",
  items?:  Array<Comment | null > | null,
  nextToken?: string | null,
};

export type ModelFaveAuthorFilterInput = {
  id?: ModelIDInput | null,
  favedBy?: ModelIDInput | null,
  authorID?: ModelIDInput | null,
  and?: Array< ModelFaveAuthorFilterInput | null > | null,
  or?: Array< ModelFaveAuthorFilterInput | null > | null,
  not?: ModelFaveAuthorFilterInput | null,
};

export type ModelFaveAuthorConnection = {
  __typename: "ModelFaveAuthorConnection",
  items?:  Array<FaveAuthor | null > | null,
  nextToken?: string | null,
};

export type ModelFaveAuthorStoryUpdateFilterInput = {
  id?: ModelIDInput | null,
  storyID?: ModelIDInput | null,
  authorID?: ModelIDInput | null,
  isNew?: ModelBooleanInput | null,
  and?: Array< ModelFaveAuthorStoryUpdateFilterInput | null > | null,
  or?: Array< ModelFaveAuthorStoryUpdateFilterInput | null > | null,
  not?: ModelFaveAuthorStoryUpdateFilterInput | null,
};

export type ModelFaveAuthorStoryUpdateConnection = {
  __typename: "ModelFaveAuthorStoryUpdateConnection",
  items?:  Array<FaveAuthorStoryUpdate | null > | null,
  nextToken?: string | null,
};

export type ModelFaveStoryFilterInput = {
  id?: ModelIDInput | null,
  storyID?: ModelIDInput | null,
  authorID?: ModelIDInput | null,
  totalChapters?: ModelIntInput | null,
  readChapters?: ModelIntInput | null,
  hasUnreadChapters?: ModelBooleanInput | null,
  and?: Array< ModelFaveStoryFilterInput | null > | null,
  or?: Array< ModelFaveStoryFilterInput | null > | null,
  not?: ModelFaveStoryFilterInput | null,
};

export type ModelMentionFilterInput = {
  id?: ModelIDInput | null,
  mentionerID?: ModelIDInput | null,
  mentionedID?: ModelIDInput | null,
  mentionedIDandStatus?: ModelStringInput | null,
  commentID?: ModelIDInput | null,
  status?: ModelMENTION_STATUSInput | null,
  storyID?: ModelIDInput | null,
  chapterID?: ModelIDInput | null,
  mentionText?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMentionFilterInput | null > | null,
  or?: Array< ModelMentionFilterInput | null > | null,
  not?: ModelMentionFilterInput | null,
};

export type ModelMentionConnection = {
  __typename: "ModelMentionConnection",
  items?:  Array<Mention | null > | null,
  nextToken?: string | null,
};

export type ModelRatingFilterInput = {
  id?: ModelIDInput | null,
  storyID?: ModelIDInput | null,
  authorID?: ModelIDInput | null,
  rating?: ModelIntInput | null,
  and?: Array< ModelRatingFilterInput | null > | null,
  or?: Array< ModelRatingFilterInput | null > | null,
  not?: ModelRatingFilterInput | null,
};

export type ModelReadStoryFilterInput = {
  id?: ModelIDInput | null,
  storyID?: ModelIDInput | null,
  authorID?: ModelIDInput | null,
  maxChapterIndex?: ModelIntInput | null,
  and?: Array< ModelReadStoryFilterInput | null > | null,
  or?: Array< ModelReadStoryFilterInput | null > | null,
  not?: ModelReadStoryFilterInput | null,
};

export type ModelReadStoryConnection = {
  __typename: "ModelReadStoryConnection",
  items?:  Array<ReadStory | null > | null,
  nextToken?: string | null,
};

export type ModelStoryFilterInput = {
  id?: ModelIDInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  authorID?: ModelIDInput | null,
  categories?: ModelStringInput | null,
  rating?: ModelStringInput | null,
  growth?: ModelStringInput | null,
  shrink?: ModelStringInput | null,
  sizeRoles?: ModelStringInput | null,
  isComplete?: ModelBooleanInput | null,
  isPublished?: ModelBooleanInput | null,
  score?: ModelIntInput | null,
  hotScore?: ModelFloatInput | null,
  chapterCount?: ModelIntInput | null,
  wordCount?: ModelIntInput | null,
  authorDisplayName?: ModelStringInput | null,
  lastChapterAdded?: ModelStringInput | null,
  and?: Array< ModelStoryFilterInput | null > | null,
  or?: Array< ModelStoryFilterInput | null > | null,
  not?: ModelStoryFilterInput | null,
};

export type ModelSuggestionFilterInput = {
  id?: ModelIDInput | null,
  toID?: ModelIDInput | null,
  authorID?: ModelIDInput | null,
  storyID?: ModelIDInput | null,
  chapterID?: ModelIDInput | null,
  body?: ModelStringInput | null,
  status?: ModelSUGGESTION_STATUSInput | null,
  message?: ModelStringInput | null,
  reply?: ModelStringInput | null,
  and?: Array< ModelSuggestionFilterInput | null > | null,
  or?: Array< ModelSuggestionFilterInput | null > | null,
  not?: ModelSuggestionFilterInput | null,
};

export type ModelSuggestionConnection = {
  __typename: "ModelSuggestionConnection",
  items?:  Array<Suggestion | null > | null,
  nextToken?: string | null,
};

export type CreateAuthorMutationVariables = {
  input: CreateAuthorInput,
  condition?: ModelAuthorConditionInput | null,
};

export type CreateAuthorMutation = {
  createAuthor?:  {
    __typename: "Author",
    id: string,
    name: string,
    nameLowerCase: string,
    cognitoUsername?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    FaveStories?:  {
      __typename: "ModelFaveStoryConnection",
      nextToken?: string | null,
    } | null,
    Ratings?:  {
      __typename: "ModelRatingConnection",
      nextToken?: string | null,
    } | null,
    Stories?:  {
      __typename: "ModelStoryConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateAuthorMutationVariables = {
  input: UpdateAuthorInput,
  condition?: ModelAuthorConditionInput | null,
};

export type UpdateAuthorMutation = {
  updateAuthor?:  {
    __typename: "Author",
    id: string,
    name: string,
    nameLowerCase: string,
    cognitoUsername?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    FaveStories?:  {
      __typename: "ModelFaveStoryConnection",
      nextToken?: string | null,
    } | null,
    Ratings?:  {
      __typename: "ModelRatingConnection",
      nextToken?: string | null,
    } | null,
    Stories?:  {
      __typename: "ModelStoryConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteAuthorMutationVariables = {
  input: DeleteAuthorInput,
  condition?: ModelAuthorConditionInput | null,
};

export type DeleteAuthorMutation = {
  deleteAuthor?:  {
    __typename: "Author",
    id: string,
    name: string,
    nameLowerCase: string,
    cognitoUsername?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    FaveStories?:  {
      __typename: "ModelFaveStoryConnection",
      nextToken?: string | null,
    } | null,
    Ratings?:  {
      __typename: "ModelRatingConnection",
      nextToken?: string | null,
    } | null,
    Stories?:  {
      __typename: "ModelStoryConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateCategoryMutationVariables = {
  input: CreateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type CreateCategoryMutation = {
  createCategory?:  {
    __typename: "Category",
    id: string,
    typename: string,
    name: string,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCategoryMutationVariables = {
  input: UpdateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type UpdateCategoryMutation = {
  updateCategory?:  {
    __typename: "Category",
    id: string,
    typename: string,
    name: string,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCategoryMutationVariables = {
  input: DeleteCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type DeleteCategoryMutation = {
  deleteCategory?:  {
    __typename: "Category",
    id: string,
    typename: string,
    name: string,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateChapterMutationVariables = {
  input: CreateChapterInput,
  condition?: ModelChapterConditionInput | null,
};

export type CreateChapterMutation = {
  createChapter?:  {
    __typename: "Chapter",
    id: string,
    title: string,
    body?: string | null,
    authorNotesBefore?: string | null,
    authorNotesAfter?: string | null,
    isPublished: boolean,
    order: number,
    storyID: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type UpdateChapterMutationVariables = {
  input: UpdateChapterInput,
  condition?: ModelChapterConditionInput | null,
};

export type UpdateChapterMutation = {
  updateChapter?:  {
    __typename: "Chapter",
    id: string,
    title: string,
    body?: string | null,
    authorNotesBefore?: string | null,
    authorNotesAfter?: string | null,
    isPublished: boolean,
    order: number,
    storyID: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type DeleteChapterMutationVariables = {
  input: DeleteChapterInput,
  condition?: ModelChapterConditionInput | null,
};

export type DeleteChapterMutation = {
  deleteChapter?:  {
    __typename: "Chapter",
    id: string,
    title: string,
    body?: string | null,
    authorNotesBefore?: string | null,
    authorNotesAfter?: string | null,
    isPublished: boolean,
    order: number,
    storyID: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type CreateCommentMutationVariables = {
  input: CreateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type CreateCommentMutation = {
  createComment?:  {
    __typename: "Comment",
    id: string,
    body: string,
    delta: string,
    authorID: string,
    authorDisplayName: string,
    storyID: string,
    chapterID: string,
    parentID?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCommentMutationVariables = {
  input: UpdateCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type UpdateCommentMutation = {
  updateComment?:  {
    __typename: "Comment",
    id: string,
    body: string,
    delta: string,
    authorID: string,
    authorDisplayName: string,
    storyID: string,
    chapterID: string,
    parentID?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCommentMutationVariables = {
  input: DeleteCommentInput,
  condition?: ModelCommentConditionInput | null,
};

export type DeleteCommentMutation = {
  deleteComment?:  {
    __typename: "Comment",
    id: string,
    body: string,
    delta: string,
    authorID: string,
    authorDisplayName: string,
    storyID: string,
    chapterID: string,
    parentID?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateFaveAuthorMutationVariables = {
  input: CreateFaveAuthorInput,
  condition?: ModelFaveAuthorConditionInput | null,
};

export type CreateFaveAuthorMutation = {
  createFaveAuthor?:  {
    __typename: "FaveAuthor",
    id: string,
    favedBy: string,
    authorID: string,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
  } | null,
};

export type UpdateFaveAuthorMutationVariables = {
  input: UpdateFaveAuthorInput,
  condition?: ModelFaveAuthorConditionInput | null,
};

export type UpdateFaveAuthorMutation = {
  updateFaveAuthor?:  {
    __typename: "FaveAuthor",
    id: string,
    favedBy: string,
    authorID: string,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
  } | null,
};

export type DeleteFaveAuthorMutationVariables = {
  input: DeleteFaveAuthorInput,
  condition?: ModelFaveAuthorConditionInput | null,
};

export type DeleteFaveAuthorMutation = {
  deleteFaveAuthor?:  {
    __typename: "FaveAuthor",
    id: string,
    favedBy: string,
    authorID: string,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
  } | null,
};

export type CreateFaveAuthorStoryUpdateMutationVariables = {
  input: CreateFaveAuthorStoryUpdateInput,
  condition?: ModelFaveAuthorStoryUpdateConditionInput | null,
};

export type CreateFaveAuthorStoryUpdateMutation = {
  createFaveAuthorStoryUpdate?:  {
    __typename: "FaveAuthorStoryUpdate",
    id: string,
    storyID: string,
    authorID: string,
    isNew: boolean,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type UpdateFaveAuthorStoryUpdateMutationVariables = {
  input: UpdateFaveAuthorStoryUpdateInput,
  condition?: ModelFaveAuthorStoryUpdateConditionInput | null,
};

export type UpdateFaveAuthorStoryUpdateMutation = {
  updateFaveAuthorStoryUpdate?:  {
    __typename: "FaveAuthorStoryUpdate",
    id: string,
    storyID: string,
    authorID: string,
    isNew: boolean,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type DeleteFaveAuthorStoryUpdateMutationVariables = {
  input: DeleteFaveAuthorStoryUpdateInput,
  condition?: ModelFaveAuthorStoryUpdateConditionInput | null,
};

export type DeleteFaveAuthorStoryUpdateMutation = {
  deleteFaveAuthorStoryUpdate?:  {
    __typename: "FaveAuthorStoryUpdate",
    id: string,
    storyID: string,
    authorID: string,
    isNew: boolean,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type CreateFaveStoryMutationVariables = {
  input: CreateFaveStoryInput,
  condition?: ModelFaveStoryConditionInput | null,
};

export type CreateFaveStoryMutation = {
  createFaveStory?:  {
    __typename: "FaveStory",
    id: string,
    storyID: string,
    authorID: string,
    totalChapters: number,
    readChapters: number,
    hasUnreadChapters: boolean,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type UpdateFaveStoryMutationVariables = {
  input: UpdateFaveStoryInput,
  condition?: ModelFaveStoryConditionInput | null,
};

export type UpdateFaveStoryMutation = {
  updateFaveStory?:  {
    __typename: "FaveStory",
    id: string,
    storyID: string,
    authorID: string,
    totalChapters: number,
    readChapters: number,
    hasUnreadChapters: boolean,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type DeleteFaveStoryMutationVariables = {
  input: DeleteFaveStoryInput,
  condition?: ModelFaveStoryConditionInput | null,
};

export type DeleteFaveStoryMutation = {
  deleteFaveStory?:  {
    __typename: "FaveStory",
    id: string,
    storyID: string,
    authorID: string,
    totalChapters: number,
    readChapters: number,
    hasUnreadChapters: boolean,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type CreateMentionMutationVariables = {
  input: CreateMentionInput,
  condition?: ModelMentionConditionInput | null,
};

export type CreateMentionMutation = {
  createMention?:  {
    __typename: "Mention",
    id: string,
    mentionerID: string,
    mentionedID: string,
    mentionedIDandStatus: string,
    commentID: string,
    status: MENTION_STATUS,
    storyID: string,
    chapterID: string,
    mentionText: string,
    createdAt: string,
    updatedAt: string,
    Mentioner?:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Chapter?:  {
      __typename: "Chapter",
      id: string,
      title: string,
      body?: string | null,
      authorNotesBefore?: string | null,
      authorNotesAfter?: string | null,
      isPublished: boolean,
      order: number,
      storyID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Comment?:  {
      __typename: "Comment",
      id: string,
      body: string,
      delta: string,
      authorID: string,
      authorDisplayName: string,
      storyID: string,
      chapterID: string,
      parentID?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    owner?: string | null,
    Story?:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
  } | null,
};

export type UpdateMentionMutationVariables = {
  input: UpdateMentionInput,
  condition?: ModelMentionConditionInput | null,
};

export type UpdateMentionMutation = {
  updateMention?:  {
    __typename: "Mention",
    id: string,
    mentionerID: string,
    mentionedID: string,
    mentionedIDandStatus: string,
    commentID: string,
    status: MENTION_STATUS,
    storyID: string,
    chapterID: string,
    mentionText: string,
    createdAt: string,
    updatedAt: string,
    Mentioner?:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Chapter?:  {
      __typename: "Chapter",
      id: string,
      title: string,
      body?: string | null,
      authorNotesBefore?: string | null,
      authorNotesAfter?: string | null,
      isPublished: boolean,
      order: number,
      storyID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Comment?:  {
      __typename: "Comment",
      id: string,
      body: string,
      delta: string,
      authorID: string,
      authorDisplayName: string,
      storyID: string,
      chapterID: string,
      parentID?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    owner?: string | null,
    Story?:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
  } | null,
};

export type DeleteMentionMutationVariables = {
  input: DeleteMentionInput,
  condition?: ModelMentionConditionInput | null,
};

export type DeleteMentionMutation = {
  deleteMention?:  {
    __typename: "Mention",
    id: string,
    mentionerID: string,
    mentionedID: string,
    mentionedIDandStatus: string,
    commentID: string,
    status: MENTION_STATUS,
    storyID: string,
    chapterID: string,
    mentionText: string,
    createdAt: string,
    updatedAt: string,
    Mentioner?:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Chapter?:  {
      __typename: "Chapter",
      id: string,
      title: string,
      body?: string | null,
      authorNotesBefore?: string | null,
      authorNotesAfter?: string | null,
      isPublished: boolean,
      order: number,
      storyID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Comment?:  {
      __typename: "Comment",
      id: string,
      body: string,
      delta: string,
      authorID: string,
      authorDisplayName: string,
      storyID: string,
      chapterID: string,
      parentID?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    owner?: string | null,
    Story?:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
  } | null,
};

export type CreateRatingMutationVariables = {
  input: CreateRatingInput,
  condition?: ModelRatingConditionInput | null,
};

export type CreateRatingMutation = {
  createRating?:  {
    __typename: "Rating",
    id: string,
    storyID: string,
    authorID: string,
    rating: number,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type UpdateRatingMutationVariables = {
  input: UpdateRatingInput,
  condition?: ModelRatingConditionInput | null,
};

export type UpdateRatingMutation = {
  updateRating?:  {
    __typename: "Rating",
    id: string,
    storyID: string,
    authorID: string,
    rating: number,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type DeleteRatingMutationVariables = {
  input: DeleteRatingInput,
  condition?: ModelRatingConditionInput | null,
};

export type DeleteRatingMutation = {
  deleteRating?:  {
    __typename: "Rating",
    id: string,
    storyID: string,
    authorID: string,
    rating: number,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type CreateReadStoryMutationVariables = {
  input: CreateReadStoryInput,
  condition?: ModelReadStoryConditionInput | null,
};

export type CreateReadStoryMutation = {
  createReadStory?:  {
    __typename: "ReadStory",
    id: string,
    storyID: string,
    authorID: string,
    maxChapterIndex: number,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type UpdateReadStoryMutationVariables = {
  input: UpdateReadStoryInput,
  condition?: ModelReadStoryConditionInput | null,
};

export type UpdateReadStoryMutation = {
  updateReadStory?:  {
    __typename: "ReadStory",
    id: string,
    storyID: string,
    authorID: string,
    maxChapterIndex: number,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type DeleteReadStoryMutationVariables = {
  input: DeleteReadStoryInput,
  condition?: ModelReadStoryConditionInput | null,
};

export type DeleteReadStoryMutation = {
  deleteReadStory?:  {
    __typename: "ReadStory",
    id: string,
    storyID: string,
    authorID: string,
    maxChapterIndex: number,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type CreateStoryMutationVariables = {
  input: CreateStoryInput,
  condition?: ModelStoryConditionInput | null,
};

export type CreateStoryMutation = {
  createStory?:  {
    __typename: "Story",
    id: string,
    title: string,
    description?: string | null,
    authorID?: string | null,
    categories?: Array< string > | null,
    rating?: string | null,
    growth?: Array< string > | null,
    shrink?: Array< string > | null,
    sizeRoles?: Array< string > | null,
    isComplete?: boolean | null,
    isPublished: boolean,
    score: number,
    hotScore?: number | null,
    chapterCount?: number | null,
    wordCount?: number | null,
    authorDisplayName: string,
    lastChapterAdded?: string | null,
    createdAt: string,
    updatedAt: string,
    Author?:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Chapters?:  {
      __typename: "ModelChapterConnection",
      nextToken?: string | null,
    } | null,
    FaveStories?:  {
      __typename: "ModelFaveStoryConnection",
      nextToken?: string | null,
    } | null,
    Ratings?:  {
      __typename: "ModelRatingConnection",
      nextToken?: string | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type UpdateStoryMutationVariables = {
  input: UpdateStoryInput,
  condition?: ModelStoryConditionInput | null,
};

export type UpdateStoryMutation = {
  updateStory?:  {
    __typename: "Story",
    id: string,
    title: string,
    description?: string | null,
    authorID?: string | null,
    categories?: Array< string > | null,
    rating?: string | null,
    growth?: Array< string > | null,
    shrink?: Array< string > | null,
    sizeRoles?: Array< string > | null,
    isComplete?: boolean | null,
    isPublished: boolean,
    score: number,
    hotScore?: number | null,
    chapterCount?: number | null,
    wordCount?: number | null,
    authorDisplayName: string,
    lastChapterAdded?: string | null,
    createdAt: string,
    updatedAt: string,
    Author?:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Chapters?:  {
      __typename: "ModelChapterConnection",
      nextToken?: string | null,
    } | null,
    FaveStories?:  {
      __typename: "ModelFaveStoryConnection",
      nextToken?: string | null,
    } | null,
    Ratings?:  {
      __typename: "ModelRatingConnection",
      nextToken?: string | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type DeleteStoryMutationVariables = {
  input: DeleteStoryInput,
  condition?: ModelStoryConditionInput | null,
};

export type DeleteStoryMutation = {
  deleteStory?:  {
    __typename: "Story",
    id: string,
    title: string,
    description?: string | null,
    authorID?: string | null,
    categories?: Array< string > | null,
    rating?: string | null,
    growth?: Array< string > | null,
    shrink?: Array< string > | null,
    sizeRoles?: Array< string > | null,
    isComplete?: boolean | null,
    isPublished: boolean,
    score: number,
    hotScore?: number | null,
    chapterCount?: number | null,
    wordCount?: number | null,
    authorDisplayName: string,
    lastChapterAdded?: string | null,
    createdAt: string,
    updatedAt: string,
    Author?:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Chapters?:  {
      __typename: "ModelChapterConnection",
      nextToken?: string | null,
    } | null,
    FaveStories?:  {
      __typename: "ModelFaveStoryConnection",
      nextToken?: string | null,
    } | null,
    Ratings?:  {
      __typename: "ModelRatingConnection",
      nextToken?: string | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type CreateSuggestionMutationVariables = {
  input: CreateSuggestionInput,
  condition?: ModelSuggestionConditionInput | null,
};

export type CreateSuggestionMutation = {
  createSuggestion?:  {
    __typename: "Suggestion",
    id: string,
    toID: string,
    authorID: string,
    storyID: string,
    chapterID: string,
    body: string,
    status: SUGGESTION_STATUS,
    message: string,
    reply?: string | null,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    Chapter?:  {
      __typename: "Chapter",
      id: string,
      title: string,
      body?: string | null,
      authorNotesBefore?: string | null,
      authorNotesAfter?: string | null,
      isPublished: boolean,
      order: number,
      storyID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Story?:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type UpdateSuggestionMutationVariables = {
  input: UpdateSuggestionInput,
  condition?: ModelSuggestionConditionInput | null,
};

export type UpdateSuggestionMutation = {
  updateSuggestion?:  {
    __typename: "Suggestion",
    id: string,
    toID: string,
    authorID: string,
    storyID: string,
    chapterID: string,
    body: string,
    status: SUGGESTION_STATUS,
    message: string,
    reply?: string | null,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    Chapter?:  {
      __typename: "Chapter",
      id: string,
      title: string,
      body?: string | null,
      authorNotesBefore?: string | null,
      authorNotesAfter?: string | null,
      isPublished: boolean,
      order: number,
      storyID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Story?:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type DeleteSuggestionMutationVariables = {
  input: DeleteSuggestionInput,
  condition?: ModelSuggestionConditionInput | null,
};

export type DeleteSuggestionMutation = {
  deleteSuggestion?:  {
    __typename: "Suggestion",
    id: string,
    toID: string,
    authorID: string,
    storyID: string,
    chapterID: string,
    body: string,
    status: SUGGESTION_STATUS,
    message: string,
    reply?: string | null,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    Chapter?:  {
      __typename: "Chapter",
      id: string,
      title: string,
      body?: string | null,
      authorNotesBefore?: string | null,
      authorNotesAfter?: string | null,
      isPublished: boolean,
      order: number,
      storyID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Story?:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type GetAuthorQueryVariables = {
  id: string,
};

export type GetAuthorQuery = {
  getAuthor?:  {
    __typename: "Author",
    id: string,
    name: string,
    nameLowerCase: string,
    cognitoUsername?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    FaveStories?:  {
      __typename: "ModelFaveStoryConnection",
      nextToken?: string | null,
    } | null,
    Ratings?:  {
      __typename: "ModelRatingConnection",
      nextToken?: string | null,
    } | null,
    Stories?:  {
      __typename: "ModelStoryConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListAuthorsQueryVariables = {
  filter?: ModelAuthorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAuthorsQuery = {
  listAuthors?:  {
    __typename: "ModelAuthorConnection",
    items?:  Array< {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetCategoryQueryVariables = {
  id: string,
};

export type GetCategoryQuery = {
  getCategory?:  {
    __typename: "Category",
    id: string,
    typename: string,
    name: string,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCategorysQueryVariables = {
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCategorysQuery = {
  listCategorys?:  {
    __typename: "ModelCategoryConnection",
    items?:  Array< {
      __typename: "Category",
      id: string,
      typename: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type CategoriesSrtByNameQueryVariables = {
  typename?: string | null,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CategoriesSrtByNameQuery = {
  categoriesSrtByName?:  {
    __typename: "ModelCategoryConnection",
    items?:  Array< {
      __typename: "Category",
      id: string,
      typename: string,
      name: string,
      description?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetChapterQueryVariables = {
  id: string,
};

export type GetChapterQuery = {
  getChapter?:  {
    __typename: "Chapter",
    id: string,
    title: string,
    body?: string | null,
    authorNotesBefore?: string | null,
    authorNotesAfter?: string | null,
    isPublished: boolean,
    order: number,
    storyID: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type ListChaptersQueryVariables = {
  filter?: ModelChapterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChaptersQuery = {
  listChapters?:  {
    __typename: "ModelChapterConnection",
    items?:  Array< {
      __typename: "Chapter",
      id: string,
      title: string,
      body?: string | null,
      authorNotesBefore?: string | null,
      authorNotesAfter?: string | null,
      isPublished: boolean,
      order: number,
      storyID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ChaptersByOrderQueryVariables = {
  storyID?: string | null,
  order?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChapterFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChaptersByOrderQuery = {
  chaptersByOrder?:  {
    __typename: "ModelChapterConnection",
    items?:  Array< {
      __typename: "Chapter",
      id: string,
      title: string,
      body?: string | null,
      authorNotesBefore?: string | null,
      authorNotesAfter?: string | null,
      isPublished: boolean,
      order: number,
      storyID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetCommentQueryVariables = {
  id: string,
};

export type GetCommentQuery = {
  getComment?:  {
    __typename: "Comment",
    id: string,
    body: string,
    delta: string,
    authorID: string,
    authorDisplayName: string,
    storyID: string,
    chapterID: string,
    parentID?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCommentsQueryVariables = {
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommentsQuery = {
  listComments?:  {
    __typename: "ModelCommentConnection",
    items?:  Array< {
      __typename: "Comment",
      id: string,
      body: string,
      delta: string,
      authorID: string,
      authorDisplayName: string,
      storyID: string,
      chapterID: string,
      parentID?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type CommentsByStoryQueryVariables = {
  storyID?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CommentsByStoryQuery = {
  commentsByStory?:  {
    __typename: "ModelCommentConnection",
    items?:  Array< {
      __typename: "Comment",
      id: string,
      body: string,
      delta: string,
      authorID: string,
      authorDisplayName: string,
      storyID: string,
      chapterID: string,
      parentID?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type CommentsByChapterQueryVariables = {
  chapterID?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CommentsByChapterQuery = {
  commentsByChapter?:  {
    __typename: "ModelCommentConnection",
    items?:  Array< {
      __typename: "Comment",
      id: string,
      body: string,
      delta: string,
      authorID: string,
      authorDisplayName: string,
      storyID: string,
      chapterID: string,
      parentID?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetFaveAuthorQueryVariables = {
  id: string,
};

export type GetFaveAuthorQuery = {
  getFaveAuthor?:  {
    __typename: "FaveAuthor",
    id: string,
    favedBy: string,
    authorID: string,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
  } | null,
};

export type ListFaveAuthorsQueryVariables = {
  filter?: ModelFaveAuthorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFaveAuthorsQuery = {
  listFaveAuthors?:  {
    __typename: "ModelFaveAuthorConnection",
    items?:  Array< {
      __typename: "FaveAuthor",
      id: string,
      favedBy: string,
      authorID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetFaveAuthorStoryUpdateQueryVariables = {
  id: string,
};

export type GetFaveAuthorStoryUpdateQuery = {
  getFaveAuthorStoryUpdate?:  {
    __typename: "FaveAuthorStoryUpdate",
    id: string,
    storyID: string,
    authorID: string,
    isNew: boolean,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type ListFaveAuthorStoryUpdatesQueryVariables = {
  filter?: ModelFaveAuthorStoryUpdateFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFaveAuthorStoryUpdatesQuery = {
  listFaveAuthorStoryUpdates?:  {
    __typename: "ModelFaveAuthorStoryUpdateConnection",
    items?:  Array< {
      __typename: "FaveAuthorStoryUpdate",
      id: string,
      storyID: string,
      authorID: string,
      isNew: boolean,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetFaveStoryQueryVariables = {
  id: string,
};

export type GetFaveStoryQuery = {
  getFaveStory?:  {
    __typename: "FaveStory",
    id: string,
    storyID: string,
    authorID: string,
    totalChapters: number,
    readChapters: number,
    hasUnreadChapters: boolean,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type ListFaveStorysQueryVariables = {
  filter?: ModelFaveStoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFaveStorysQuery = {
  listFaveStorys?:  {
    __typename: "ModelFaveStoryConnection",
    items?:  Array< {
      __typename: "FaveStory",
      id: string,
      storyID: string,
      authorID: string,
      totalChapters: number,
      readChapters: number,
      hasUnreadChapters: boolean,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type FaveStoriesByAuthorQueryVariables = {
  authorID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelFaveStoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type FaveStoriesByAuthorQuery = {
  faveStoriesByAuthor?:  {
    __typename: "ModelFaveStoryConnection",
    items?:  Array< {
      __typename: "FaveStory",
      id: string,
      storyID: string,
      authorID: string,
      totalChapters: number,
      readChapters: number,
      hasUnreadChapters: boolean,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetMentionQueryVariables = {
  id: string,
};

export type GetMentionQuery = {
  getMention?:  {
    __typename: "Mention",
    id: string,
    mentionerID: string,
    mentionedID: string,
    mentionedIDandStatus: string,
    commentID: string,
    status: MENTION_STATUS,
    storyID: string,
    chapterID: string,
    mentionText: string,
    createdAt: string,
    updatedAt: string,
    Mentioner?:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Chapter?:  {
      __typename: "Chapter",
      id: string,
      title: string,
      body?: string | null,
      authorNotesBefore?: string | null,
      authorNotesAfter?: string | null,
      isPublished: boolean,
      order: number,
      storyID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Comment?:  {
      __typename: "Comment",
      id: string,
      body: string,
      delta: string,
      authorID: string,
      authorDisplayName: string,
      storyID: string,
      chapterID: string,
      parentID?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    owner?: string | null,
    Story?:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
  } | null,
};

export type ListMentionsQueryVariables = {
  filter?: ModelMentionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMentionsQuery = {
  listMentions?:  {
    __typename: "ModelMentionConnection",
    items?:  Array< {
      __typename: "Mention",
      id: string,
      mentionerID: string,
      mentionedID: string,
      mentionedIDandStatus: string,
      commentID: string,
      status: MENTION_STATUS,
      storyID: string,
      chapterID: string,
      mentionText: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type MentionsByMentionedIDandStatusQueryVariables = {
  mentionedIDandStatus?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMentionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MentionsByMentionedIDandStatusQuery = {
  mentionsByMentionedIDandStatus?:  {
    __typename: "ModelMentionConnection",
    items?:  Array< {
      __typename: "Mention",
      id: string,
      mentionerID: string,
      mentionedID: string,
      mentionedIDandStatus: string,
      commentID: string,
      status: MENTION_STATUS,
      storyID: string,
      chapterID: string,
      mentionText: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetRatingQueryVariables = {
  id: string,
};

export type GetRatingQuery = {
  getRating?:  {
    __typename: "Rating",
    id: string,
    storyID: string,
    authorID: string,
    rating: number,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type ListRatingsQueryVariables = {
  filter?: ModelRatingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListRatingsQuery = {
  listRatings?:  {
    __typename: "ModelRatingConnection",
    items?:  Array< {
      __typename: "Rating",
      id: string,
      storyID: string,
      authorID: string,
      rating: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetReadStoryQueryVariables = {
  id: string,
};

export type GetReadStoryQuery = {
  getReadStory?:  {
    __typename: "ReadStory",
    id: string,
    storyID: string,
    authorID: string,
    maxChapterIndex: number,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type ListReadStorysQueryVariables = {
  filter?: ModelReadStoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReadStorysQuery = {
  listReadStorys?:  {
    __typename: "ModelReadStoryConnection",
    items?:  Array< {
      __typename: "ReadStory",
      id: string,
      storyID: string,
      authorID: string,
      maxChapterIndex: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ReadStoriesByAuthorQueryVariables = {
  authorID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReadStoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReadStoriesByAuthorQuery = {
  readStoriesByAuthor?:  {
    __typename: "ModelReadStoryConnection",
    items?:  Array< {
      __typename: "ReadStory",
      id: string,
      storyID: string,
      authorID: string,
      maxChapterIndex: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetStoryQueryVariables = {
  id: string,
};

export type GetStoryQuery = {
  getStory?:  {
    __typename: "Story",
    id: string,
    title: string,
    description?: string | null,
    authorID?: string | null,
    categories?: Array< string > | null,
    rating?: string | null,
    growth?: Array< string > | null,
    shrink?: Array< string > | null,
    sizeRoles?: Array< string > | null,
    isComplete?: boolean | null,
    isPublished: boolean,
    score: number,
    hotScore?: number | null,
    chapterCount?: number | null,
    wordCount?: number | null,
    authorDisplayName: string,
    lastChapterAdded?: string | null,
    createdAt: string,
    updatedAt: string,
    Author?:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Chapters?:  {
      __typename: "ModelChapterConnection",
      nextToken?: string | null,
    } | null,
    FaveStories?:  {
      __typename: "ModelFaveStoryConnection",
      nextToken?: string | null,
    } | null,
    Ratings?:  {
      __typename: "ModelRatingConnection",
      nextToken?: string | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type ListStorysQueryVariables = {
  filter?: ModelStoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStorysQuery = {
  listStorys?:  {
    __typename: "ModelStoryConnection",
    items?:  Array< {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetSuggestionQueryVariables = {
  id: string,
};

export type GetSuggestionQuery = {
  getSuggestion?:  {
    __typename: "Suggestion",
    id: string,
    toID: string,
    authorID: string,
    storyID: string,
    chapterID: string,
    body: string,
    status: SUGGESTION_STATUS,
    message: string,
    reply?: string | null,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    Chapter?:  {
      __typename: "Chapter",
      id: string,
      title: string,
      body?: string | null,
      authorNotesBefore?: string | null,
      authorNotesAfter?: string | null,
      isPublished: boolean,
      order: number,
      storyID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Story?:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type ListSuggestionsQueryVariables = {
  filter?: ModelSuggestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSuggestionsQuery = {
  listSuggestions?:  {
    __typename: "ModelSuggestionConnection",
    items?:  Array< {
      __typename: "Suggestion",
      id: string,
      toID: string,
      authorID: string,
      storyID: string,
      chapterID: string,
      body: string,
      status: SUGGESTION_STATUS,
      message: string,
      reply?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type SuggestionsByToIDQueryVariables = {
  toID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSuggestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SuggestionsByToIDQuery = {
  suggestionsByToID?:  {
    __typename: "ModelSuggestionConnection",
    items?:  Array< {
      __typename: "Suggestion",
      id: string,
      toID: string,
      authorID: string,
      storyID: string,
      chapterID: string,
      body: string,
      status: SUGGESTION_STATUS,
      message: string,
      reply?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type OnCreateAuthorSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateAuthorSubscription = {
  onCreateAuthor?:  {
    __typename: "Author",
    id: string,
    name: string,
    nameLowerCase: string,
    cognitoUsername?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    FaveStories?:  {
      __typename: "ModelFaveStoryConnection",
      nextToken?: string | null,
    } | null,
    Ratings?:  {
      __typename: "ModelRatingConnection",
      nextToken?: string | null,
    } | null,
    Stories?:  {
      __typename: "ModelStoryConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateAuthorSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateAuthorSubscription = {
  onUpdateAuthor?:  {
    __typename: "Author",
    id: string,
    name: string,
    nameLowerCase: string,
    cognitoUsername?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    FaveStories?:  {
      __typename: "ModelFaveStoryConnection",
      nextToken?: string | null,
    } | null,
    Ratings?:  {
      __typename: "ModelRatingConnection",
      nextToken?: string | null,
    } | null,
    Stories?:  {
      __typename: "ModelStoryConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteAuthorSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteAuthorSubscription = {
  onDeleteAuthor?:  {
    __typename: "Author",
    id: string,
    name: string,
    nameLowerCase: string,
    cognitoUsername?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    FaveStories?:  {
      __typename: "ModelFaveStoryConnection",
      nextToken?: string | null,
    } | null,
    Ratings?:  {
      __typename: "ModelRatingConnection",
      nextToken?: string | null,
    } | null,
    Stories?:  {
      __typename: "ModelStoryConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateCategorySubscription = {
  onCreateCategory?:  {
    __typename: "Category",
    id: string,
    typename: string,
    name: string,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCategorySubscription = {
  onUpdateCategory?:  {
    __typename: "Category",
    id: string,
    typename: string,
    name: string,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCategorySubscription = {
  onDeleteCategory?:  {
    __typename: "Category",
    id: string,
    typename: string,
    name: string,
    description?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateChapterSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateChapterSubscription = {
  onCreateChapter?:  {
    __typename: "Chapter",
    id: string,
    title: string,
    body?: string | null,
    authorNotesBefore?: string | null,
    authorNotesAfter?: string | null,
    isPublished: boolean,
    order: number,
    storyID: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type OnUpdateChapterSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateChapterSubscription = {
  onUpdateChapter?:  {
    __typename: "Chapter",
    id: string,
    title: string,
    body?: string | null,
    authorNotesBefore?: string | null,
    authorNotesAfter?: string | null,
    isPublished: boolean,
    order: number,
    storyID: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type OnDeleteChapterSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteChapterSubscription = {
  onDeleteChapter?:  {
    __typename: "Chapter",
    id: string,
    title: string,
    body?: string | null,
    authorNotesBefore?: string | null,
    authorNotesAfter?: string | null,
    isPublished: boolean,
    order: number,
    storyID: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type OnCreateCommentSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateCommentSubscription = {
  onCreateComment?:  {
    __typename: "Comment",
    id: string,
    body: string,
    delta: string,
    authorID: string,
    authorDisplayName: string,
    storyID: string,
    chapterID: string,
    parentID?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCommentSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateCommentSubscription = {
  onUpdateComment?:  {
    __typename: "Comment",
    id: string,
    body: string,
    delta: string,
    authorID: string,
    authorDisplayName: string,
    storyID: string,
    chapterID: string,
    parentID?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCommentSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteCommentSubscription = {
  onDeleteComment?:  {
    __typename: "Comment",
    id: string,
    body: string,
    delta: string,
    authorID: string,
    authorDisplayName: string,
    storyID: string,
    chapterID: string,
    parentID?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateFaveAuthorSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateFaveAuthorSubscription = {
  onCreateFaveAuthor?:  {
    __typename: "FaveAuthor",
    id: string,
    favedBy: string,
    authorID: string,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
  } | null,
};

export type OnUpdateFaveAuthorSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateFaveAuthorSubscription = {
  onUpdateFaveAuthor?:  {
    __typename: "FaveAuthor",
    id: string,
    favedBy: string,
    authorID: string,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
  } | null,
};

export type OnDeleteFaveAuthorSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteFaveAuthorSubscription = {
  onDeleteFaveAuthor?:  {
    __typename: "FaveAuthor",
    id: string,
    favedBy: string,
    authorID: string,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
  } | null,
};

export type OnCreateFaveAuthorStoryUpdateSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateFaveAuthorStoryUpdateSubscription = {
  onCreateFaveAuthorStoryUpdate?:  {
    __typename: "FaveAuthorStoryUpdate",
    id: string,
    storyID: string,
    authorID: string,
    isNew: boolean,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type OnUpdateFaveAuthorStoryUpdateSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateFaveAuthorStoryUpdateSubscription = {
  onUpdateFaveAuthorStoryUpdate?:  {
    __typename: "FaveAuthorStoryUpdate",
    id: string,
    storyID: string,
    authorID: string,
    isNew: boolean,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type OnDeleteFaveAuthorStoryUpdateSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteFaveAuthorStoryUpdateSubscription = {
  onDeleteFaveAuthorStoryUpdate?:  {
    __typename: "FaveAuthorStoryUpdate",
    id: string,
    storyID: string,
    authorID: string,
    isNew: boolean,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type OnCreateFaveStorySubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateFaveStorySubscription = {
  onCreateFaveStory?:  {
    __typename: "FaveStory",
    id: string,
    storyID: string,
    authorID: string,
    totalChapters: number,
    readChapters: number,
    hasUnreadChapters: boolean,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type OnUpdateFaveStorySubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateFaveStorySubscription = {
  onUpdateFaveStory?:  {
    __typename: "FaveStory",
    id: string,
    storyID: string,
    authorID: string,
    totalChapters: number,
    readChapters: number,
    hasUnreadChapters: boolean,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type OnDeleteFaveStorySubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteFaveStorySubscription = {
  onDeleteFaveStory?:  {
    __typename: "FaveStory",
    id: string,
    storyID: string,
    authorID: string,
    totalChapters: number,
    readChapters: number,
    hasUnreadChapters: boolean,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type OnCreateMentionSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateMentionSubscription = {
  onCreateMention?:  {
    __typename: "Mention",
    id: string,
    mentionerID: string,
    mentionedID: string,
    mentionedIDandStatus: string,
    commentID: string,
    status: MENTION_STATUS,
    storyID: string,
    chapterID: string,
    mentionText: string,
    createdAt: string,
    updatedAt: string,
    Mentioner?:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Chapter?:  {
      __typename: "Chapter",
      id: string,
      title: string,
      body?: string | null,
      authorNotesBefore?: string | null,
      authorNotesAfter?: string | null,
      isPublished: boolean,
      order: number,
      storyID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Comment?:  {
      __typename: "Comment",
      id: string,
      body: string,
      delta: string,
      authorID: string,
      authorDisplayName: string,
      storyID: string,
      chapterID: string,
      parentID?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    owner?: string | null,
    Story?:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
  } | null,
};

export type OnUpdateMentionSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateMentionSubscription = {
  onUpdateMention?:  {
    __typename: "Mention",
    id: string,
    mentionerID: string,
    mentionedID: string,
    mentionedIDandStatus: string,
    commentID: string,
    status: MENTION_STATUS,
    storyID: string,
    chapterID: string,
    mentionText: string,
    createdAt: string,
    updatedAt: string,
    Mentioner?:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Chapter?:  {
      __typename: "Chapter",
      id: string,
      title: string,
      body?: string | null,
      authorNotesBefore?: string | null,
      authorNotesAfter?: string | null,
      isPublished: boolean,
      order: number,
      storyID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Comment?:  {
      __typename: "Comment",
      id: string,
      body: string,
      delta: string,
      authorID: string,
      authorDisplayName: string,
      storyID: string,
      chapterID: string,
      parentID?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    owner?: string | null,
    Story?:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
  } | null,
};

export type OnDeleteMentionSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteMentionSubscription = {
  onDeleteMention?:  {
    __typename: "Mention",
    id: string,
    mentionerID: string,
    mentionedID: string,
    mentionedIDandStatus: string,
    commentID: string,
    status: MENTION_STATUS,
    storyID: string,
    chapterID: string,
    mentionText: string,
    createdAt: string,
    updatedAt: string,
    Mentioner?:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Chapter?:  {
      __typename: "Chapter",
      id: string,
      title: string,
      body?: string | null,
      authorNotesBefore?: string | null,
      authorNotesAfter?: string | null,
      isPublished: boolean,
      order: number,
      storyID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Comment?:  {
      __typename: "Comment",
      id: string,
      body: string,
      delta: string,
      authorID: string,
      authorDisplayName: string,
      storyID: string,
      chapterID: string,
      parentID?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    owner?: string | null,
    Story?:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
  } | null,
};

export type OnCreateRatingSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateRatingSubscription = {
  onCreateRating?:  {
    __typename: "Rating",
    id: string,
    storyID: string,
    authorID: string,
    rating: number,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type OnUpdateRatingSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateRatingSubscription = {
  onUpdateRating?:  {
    __typename: "Rating",
    id: string,
    storyID: string,
    authorID: string,
    rating: number,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type OnDeleteRatingSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteRatingSubscription = {
  onDeleteRating?:  {
    __typename: "Rating",
    id: string,
    storyID: string,
    authorID: string,
    rating: number,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type OnCreateReadStorySubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateReadStorySubscription = {
  onCreateReadStory?:  {
    __typename: "ReadStory",
    id: string,
    storyID: string,
    authorID: string,
    maxChapterIndex: number,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type OnUpdateReadStorySubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateReadStorySubscription = {
  onUpdateReadStory?:  {
    __typename: "ReadStory",
    id: string,
    storyID: string,
    authorID: string,
    maxChapterIndex: number,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type OnDeleteReadStorySubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteReadStorySubscription = {
  onDeleteReadStory?:  {
    __typename: "ReadStory",
    id: string,
    storyID: string,
    authorID: string,
    maxChapterIndex: number,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    owner?: string | null,
    Story:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
  } | null,
};

export type OnCreateStorySubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateStorySubscription = {
  onCreateStory?:  {
    __typename: "Story",
    id: string,
    title: string,
    description?: string | null,
    authorID?: string | null,
    categories?: Array< string > | null,
    rating?: string | null,
    growth?: Array< string > | null,
    shrink?: Array< string > | null,
    sizeRoles?: Array< string > | null,
    isComplete?: boolean | null,
    isPublished: boolean,
    score: number,
    hotScore?: number | null,
    chapterCount?: number | null,
    wordCount?: number | null,
    authorDisplayName: string,
    lastChapterAdded?: string | null,
    createdAt: string,
    updatedAt: string,
    Author?:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Chapters?:  {
      __typename: "ModelChapterConnection",
      nextToken?: string | null,
    } | null,
    FaveStories?:  {
      __typename: "ModelFaveStoryConnection",
      nextToken?: string | null,
    } | null,
    Ratings?:  {
      __typename: "ModelRatingConnection",
      nextToken?: string | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateStorySubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateStorySubscription = {
  onUpdateStory?:  {
    __typename: "Story",
    id: string,
    title: string,
    description?: string | null,
    authorID?: string | null,
    categories?: Array< string > | null,
    rating?: string | null,
    growth?: Array< string > | null,
    shrink?: Array< string > | null,
    sizeRoles?: Array< string > | null,
    isComplete?: boolean | null,
    isPublished: boolean,
    score: number,
    hotScore?: number | null,
    chapterCount?: number | null,
    wordCount?: number | null,
    authorDisplayName: string,
    lastChapterAdded?: string | null,
    createdAt: string,
    updatedAt: string,
    Author?:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Chapters?:  {
      __typename: "ModelChapterConnection",
      nextToken?: string | null,
    } | null,
    FaveStories?:  {
      __typename: "ModelFaveStoryConnection",
      nextToken?: string | null,
    } | null,
    Ratings?:  {
      __typename: "ModelRatingConnection",
      nextToken?: string | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteStorySubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteStorySubscription = {
  onDeleteStory?:  {
    __typename: "Story",
    id: string,
    title: string,
    description?: string | null,
    authorID?: string | null,
    categories?: Array< string > | null,
    rating?: string | null,
    growth?: Array< string > | null,
    shrink?: Array< string > | null,
    sizeRoles?: Array< string > | null,
    isComplete?: boolean | null,
    isPublished: boolean,
    score: number,
    hotScore?: number | null,
    chapterCount?: number | null,
    wordCount?: number | null,
    authorDisplayName: string,
    lastChapterAdded?: string | null,
    createdAt: string,
    updatedAt: string,
    Author?:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Chapters?:  {
      __typename: "ModelChapterConnection",
      nextToken?: string | null,
    } | null,
    FaveStories?:  {
      __typename: "ModelFaveStoryConnection",
      nextToken?: string | null,
    } | null,
    Ratings?:  {
      __typename: "ModelRatingConnection",
      nextToken?: string | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type OnCreateSuggestionSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateSuggestionSubscription = {
  onCreateSuggestion?:  {
    __typename: "Suggestion",
    id: string,
    toID: string,
    authorID: string,
    storyID: string,
    chapterID: string,
    body: string,
    status: SUGGESTION_STATUS,
    message: string,
    reply?: string | null,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    Chapter?:  {
      __typename: "Chapter",
      id: string,
      title: string,
      body?: string | null,
      authorNotesBefore?: string | null,
      authorNotesAfter?: string | null,
      isPublished: boolean,
      order: number,
      storyID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Story?:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateSuggestionSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateSuggestionSubscription = {
  onUpdateSuggestion?:  {
    __typename: "Suggestion",
    id: string,
    toID: string,
    authorID: string,
    storyID: string,
    chapterID: string,
    body: string,
    status: SUGGESTION_STATUS,
    message: string,
    reply?: string | null,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    Chapter?:  {
      __typename: "Chapter",
      id: string,
      title: string,
      body?: string | null,
      authorNotesBefore?: string | null,
      authorNotesAfter?: string | null,
      isPublished: boolean,
      order: number,
      storyID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Story?:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteSuggestionSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteSuggestionSubscription = {
  onDeleteSuggestion?:  {
    __typename: "Suggestion",
    id: string,
    toID: string,
    authorID: string,
    storyID: string,
    chapterID: string,
    body: string,
    status: SUGGESTION_STATUS,
    message: string,
    reply?: string | null,
    createdAt: string,
    updatedAt: string,
    Author:  {
      __typename: "Author",
      id: string,
      name: string,
      nameLowerCase: string,
      cognitoUsername?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    Chapter?:  {
      __typename: "Chapter",
      id: string,
      title: string,
      body?: string | null,
      authorNotesBefore?: string | null,
      authorNotesAfter?: string | null,
      isPublished: boolean,
      order: number,
      storyID: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    Story?:  {
      __typename: "Story",
      id: string,
      title: string,
      description?: string | null,
      authorID?: string | null,
      categories?: Array< string > | null,
      rating?: string | null,
      growth?: Array< string > | null,
      shrink?: Array< string > | null,
      sizeRoles?: Array< string > | null,
      isComplete?: boolean | null,
      isPublished: boolean,
      score: number,
      hotScore?: number | null,
      chapterCount?: number | null,
      wordCount?: number | null,
      authorDisplayName: string,
      lastChapterAdded?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    owner?: string | null,
  } | null,
};
