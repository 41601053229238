/* eslint-disable @typescript-eslint/no-explicit-any */
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import React, { useState } from "react";
import { UseQueryResult } from "react-query";

interface Props {
  title: string;
  subtitle: string;
  query: UseQueryResult<any[]>;
  renderItem: (item: any) => JSX.Element;
  emptyIcon: React.ReactElement;
}

const DashboardCard: React.FC<Props> = ({ title, subtitle, query, renderItem, emptyIcon }) => {
  const [open, setOpen] = useState(false);
  const cardContainerClasses = classNames({
    "fixed top-0 right-0 bottom-0 left-0 z-30 p-10 bg-black bg-opacity-50 flex flex-col": open,
  });
  const styledIcon = React.cloneElement(emptyIcon, { style: { fontSize: 60 } });

  const cardClasses = classNames(
    "border dark:border-gray-600 p-2 rounded  bg-white dark:bg-gray-800 flex-grow shadow-lg",
    {
      "min-h-[267px]": !open,
    },
  );
  return (
    <div className={cardContainerClasses}>
      <div className={cardClasses}>
        <div className="flex justify-between items-center border-b dark:border-gray-600 pb-2">
          <div>
            <h2 className="font-bold text-lg">
              {title} {query.data && <>({query.data.length})</>}
            </h2>
            <p className="text-sm text-gray-600 dark:text-gray-400">{subtitle}</p>
          </div>
          <div className="flex">
            {open && (
              <button
                onClick={() => setOpen(false)}
                className="justify-center  text-gray-600 dark:text-gray-400 flex items-center text-lg font-bold bg-gray-100 hover:bg-gray-200 border-2 border-gray-600 rounded px-0.5 mr-1"
              >
                <CloseIcon />
              </button>
            )}
          </div>
        </div>
        <div className="flex flex-col space-y-2 py-2">
          {query.data && query.data.length === 0 && (
            <div className="text-center py-8 text-pink-600">
              {styledIcon}
              <div className="text-gray-600 dark:text-gray-400 font-bold pt-4">No {title}</div>
            </div>
          )}
          {query.data &&
            query.data.slice(0, !open ? 5 : query.data.length).map((item, index) => (
              <div key={index} className="h-6 overflow-hidden">
                {renderItem(item)}
              </div>
            ))}
        </div>
        {!open && query.data && query.data.length > 5 && (
          <button
            onClick={() => setOpen(true)}
            className="text-pink-600 hover:text-pink-700 flex items-center justify-end w-full focus:outline-none"
          >
            <span>View All {query.data.length}</span>
            <ChevronRightIcon />
          </button>
        )}
      </div>
    </div>
  );
};

export default DashboardCard;
