import classNames from "classnames";
import React from "react";
import { NavLink } from "react-router-dom";
import { Suggestion, SuggestionsByToIDQueryVariables, SUGGESTION_STATUS } from "../API";
import useCurrentAuthor from "../api/useCurrentAuthor";
import useListQuery from "../api/useListQuery";
import { suggestionsByToID } from "../graphqlcustom/queries";
import { routes } from "../ui/routes";

interface Props {
  children: React.ReactNode;
}

const DashboardLayout: React.FC<Props> = ({ children }) => {
  const activeNavClasses = classNames("text-pink-600");
  const currentAuthor = useCurrentAuthor();
  const suggestionQuery = useListQuery<Suggestion, SuggestionsByToIDQueryVariables>(
    suggestionsByToID,
    "suggestionsByToID",
    { toID: currentAuthor?.id || "", filter: { status: { eq: SUGGESTION_STATUS.PENDING } } },
    { enabled: !!currentAuthor?.id },
  );

  return (
    <div className="flex">
      <div className=" side-bar">
        <NavLink to={routes.dashboard.path} activeClassName={activeNavClasses} exact>
          My Dashboard
        </NavLink>
        <NavLink to={routes.dashboard.myStories.path} activeClassName={activeNavClasses}>
          My Stories
        </NavLink>
        <NavLink to={routes.dashboard.suggestions.path} activeClassName={activeNavClasses}>
          Suggestions ({suggestionQuery.data?.length})
        </NavLink>
        <NavLink to={routes.dashboard.favoriteStories.path} activeClassName={activeNavClasses}>
          Favorite Stories
        </NavLink>
        <NavLink to={routes.dashboard.followedAuthors.path} activeClassName={activeNavClasses}>
          Followed Authors
        </NavLink>
      </div>
      <div className="flex-grow">{children}</div>
    </div>
  );
};

export default DashboardLayout;
