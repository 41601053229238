import React from "react";
import { useTranslation } from "react-i18next";
import home from "../../assets/2.jpg";
const Home: React.FC = () => {
  const [t] = useTranslation();
  return (
    <div className="mt-10">
      <div className="max-w-screen-lg mx-auto p-4">
        <img
          style={{ aspectRatio: "1/1" }}
          src={home}
          alt="Home"
          className="rounded-full w-[600px] mx-auto object-cover"
        />
        <h1 className="text-center text-5xl font-thin py-10">{t("common.siteName")}</h1>
      </div>
    </div>
  );
};

export default Home;
