import { ReactiveBase, ReactiveList, SelectedFilters } from "@appbaseio/reactivesearch";
import React from "react";
import SkeletonStoryCard from "../../components/StoryList/SkeletonStoryCard";
import StoryList, { LinkTo } from "../../components/StoryList/StoryList";
import { CustomWindow } from "../../env";
import StoryFilters, { defaultQuery, reactiveList } from "./StoryFilters";

const Browse: React.FC = () => {
  const runtime: CustomWindow = window;

  return (
    <div className="">
      <ReactiveBase
        app={`stories-${runtime.env?.REACT_APP_BRANCH || "unknown"}`}
        url="https://3fe0aeed8d4e470d81334442f3211304.us-central1.gcp.cloud.es.io:9243/"
        credentials="web-user:ux%oh#5CTt91A&hIl"
      >
        <div className="flex dark:text-white">
          <div className="side-bar">
            <StoryFilters />
          </div>
          <div className="flex-grow p-4">
            <div className="h-14 flex justify-between items-center">
              <h1 className="text-lg font-bold">Browse Stories</h1>
              <SelectedFilters showClearAll={true} clearAllLabel="Clear filters" />
            </div>
            <ReactiveList
              defaultQuery={defaultQuery}
              componentId="results"
              dataField="name"
              size={20}
              loader={<SkeletonStoryCard rows={10} />}
              pagination={true}
              URLParams
              innerClass={{
                button: "pagination-button",
                sortOptions: "input",
              }}
              react={{ and: reactiveList }}
              sortOptions={[
                { label: "Hot", dataField: "hotScore", sortBy: "desc" },
                { label: "Highest Rated", dataField: "score", sortBy: "desc" },
                { label: "Updated Recently", dataField: "updatedAt", sortBy: "desc" },
                { label: "Newest First", dataField: "createdAt", sortBy: "desc" },
                { label: "Oldest First", dataField: "createdAt", sortBy: "asc" },
                { label: "Alphabetical", dataField: "title.keyword", sortBy: "asc" },
              ]}
              render={({ data }) => <StoryList data={data} linkTo={LinkTo.Show} />}
            />
          </div>
        </div>
      </ReactiveBase>
    </div>
  );
};

export default Browse;
