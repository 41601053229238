import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { FaveAuthor, ListFaveAuthorsQueryVariables } from "../../API";
import useCurrentAuthor from "../../api/useCurrentAuthor";
import useListQuery from "../../api/useListQuery";
import { listFaveAuthorsWithAuthorName } from "../../graphqlcustom/queries";
import { routes } from "../../ui/routes";

const FollowedAuthors: React.FC = () => {
  const currentAuthor = useCurrentAuthor();

  const query = useListQuery<FaveAuthor, ListFaveAuthorsQueryVariables>(
    listFaveAuthorsWithAuthorName,
    "listFaveAuthors",
    { filter: { favedBy: { eq: currentAuthor?.id } } },
    { enabled: !!currentAuthor },
  );

  return (
    <div className="p-4">
      <div className="pt-3.5 h-12 flex justify-between">
        <h1 className="text-lg font-bold">Followed Authors</h1>
      </div>
      <div className="border-t pt-4 mt-4">
        {query.data &&
          query.data.map((fave) => (
            <div>
              <Link className="text-pink-600 hover:underline" to={routes.authors.show.with({ id: fave.authorID })}>
                {fave.Author && fave.Author.name}
              </Link>{" "}
              followed {moment(fave.createdAt).fromNow()}
            </div>
          ))}
      </div>
    </div>
  );
};

export default FollowedAuthors;
