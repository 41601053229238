import "quill-emoji/dist/quill-emoji.css";
import "quill-mention/dist/quill.mention.min.css";
import "rc-slider/assets/index.css";
import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "react-quill/dist/quill.snow.css";
import { RecoilRoot } from "recoil";
import i18n from "./i18n";
import "./index.css";
import reportWebVitals from "./report-web-vitals";
import * as serviceWorkerRegistration from "./service-worker-registration";
import App from "./ui/App";

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, staleTime: 1000 * 60 * 5 } },
});

const Loading: React.FC = () => {
  return <div className="w-full h-screen flex justify-center items-center">Loading...</div>;
};

const Root: React.FC = () => {
  return (
    <React.StrictMode>
      <React.Suspense fallback={<Loading />}>
        <I18nextProvider i18n={i18n}>
          <QueryClientProvider client={queryClient}>
            <RecoilRoot>
              <App></App>
            </RecoilRoot>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </I18nextProvider>
      </React.Suspense>
    </React.StrictMode>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
