export const growthMarks = {
  0: "none",
  1: "7ft",
  2: "15ft",
  3: "30ft",
  4: "50ft",
  5: "100ft",
  6: "500ft",
  7: "1mi",
  8: "100mi↑",
};
