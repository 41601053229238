import CloseIcon from "@material-ui/icons/Close";
import { useSetRecoilState } from "recoil";
import { modalAtom } from "../../atoms/atoms";

interface Props {
  title?: string;
  showCloseButton?: boolean;
  children: JSX.Element;
}

const ModalWrapper: React.FC<Props> = ({ title, showCloseButton = false, children }) => {
  const setModal = useSetRecoilState(modalAtom);
  return (
    <div className="fixed top-0 w-full h-screen flex items-center bg-black bg-opacity-50 z-30">
      <div className="bg-white dark:bg-gray-700 rounded-lg shadow-2xl p-6 max-w-prose mx-auto flex items-center justify-center relative">
        {showCloseButton && (
          <button onClick={() => setModal(undefined)} className="absolute top-0 right-0 mt-2 mr-2 hover:text-pink-600">
            <CloseIcon />
          </button>
        )}
        <div>
          {title && <h1 className="font-bold text-lg">{title}</h1>}
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalWrapper;
