/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-empty-function */
import * as Emoji from "quill-emoji";
import MagicUrl from "quill-magic-url";
import "quill-mention";
import { useEffect, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import useSearchAuthors from "../../api/useSearchAuthors";

Quill.register("modules/emoji", Emoji);
Quill.register("modules/magicUrl", MagicUrl);

interface Props {
  rootForm: boolean;
  onSave: (delta: string, body: string, mentions: string[]) => void;
  closeEditor: () => void;
}

const CommentEditor: React.FC<Props> = ({ rootForm, onSave, closeEditor }) => {
  const ref = useRef<ReactQuill | null>(null);
  const search = useSearchAuthors();
  const toolbarOptions = {
    container: [
      ["bold", "italic", "underline", "strike"],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["clean"],
      ["emoji"],
    ],
    handlers: { emoji: function () {} },
  };

  useEffect(() => {
    if (ref.current && !rootForm) {
      ref.current.focus();
      const editor = ref.current.getEditor();
      const pos = editor.getLength();
      editor.setSelection(pos, 0);
    }
  }, []);

  const save = () => {
    if (ref.current) {
      const editor = ref.current.getEditor();
      const unprivilegedEditor = ref.current.makeUnprivilegedEditor(editor);
      const delta = editor.getContents();
      const mentions = delta.filter((line) => !!line.insert.mention);
      const mentionIds = mentions.map((item) => item.insert.mention.id);
      const html = unprivilegedEditor.getHTML();
      const text = editor.getText();
      onSave(JSON.stringify(delta), html, mentionIds);
      editor.setText("");
    }
  };

  return (
    <div>
      <div className="bg-white dark:bg-gray-900">
        <ReactQuill
          ref={(el) => {
            ref.current = el;
          }}
          placeholder="Say something"
          theme="snow"
          modules={{
            toolbar: toolbarOptions,
            "emoji-toolbar": true,
            "emoji-textarea": false,
            "emoji-shortname": true,
            magicUrl: true,
            mention: {
              allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
              mentionDenotationChars: ["@"],
              source: async function (searchTerm: any, renderList: any) {
                const matchedPeople = await search(searchTerm);
                renderList(matchedPeople);
              },
            },
          }}
        />
      </div>
      <div className="flex justify-end space-x-2 pt-3">
        {!rootForm && (
          <button onClick={closeEditor} className="btn-default">
            Cancel
          </button>
        )}
        <button onClick={save} className="btn-primary btn-lg">
          {rootForm ? "Add Comment" : "Reply"}
        </button>
      </div>
    </div>
  );
};

export default CommentEditor;
