import { route } from "static-route-paths";

export const routes = route({
  root: route(),
  authenticate: route("authenticate"),
  recent: route("recent"),
  donate: route("donate"),
  profile: route("profile"),
  dashboard: route("dashboard", {
    myStories: route("my-stories", {
      new: route("new"),
      edit: route(":id"),
    }),
    suggestions: route("suggestions", {
      show: route(":id"),
    }),
    favoriteStories: route("favorite-stories"),
    followedAuthors: route("followed-authors"),
  }),

  admin: route("admin", {
    storyCategories: route("story-categories"),
  }),

  stories: route("stories", {
    show: route(":id", {
      chapter: route(":chapterIndex"),
    }),
  }),

  chapters: route("chapters", {
    show: route(":id", {
      suggestion: route("new-suggestion"),
    }),
  }),

  authors: route("authors", {
    show: route(":id"),
  }),
});
