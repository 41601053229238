import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router";
import {
  CreateCommentInput,
  CreateCommentMutation,
  CreateMentionInput,
  CreateMentionMutation,
  MENTION_STATUS,
  Story,
} from "../../API";
import useApiMutation from "../../api/useApiMutation";
import useCurrentAuthor from "../../api/useCurrentAuthor";
import useGetQuery from "../../api/useGetQuery";
import { createComment, createMention } from "../../graphql/mutations";
import { getStory } from "../../graphql/queries";
import CommentEditor from "./CommentEditor";

interface Props {
  chapterID: string;
  parentID?: string;
  rootForm: boolean;
  showForm: boolean;
}

const CommentForm: React.FC<Props> = ({ chapterID, parentID, rootForm, showForm }) => {
  const queryClient = useQueryClient();
  const [openEditor, setOpenEditor] = useState(!!rootForm);
  const { id } = useParams<{ id: string }>();
  const storyQuery = useGetQuery<Story>(getStory, "getStory", id);
  const currentAuthor = useCurrentAuthor();

  const addComment = useApiMutation<CreateCommentMutation, CreateCommentInput>(createComment);
  const addMention = useApiMutation<CreateMentionMutation, CreateMentionInput>(createMention);

  const save = (delta: string, body: string, mentions: string[]) => {
    if (!currentAuthor || !currentAuthor.id || !currentAuthor.name) {
      return;
    }

    //Add Comment
    addComment.mutate(
      {
        storyID: id,
        chapterID,
        delta,
        body,
        authorID: currentAuthor.id,
        authorDisplayName: currentAuthor.name,
        parentID,
      },
      {
        onSuccess: (data) => {
          //queryClient.invalidateQueries("commentsByStory");
          queryClient.setQueryData(["commentsByStory", { sortDirection: "ASC", storyID: id }], (old: any) => [
            ...old,
            data.createComment,
          ]);
          queryClient.setQueryData(
            ["commentsByChapter", { sortDirection: "ASC", chapterID: chapterID }],
            (old: any) => [...old, data.createComment],
          );
          if (data.createComment) {
            createMentions(mentions, data.createComment.id);
          }
          if (!rootForm) {
            setOpenEditor(false);
          }
        },
      },
    );
  };

  function createMentions(mentions: string[], commentID: string) {
    if (
      !currentAuthor ||
      !currentAuthor.id ||
      !currentAuthor.name ||
      !storyQuery ||
      !storyQuery.data ||
      !storyQuery.data.title
    ) {
      return;
    }
    mentions.forEach((mentionedID) => {
      console.log(mentionedID);
      addMention.mutate({
        mentionedID: mentionedID,
        mentionerID: currentAuthor.id!,
        status: MENTION_STATUS.UNSEEN,
        mentionedIDandStatus: `${mentionedID}#${MENTION_STATUS.UNSEEN}`,
        commentID: commentID,
        storyID: id,
        chapterID: chapterID,
        mentionText: `You were mentioned in a comment on the story "${storyQuery.data.title}" by ${storyQuery.data.authorDisplayName}`,
      });
    });
  }

  return (
    <div className="w-full">
      {showForm && openEditor && (
        <CommentEditor rootForm={rootForm} onSave={save} closeEditor={() => setOpenEditor(false)} />
      )}
      {showForm && !openEditor && (
        <button
          onClick={() => setOpenEditor(true)}
          className="italic text-gray-500 text-sm bg-white dark:bg-gray-900 w-full text-left border dark:border-gray-600 p-2 rounded"
        >
          Say something
        </button>
      )}
    </div>
  );
};

export default CommentForm;
