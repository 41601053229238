import { useRecoilState, useRecoilValue } from "recoil";
import { Author } from "../API";
import { modalAtom, userAtom } from "../atoms/atoms";
import NewAuthorModal from "../components/AuthorDisplayNameModal/NewAuthorModal";
import { listAuthors } from "../graphql/queries";
import useListQuery from "./useListQuery";

const useCurrentAuthor = () => {
  const user = useRecoilValue(userAtom);
  const [modal, setModal] = useRecoilState(modalAtom);
  const authorQuery = useListQuery<Author>(
    listAuthors,
    "listAuthors",
    { filter: { cognitoUsername: { eq: user?.username } } },
    { enabled: !!user && !modal },
  );

  if (!authorQuery.data) return null;

  if (authorQuery.data.length === 0 && !modal) {
    setModal(<NewAuthorModal />);
    return null;
  }
  return authorQuery.data[0];
};

export default useCurrentAuthor;
