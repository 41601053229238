import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Chapter, CreateSuggestionInput, CreateSuggestionMutation, SUGGESTION_STATUS } from "../../API";
import useApiMutation from "../../api/useApiMutation";
import useCurrentAuthor from "../../api/useCurrentAuthor";
import useGetQuery from "../../api/useGetQuery";
import MarkDownEditor from "../../components/MarkDownEditor/MarkDownEditor";
import * as mutations from "../../graphql/mutations";
import { getChapter } from "../../graphql/queries";
import { routes } from "../../ui/routes";
import history from "../../util/history";

const SuggestionNew: React.FC = () => {
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const [body, setBody] = useState("");
  const [message, setMessage] = useState("");
  const currentAuthor = useCurrentAuthor();
  const chapterQuery = useGetQuery<Chapter>(getChapter, "getChapter", id);
  const createSuggestion = useApiMutation<CreateSuggestionMutation, CreateSuggestionInput>(mutations.createSuggestion);

  const submitSuggestion = () => {
    if (
      !chapterQuery.data?.storyID ||
      typeof chapterQuery.data?.order !== "number" ||
      !chapterQuery.data.Story?.authorID ||
      !currentAuthor?.id
    ) {
      return;
    }

    createSuggestion.mutate(
      {
        authorID: currentAuthor?.id,
        body,
        storyID: chapterQuery.data?.storyID,
        chapterID: id,
        toID: chapterQuery.data.Story?.authorID,
        status: SUGGESTION_STATUS.PENDING,
        message,
      },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries("suggestionsByToID");
          history.push(
            routes.stories.show.chapter.with({
              id: chapterQuery.data?.storyID,
              chapterIndex: chapterQuery.data?.order,
            }),
          );
        },
      },
    );
  };
  useEffect(() => {
    setBody(chapterQuery.data?.body || "");
  }, [chapterQuery.data]);

  return (
    <div className="flex max-w-full">
      <div className="side-bar">
        <div className="relative w-full">
          <Link
            to={routes.stories.show.chapter.with({
              id: chapterQuery.data?.storyID,
              chapterIndex: chapterQuery.data?.order,
            })}
            className="btn-minimal text-left absolute w-full -mt-4"
          >
            <p>
              <ArrowBackIosIcon /> Back to Story
            </p>
            <p className="ml-7 text-sm font-extralight">{chapterQuery.data?.Story?.title}</p>
          </Link>
        </div>
      </div>
      <div className="flex-grow">
        <div className="border-b p-4 flex justify-between items-center">
          <h1 className="text-lg font-bold ">{chapterQuery.data?.title}</h1>
          <button onClick={submitSuggestion} className="btn-primary btn-lg">
            Submit Suggestion
          </button>
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-3 xl:gap-4 p-4">
          <div className="not-sticky py-4 col-span-2">
            <p className="font-bold pb-3">Your suggestions</p>
            <MarkDownEditor
              value={body}
              setValue={(value) => {
                setBody(value);
              }}
              uploadLocation={`chapters/${id}`}
              spellCheck={false}
            />
          </div>
          <div className="py-4 order-first xl:order-last">
            <label htmlFor="note" className="font-bold pb-3">
              Note to the Author
            </label>
            <textarea
              id="note"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="input w-full mt-2.5"
              rows={6}
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuggestionNew;
