/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { authAtom, modalAtom, User, userAtom } from "../atoms/atoms";
import { routes } from "../ui/routes";
import history from "../util/history";

const useAuthState: () => [AuthState | undefined, User | undefined] = () => {
  const [authState, setAuthState] = useRecoilState(authAtom);
  const [user, setUser] = useRecoilState(userAtom);
  const searchParams = new URLSearchParams(window.location.search);
  const redirect = searchParams.get("redirect");
  const [modal, setModal] = useRecoilState(modalAtom);
  const formatUserObj = (authData: any): User | undefined => {
    if (
      !authData ||
      !authData.attributes ||
      !authData.attributes.email ||
      !authData.signInUserSession ||
      !authData.signInUserSession.accessToken ||
      !authData.signInUserSession.accessToken.payload ||
      !authData.signInUserSession.accessToken.payload["cognito:groups"]
    )
      return undefined;
    return {
      username: authData.username,
      email: authData.attributes.email,
      groups: authData.signInUserSession.accessToken.payload["cognito:groups"],
    };
  };

  const updateAuthAndUserState = (nextAuthState: AuthState, authData: any) => {
    const oldAuthState = authState;
    setAuthState(nextAuthState);
    setUser(formatUserObj(authData));

    //See if we need to close the modal window
    if (oldAuthState !== nextAuthState && modal) {
      setModal(undefined);
      return;
    }

    //See if we need to redirect
    if (oldAuthState === AuthState.SignIn && nextAuthState === AuthState.SignedIn) {
      history.push(redirect ?? routes.dashboard.path);
    }
  };

  useEffect(() => {
    if (authState === undefined) {
      Auth.currentAuthenticatedUser()
        .then((authData: any) => {
          updateAuthAndUserState(AuthState.SignedIn, authData);
        })
        .catch((e: string) => {
          setAuthState(AuthState.SignedOut);
        });
    }

    return onAuthUIStateChange((nextAuthState, authData) => {
      updateAuthAndUserState(nextAuthState, authData);
    });
  }, [authState, setAuthState, setUser]);

  return [authState, user];
};

export default useAuthState;
