import RateReviewTwoToneIcon from "@material-ui/icons/RateReviewTwoTone";
import React from "react";
import { Link } from "react-router-dom";
import { FaveAuthorStoryUpdate, Suggestion, SuggestionsByToIDQueryVariables, SUGGESTION_STATUS } from "../../API";
import useCurrentAuthor from "../../api/useCurrentAuthor";
import useListQuery from "../../api/useListQuery";
import { suggestionsByToID } from "../../graphqlcustom/queries";
import { routes } from "../../ui/routes";
import DashboardCard from "./DashboardCard";

const PendingSuggestions: React.FC = () => {
  const currentAuthor = useCurrentAuthor();
  const query = useListQuery<Suggestion, SuggestionsByToIDQueryVariables>(
    suggestionsByToID,
    "suggestionsByToID",
    { toID: currentAuthor?.id || "", filter: { status: { eq: SUGGESTION_STATUS.PENDING } } },
    { enabled: !!currentAuthor?.id },
  );

  return (
    <DashboardCard
      title="Pending Suggestions"
      subtitle="Suggestions for your stories"
      query={query}
      emptyIcon={<RateReviewTwoToneIcon />}
      renderItem={(item: FaveAuthorStoryUpdate) => {
        return (
          <div key="item.id" className="text-sm">
            <Link
              to={routes.dashboard.suggestions.show.with({ id: item.id })}
              className="text-pink-600 hover:underline"
            >
              {item?.Story?.title}
            </Link>{" "}
            has a suggestion from{" "}
            <Link className="text-pink-600 hover:underline" to={routes.authors.show.with({ id: item?.authorID })}>
              {item?.Author?.name}
            </Link>{" "}
          </div>
        );
      }}
    />
  );
};

export default PendingSuggestions;
