/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router";
import { useSetRecoilState } from "recoil";
import {
  CreateFaveAuthorInput,
  CreateFaveAuthorMutation,
  DeleteFaveAuthorInput,
  DeleteFaveAuthorMutation,
  FaveAuthor,
  ListFaveAuthorsQueryVariables,
} from "../../API";
import useApiMutation from "../../api/useApiMutation";
import useCurrentAuthor from "../../api/useCurrentAuthor";
import useGetQuery from "../../api/useGetQuery";
import useListQuery from "../../api/useListQuery";
import { modalAtom } from "../../atoms/atoms";
import ToggleButton from "../../components/ToggleButton/ToggleButton";
import * as mutations from "../../graphql/mutations";
import { getFaveAuthor, listFaveAuthors } from "../../graphql/queries";
import Authenticator from "../Authenticator/Authenticator";

interface Props {
  authorName: string;
}

const FaveAuthorButton: React.FC<Props> = ({ authorName }) => {
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const setModal = useSetRecoilState(modalAtom);

  const currentAuthor = useCurrentAuthor();
  const compositeID = `${id}#${currentAuthor ? currentAuthor.id : ""}`;
  const faveAuthorQuery = useGetQuery<FaveAuthor>(getFaveAuthor, "getFaveAuthor", compositeID, {
    enabled: !!currentAuthor && !!id,
  });
  const listAuthors = useListQuery<FaveAuthor, ListFaveAuthorsQueryVariables>(listFaveAuthors, "listFaveAuthors", {
    filter: { authorID: { eq: id } },
  });

  const createFave = useApiMutation<CreateFaveAuthorMutation, CreateFaveAuthorInput>(mutations.createFaveAuthor);
  const deleteFave = useApiMutation<DeleteFaveAuthorMutation, DeleteFaveAuthorInput>(mutations.deleteFaveAuthor);

  const isFave = Boolean(faveAuthorQuery.data);

  const invalidateQueries = () => {
    queryClient.invalidateQueries("listFaveAuthors");
    queryClient.invalidateQueries("getFaveAuthor");
  };

  const toggleFave = () => {
    if (!currentAuthor || !currentAuthor.id) {
      setModal(<Authenticator isModal={true} />);
      return;
    }
    if (!isFave) {
      createFave.mutate(
        {
          id: compositeID,
          favedBy: currentAuthor.id,
          authorID: id,
        },
        {
          onSuccess: () => invalidateQueries(),
        },
      );
    } else {
      deleteFave.mutate(
        { id: compositeID },
        {
          onSuccess: () => invalidateQueries(),
        },
      );
    }
  };

  return (
    <div className="">
      <ToggleButton
        className="p-3 border rounded shadow dark:border-gray-600"
        label={`Follow ${authorName}`}
        checked={isFave}
        onChange={(value) => toggleFave()}
      />
      <div className="py-1">
        <p className="text-sm pt-1">
          Followers: <span className=" font-semibold">{listAuthors.data ? listAuthors.data.length : "Loading..."}</span>
        </p>
      </div>
    </div>
  );
};
export default FaveAuthorButton;
