import MenuBookTwoToneIcon from "@material-ui/icons/MenuBookTwoTone";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { FaveAuthorStoryUpdate, ListFaveAuthorStoryUpdatesQueryVariables } from "../../API";
import useCurrentAuthor from "../../api/useCurrentAuthor";
import useListQuery from "../../api/useListQuery";
import { listFaveAuthorStoryUpdatesWithStory } from "../../graphqlcustom/queries";
import { routes } from "../../ui/routes";
import DashboardCard from "./DashboardCard";

const StoryUpdates: React.FC = () => {
  const currentAuthor = useCurrentAuthor();
  const query = useListQuery<FaveAuthorStoryUpdate, ListFaveAuthorStoryUpdatesQueryVariables>(
    listFaveAuthorStoryUpdatesWithStory,
    "listFaveAuthorStoryUpdates",
    { filter: { authorID: { eq: currentAuthor?.id }, isNew: { eq: true } } },
    { enabled: !!currentAuthor },
  );

  return (
    <DashboardCard
      title="Story Updates"
      subtitle="New Stories from your followed authors"
      query={query}
      emptyIcon={<MenuBookTwoToneIcon />}
      renderItem={(item: FaveAuthorStoryUpdate) => {
        return (
          <div key="item.id" className="text-sm">
            <Link to={routes.stories.show.with({ id: item.storyID })} className="text-pink-600 hover:underline">
              {item?.Story?.title}
            </Link>{" "}
            from{" "}
            <Link
              className="text-pink-600 hover:underline"
              to={routes.authors.show.with({ id: item?.Story?.authorID })}
            >
              {item?.Story?.authorDisplayName}
            </Link>{" "}
            was published {moment(item.createdAt).fromNow()}
          </div>
        );
      }}
    />
  );
};

export default StoryUpdates;
