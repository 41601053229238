import { useQueryClient } from "react-query";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { CreateAuthorInput, CreateAuthorMutation } from "../../API";
import useApiMutation from "../../api/useApiMutation";
import { modalAtom, userAtom } from "../../atoms/atoms";
import * as mutations from "../../graphql/mutations";
import AuthorNameForm from "../AuthorNameForm/AuthorNameForm";
import ModalWrapper from "../ModalWrapper/ModalWrapper";

const NewAuthorModal: React.FC = () => {
  const queryClient = useQueryClient();
  const setModal = useSetRecoilState(modalAtom);
  const user = useRecoilValue(userAtom);

  const createAuthor = useApiMutation<CreateAuthorMutation, CreateAuthorInput>(mutations.createAuthor);

  const save = (name: string, nameIsTaken: boolean) => {
    if (!user || !user.username) {
      alert(
        "Well this is awkward. We could not retrieve your cognito userID. This should hopefully never happen...but if it does, good luck!",
      );
      return;
    }
    createAuthor.mutate(
      {
        id: user.username,
        name: name,
        nameLowerCase: name.toLowerCase(),
        cognitoUsername: user?.username,
      },
      {
        onSuccess: (data) => {
          queryClient.setQueryData(
            ["listAuthors", { filter: { cognitoUsername: { eq: user?.username } } }],
            data.createAuthor,
          );
          queryClient.invalidateQueries("listAuthors");
          setModal(undefined);
        },
        onError: (err) => {
          // eslint-disable-next-line
          console.log(err);
          alert("Error. Could not create profile.");
        },
      },
    );
  };

  return (
    <ModalWrapper>
      <div>
        <h1 className="text-3xl font-bold">Welcome to Giantess.land!</h1>
        <h2 className="text-lg text-gray-700  dark:text-gray-400">Please set up your profile</h2>
        <AuthorNameForm onSave={save} />
      </div>
    </ModalWrapper>
  );
};

export default NewAuthorModal;
