/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:7d103bfc-b9f9-4dae-a64d-7fba2d7e6581",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_1Xd7bo7bp",
    "aws_user_pools_web_client_id": "23v7rnoih4cjd6trquf0m4hsa",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://pnc4yrnea5fbvh3sqeb6u36omy.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "giantess-land150619-master",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
