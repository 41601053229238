/* eslint-disable @typescript-eslint/no-explicit-any */
import Select from "react-select";

interface Props {
  label: string;
  value: string[];
  options: string[];
  onChange: (values: string[]) => void;
}

type Option = { value: string; label: string };

function convertStringArrayToOptionsArray(values: string[]): Option[] {
  return values.map((item) => ({ label: item, value: item }));
}

function converOptionsArrayToStringArray(values: any[]): string[] {
  return values.map((item) => item.value);
}

const MultiSelect: React.FC<Props> = ({ label, value, options, onChange }) => {
  const handleChange = (values: any) => {
    //setSelected(values);
    onChange(converOptionsArrayToStringArray(values));
  };

  return (
    <div className="flex flex-col">
      <label htmlFor="description" className="py-2">
        {label}
      </label>

      <Select
        isMulti
        name={label}
        value={convertStringArrayToOptionsArray(value)}
        onChange={(values) => handleChange(values)}
        options={convertStringArrayToOptionsArray(options)}
        className="basic-multi-select"
        classNamePrefix="select"
        menuColor="red"
        closeMenuOnSelect={false}
      />
    </div>
  );
};

export default MultiSelect;
