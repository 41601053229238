import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { isEqual, pick } from "lodash";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { Link, Prompt, useHistory, useParams } from "react-router-dom";
import {
  CreateStoryInput,
  DeleteStoryInput,
  DeleteStoryMutation,
  Story,
  UpdateStoryInput,
  UpdateStoryMutation,
} from "../../API";
import useApiMutation from "../../api/useApiMutation";
import useGetQuery from "../../api/useGetQuery";
import useListQuery from "../../api/useListQuery";
import MultiSelect from "../../components/MuiltiSelect/MultiSelect";
import TextRange from "../../components/TextRange/TextRange";
import * as mutations from "../../graphql/mutations";
import { getStory, listCategorys } from "../../graphql/queries";
import { routes } from "../../ui/routes";
import ChapterList from "./ChapterList";
import { growthMarks } from "./growthMarks";
import { ratings } from "./ratings";
import { shrinkMarks } from "./shrinkMarks";

const EditStory: React.FC = () => {
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const categoryQuery = useListQuery<{ name: string }>(listCategorys, "listCategorys", {});
  const categoryOptions = categoryQuery.data ? categoryQuery.data.map((item) => item.name) : [];
  const [originalStoryState, setOriginalStoryState] = useState<CreateStoryInput | null>(null);
  const [storyState, setStoryState] = useState<CreateStoryInput | null>(null);
  const storyQuery = useGetQuery<Story>(getStory, "getStory", id);

  useEffect(() => {
    if (storyQuery.data) {
      const storyData = pick(storyQuery.data, [
        "id",
        "title",
        "description",
        "categories",
        "rating",
        "growth",
        "shrink",
        "isPublished",
        "score",
        "authorName",
      ]) as CreateStoryInput;
      setStoryState(storyData);
      setOriginalStoryState(storyData);
    }
  }, [storyQuery.data]);

  const updateStory = useApiMutation<UpdateStoryMutation, UpdateStoryInput>(mutations.updateStory);
  const deleteStory = useApiMutation<DeleteStoryMutation, DeleteStoryInput>(mutations.deleteStory);

  const invalidate = () => {
    queryClient.invalidateQueries("listFaveAuthorStoryUpdates");
    queryClient.invalidateQueries("getFaveAuthorStoryUpdate");
    queryClient.invalidateQueries("listFaveStorys");
    queryClient.invalidateQueries("listStorys");
  };

  const saveStory = async () => {
    if (!storyState) return;
    const story: UpdateStoryInput = { ...storyState, id };
    updateStory.mutate(story);
    queryClient.setQueryData(["getStory", id], story);
    invalidate();
  };

  const delStory = () => {
    const conf = window.confirm("Are you sure you want to delete this story?");
    if (!conf) {
      return;
    }
    deleteStory.mutate(
      { id: id },
      {
        onSuccess: (data) => {
          if (data.deleteStory === null) {
            throw { errors: [{ message: "Error: Could not delete story" }] };
          }
          invalidate();
          history.push(routes.dashboard.myStories.path);
        },
        onError: (error) => {
          alert(error.errors[0].message);
        },
      },
    );
  };

  const hasChanged = !isEqual(storyState, originalStoryState);

  if (!storyState) {
    return <div className="flex justify-center py-20">Loading...</div>;
  }

  return (
    <div className="max-w-screen-lg mx-auto py-3.5 px-4">
      <Prompt when={hasChanged} message="You have unsaved changes. Are you sure you want to continue?" />
      <div>
        <div className="flex justify-between items-center">
          <Link to={routes.dashboard.myStories.path} className="flex items-center text-pink-600 py-2">
            <ArrowBackIosIcon fontSize="small" />
            My Stories
          </Link>
          <Link
            to={routes.stories.show.with({ id: id })}
            target="_blank"
            className="text-pink-600 hover:underline flex items-center space-x-2"
          >
            <span>Preview</span> <OpenInNewIcon fontSize="small" />
          </Link>
        </div>
        <div className="flex items-center justify-between my-4">
          <h1 className="text-2xl">{storyState?.title}</h1>
          <div className="flex space-x-3 items-center">
            <label className="flex items-center space-x-3 border dark:bg-gray-800 dark:border-gray-600 py-1 pl-1 pr-2 rounded shadow cursor-pointer">
              <input
                type="checkbox"
                name="spellcheck"
                checked={storyState.isPublished}
                onChange={() =>
                  setStoryState((old) => ({ ...(old as CreateStoryInput), isPublished: Boolean(!old?.isPublished) }))
                }
                className="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none"
              />
              <span className="text-gray-900 dark:text-white">Published</span>
            </label>
            <button onClick={saveStory} className="btn-default" title={hasChanged ? "Unsaved changes" : ""}>
              {hasChanged && (
                <>
                  <span className="animate-ping absolute ml-[34px] mt-[-8px] mar h-3 w-3 block bg-pink-200 rounded-full"></span>
                  <span className="absolute ml-[34px] mt-[-8px] mar h-3 w-3 block bg-pink-600 rounded-full"></span>
                </>
              )}
              <span>Save</span>
            </button>
          </div>
        </div>
        <div className="bg-gray-50  dark:bg-gray-700 rounded-lg shadow-lg p-8 my-4">
          <div className="flex space-x-4">
            <div className="flex-grow flex flex-col">
              <label htmlFor="title" className="py-2">
                Title
              </label>
              <input
                name="title"
                value={storyState?.title}
                onChange={(e) => setStoryState({ ...storyState, title: e.target.value } as Story)}
                className="input"
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="rating" className="py-2">
                Rating
              </label>
              <select
                name="rating"
                value={storyState?.rating ?? ""}
                onBlur={(e) => setStoryState({ ...storyState, rating: e.target.value } as Story)}
                onChange={(e) => setStoryState({ ...storyState, rating: e.target.value } as Story)}
                className="input"
              >
                <option></option>
                {ratings.map((rating) => (
                  <option key={rating}>{rating}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex flex-col">
            <label htmlFor="description" className="py-2">
              Description
            </label>
            <textarea
              rows={6}
              name="description"
              className="input"
              value={storyState?.description ?? ""}
              onChange={(e) => setStoryState({ ...storyState, description: e.target.value } as Story)}
            ></textarea>
          </div>
          <MultiSelect
            label="Categories"
            value={storyState.categories ? storyState.categories : []}
            onChange={(values) => setStoryState((old) => ({ ...(old as CreateStoryInput), categories: values }))}
            options={categoryOptions}
          />
          <TextRange
            label="Growth"
            values={storyState.growth}
            onChange={(values) => setStoryState((old) => ({ ...(old as CreateStoryInput), growth: values }))}
            marks={growthMarks}
          />
          <TextRange
            label="Shrink"
            values={storyState.shrink}
            onChange={(values) => setStoryState((old) => ({ ...(old as CreateStoryInput), shrink: values }))}
            marks={shrinkMarks}
          />

          <ChapterList />

          <button onClick={delStory} className="btn-danger  mt-4">
            Delete Story
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditStory;
