import React from "react";
import { useQueryClient } from "react-query";
import { useSetRecoilState } from "recoil";
import {
  Category,
  DeleteCategoryInput,
  DeleteCategoryMutation,
  UpdateCategoryInput,
  UpdateCategoryMutation,
} from "../../API";
import useApiMutation from "../../api/useApiMutation";
import { modalAtom } from "../../atoms/atoms";
import ModalWrapper from "../../components/ModalWrapper/ModalWrapper";
import * as mutations from "../../graphql/mutations";
import StoryCategoryForm, { StoryCategory } from "./StoryCategoryForm";

interface Props {
  cat: Category;
}

const StoryCategoryEdit: React.FC<Props> = ({ cat }) => {
  const setModal = useSetRecoilState(modalAtom);
  const queryClient = useQueryClient();
  const createCategory = useApiMutation<UpdateCategoryMutation, UpdateCategoryInput>(mutations.updateCategory);
  const deleteCategory = useApiMutation<DeleteCategoryMutation, DeleteCategoryInput>(mutations.deleteCategory);

  const handleSave = ({ name, description }: StoryCategory) => {
    if (!cat.id) {
      throw new Error("Error: Missing category ID.");
    }
    createCategory.mutate(
      { id: cat.id, name, description, typename: "Category" },
      {
        onSuccess: () => {
          setModal(undefined);
          queryClient.invalidateQueries("categoriesSrtByName");
        },
        onError: (err) => alert(err),
      },
    );
  };

  const handleDelete = () => {
    if (!cat.id) {
      throw new Error("Error: Missing category ID.");
    }
    /* eslint-disable-next-line */
    const conf = confirm("Are you sure you want to delete this category?");
    if (!conf) return;
    deleteCategory.mutate(
      { id: cat.id },
      {
        onSuccess: () => {
          setModal(undefined);
          queryClient.invalidateQueries("categoriesSrtByName");
        },
        onError: (err) => alert(err),
      },
    );
  };
  return (
    <ModalWrapper showCloseButton title="Edit Category">
      <div className=" sm:w-96">
        <StoryCategoryForm
          initState={{ name: cat.name || "", description: cat.description || "" }}
          onSubmit={handleSave}
          buttonText="Update"
        />
        <button onClick={() => handleDelete()} className="btn-danger ml-3">
          Delete
        </button>
      </div>
    </ModalWrapper>
  );
};

export default StoryCategoryEdit;
