import { Range } from "rc-slider";
import { displayRange } from "../../util/displayRange";

interface Props {
  label: string;
  values?: string[] | null;
  onChange: (value: string[]) => void;
  marks: Record<number, string>;
}

const TextRange: React.FC<Props> = ({ label, values, onChange, marks }) => {
  const marksArray = Object.entries(marks);

  const valueToRange = () => {
    if (!values) {
      return [0, 0];
    }
    const firstValue = values[0];
    const lastValue = values[values.length - 1];
    const firstIndex = marksArray.findIndex((item) => item[1] === firstValue);
    const lastIndex = marksArray.findIndex((item) => item[1] === lastValue);
    const returnFirstIndex = firstIndex < 0 ? 0 : firstIndex;
    const returnLastIndex = lastIndex < 0 ? 0 : lastIndex;
    return [returnFirstIndex, returnLastIndex];
  };

  const handleChange = (values: number[]) => {
    const slice = marksArray.slice(values[0], values[1] + 1);
    const textArray = slice.map((item) => item[1]);
    onChange(textArray);
  };

  return (
    <div className="flex flex-col pb-8">
      <div className="py-2">
        {label} ({displayRange(values)})
      </div>
      <div className="px-5">
        <Range marks={marks} max={marksArray.length - 1} value={valueToRange()} onChange={handleChange} />
      </div>
    </div>
  );
};

export default TextRange;
