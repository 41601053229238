import classNames from "classnames";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { FaveStory, ReadStory, Story } from "../../API";
import useListQuery from "../../api/useListQuery";
import { userAtom } from "../../atoms/atoms";
import { faveStoriesByAuthor, readStoriesByAuthor } from "../../graphql/queries";
import { routes } from "../../ui/routes";
import { displayRange } from "../../util/displayRange";

export enum LinkTo {
  Show,
  Edit,
}

interface Props {
  data: Story[];
  linkTo: LinkTo;
}

const StoryList: React.FC<Props> = ({ data, linkTo }) => {
  const [t] = useTranslation();
  const userState = useRecoilValue(userAtom);
  const storiesFilterArray = data.map((story) => ({ storyID: { eq: story.id } }));
  const readStoryQuery = useListQuery<ReadStory>(
    readStoriesByAuthor,
    "readStoriesByAuthor",
    { authorID: userState?.username, filter: { or: storiesFilterArray } },
    { enabled: !!userState?.username && storiesFilterArray.length > 0 },
  );

  const faveStoryQuery = useListQuery<FaveStory>(
    faveStoriesByAuthor,
    "faveStoriesByAuthor",
    { authorID: userState?.username, filter: { or: storiesFilterArray } },
    { enabled: !!userState?.username && storiesFilterArray.length > 0 },
  );

  return (
    <div className="grid grid-flow-row grid-cols-1 gap-4 mt-4">
      {data.map((item: Story) => {
        const readStory = (readStoryQuery.data || []).find((story) => story.storyID === item.id);
        const faveStory = (faveStoryQuery.data || []).find((story) => story.storyID === item.id);
        const unreadChapters =
          typeof readStory?.maxChapterIndex === "number" && item?.chapterCount
            ? item.chapterCount - (readStory.maxChapterIndex! + 1)
            : 0;
        const nextChapter =
          typeof readStory?.maxChapterIndex === "number" && typeof item?.chapterCount === "number"
            ? Math.min(readStory.maxChapterIndex + 1, item.chapterCount - 1)
            : 0;

        const publishClass = classNames(
          "h-3 w-3 inline-block mt-0.5 rounded-full",
          {
            "bg-green-500": item.isPublished,
          },
          {
            "bg-yellow-500": !item.isPublished,
          },
        );
        return (
          <div key={item.id} className="border-t dark:border-gray-600 pt-4">
            {/* Show Link */}
            {linkTo === LinkTo.Show && (
              <div>
                <Link
                  // to={routes.stories.show.with({ id: item.id })}
                  to={routes.stories.show.chapter.with({ id: item.id, chapterIndex: nextChapter })}
                  className="text-pink-600 hover:underline font-bold"
                >
                  {item.title}
                </Link>{" "}
                <span className="text-sm font-normal">
                  by{" "}
                  <Link to={routes.authors.show.with({ id: item.authorID })} className="text-pink-600 hover:underline">
                    {item.authorDisplayName}
                  </Link>
                </span>
              </div>
            )}

            {/* Edit Link */}
            {linkTo === LinkTo.Edit && (
              <div className="flex items-center space-x-2">
                <Link
                  to={routes.dashboard.myStories.edit.with({ id: item.id })}
                  className="text-pink-600 hover:underline font-bold"
                >
                  {item.title}
                </Link>
                <span className={publishClass} title={item.isPublished ? "Published" : "Not Published"}></span>
              </div>
            )}

            <div className="flex space-x-2 py-1">
              {/* Unread Chapters */}
              {unreadChapters > 0 && (
                <button className="inline-block">
                  <div className="border border-pink-600 rounded text-pink-600 bg-pink-50 dark:bg-gray-900 dark:text-white dark:border-pink-900 pl-0.5 pr-2 py-0.5 text-xs flex space-x-2 items-center font-bold">
                    <span role="img" aria-label="fire emoji">
                      🔥
                    </span>
                    <span>{t("common.unreadChapter", { count: unreadChapters })}</span>
                  </div>
                </button>
              )}

              {/* Fave Story */}
              {faveStory && (
                <button className="inline-block" title={`Favorited ${moment(faveStory.createdAt).fromNow()}`}>
                  <div className="border border-pink-600 rounded text-pink-600 bg-pink-50 dark:bg-gray-900 dark:text-white dark:border-pink-900 pl-0.5 pr-2 py-0.5 text-xs flex space-x-2 items-center font-bold">
                    <span role="img" aria-label="heart emoji">
                      ❤️
                    </span>
                    <span>Faved</span>
                  </div>
                </button>
              )}
            </div>

            <p className=" max-w-screen-md text-sm text-gray-600 dark:text-gray-300">{item.description}</p>
            <div className="flex space-x-10">
              <div className="text-gray-500 dark:text-gray-400 text-xs flex flex-col py-2 space-y-0.5">
                <p>
                  Rated: <span className="text-black dark:text-gray-50 font-semibold">{item.rating}</span>
                </p>
                <p>
                  Growth:{" "}
                  <span className="text-black dark:text-gray-50 font-semibold">{displayRange(item.growth)}</span>
                </p>
                <p>
                  Shrink:{" "}
                  <span className="text-black dark:text-gray-50 font-semibold">{displayRange(item.shrink)}</span>
                </p>
              </div>
              <div className="text-gray-500 dark:text-gray-400 text-xs flex flex-col py-2 space-y-0.5">
                <p>
                  Chapters: <span className="text-black dark:text-gray-50 font-semibold">{item.chapterCount}</span>
                </p>
                <p>
                  Words: <span className="text-black dark:text-gray-50 font-semibold">{item.wordCount}</span>
                </p>
                <p>
                  Likes: <span className="text-black dark:text-gray-50 font-semibold">{item.score}</span>
                </p>
              </div>
              <div className="text-gray-500 dark:text-gray-400 text-xs flex flex-col py-2 space-y-0.5">
                <p>
                  Created:{" "}
                  <span className="text-black dark:text-gray-50 font-semibold">
                    {moment(item.createdAt).format("MMMM Do YYYY")}
                  </span>
                </p>
                <p>
                  Updated:{" "}
                  <span className="text-black dark:text-gray-50 font-semibold">
                    {moment(item.lastChapterAdded).fromNow()}
                  </span>
                </p>
              </div>
            </div>
            <div className="flex space-x-1.5 items-center py-2">
              {item.categories &&
                item.categories.map((category: string) => (
                  <span
                    key={category}
                    className="text-xs bg-gray-50 dark:bg-gray-700 border dark:border-gray-600 rounded py-0.5 px-1"
                  >
                    {category}
                  </span>
                ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default StoryList;
