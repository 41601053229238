import { DataSearch, DynamicRangeSlider } from "@appbaseio/reactivesearch";
import React from "react";
import { sortByArray } from "../../util/sortByArray";
import { growthMarks } from "../StoryForm/growthMarks";
import { ratings } from "../StoryForm/ratings";
import { shrinkMarks } from "../StoryForm/shrinkMarks";
import StoryFilterMultiDropdownList from "./StoryFilterMultiDropdownList";

export const reactiveList = ["Search", "Category", "Rating", "Growth", "Shrink", "Word Count", "Chapter Count"];

export const defaultQuery = () => ({
  query: {
    match: {
      isPublished: true,
    },
  },
});

const StoryFilters: React.FC = () => {
  return (
    <>
      <DataSearch
        componentId="Search"
        dataField={["title", "description", "authorDisplayName"]}
        defaultQuery={defaultQuery}
        URLParams
        innerClass={{
          input: "input !rounded-none",
        }}
      />
      <div className="my-2">
        <StoryFilterMultiDropdownList name="Category" dataField="categories.keyword" showSearch queryFormat="and" />
        <StoryFilterMultiDropdownList
          name="Rating"
          dataField="rating.keyword"
          transformData={(items) => sortByArray(items, ratings)}
        />
        <StoryFilterMultiDropdownList
          name="Growth"
          dataField="growth.keyword"
          transformData={(items) => sortByArray(items, Object.values(growthMarks))}
        />
        <StoryFilterMultiDropdownList
          name="Shrink"
          dataField="shrink.keyword"
          transformData={(items) => sortByArray(items, Object.values(shrinkMarks))}
        />
      </div>
      <DynamicRangeSlider
        componentId="Word Count"
        dataField="wordCount"
        rangeLabels={(min, max) => ({
          start: `${min} words`,
          end: `${max} words`,
        })}
        renderTooltipData={(data) => (
          <>
            {data} <span className="text-xs">words</span>
          </>
        )}
        tooltipTrigger="focus"
        URLParams
        innerClass={{
          label: "dark:text-white",
        }}
      />
      <DynamicRangeSlider
        componentId="Chapter Count"
        dataField="chapterCount"
        rangeLabels={(min, max) => ({
          start: `${min} chapter`,
          end: `${max} chapters`,
        })}
        renderTooltipData={(data) => (
          <>
            {data} <span className="text-xs">chapters</span>
          </>
        )}
        tooltipTrigger="focus"
        URLParams
        innerClass={{
          label: "dark:text-white",
        }}
      />
    </>
  );
};

export default StoryFilters;
