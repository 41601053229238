import CloseIcon from "@material-ui/icons/Close";
import { Storage } from "aws-amplify";
import DOMPurify from "dompurify";
import SimpleMDE from "easymde";
import "easymde/dist/easymde.min.css";
import { isEqual, pick } from "lodash";
import React, { useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { Prompt, useParams } from "react-router";
import SimpleMDEReact from "react-simplemde-editor";
import { CreateChapterInput, CreateChapterMutation, UpdateChapterInput, UpdateChapterMutation } from "../../API";
import useApiMutation, { ApiError } from "../../api/useApiMutation";
import * as mutations from "../../graphql/mutations";
import generatePublicURL from "../../util/generatePublicURL";

export interface ChapterSavePayload {
  title: string;
  body: string;
  authorNotesBefore: string;
  authorNotesAfter: string;
}
interface Props {
  chapter: CreateChapterInput;
  closeDrawer: () => void;
}
const ChapterDrawer: React.FC<Props> = ({ chapter, closeDrawer }) => {
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  //Chapter State
  const [title, setTitle] = useState(chapter.title ?? "");
  const [body, setBody] = useState(chapter.body ?? "");
  const [authorNotesBefore, setAuthorNotesBefore] = useState(chapter.authorNotesBefore ?? "");
  const [authorNotesAfter, setAuthorNotesAfter] = useState(chapter.authorNotesAfter ?? "");
  const [isPublished, setIsPublished] = useState(chapter.isPublished ?? false);

  //Drawer State
  const [spellCheck, setSpellCheck] = useState(false);
  const [error, setError] = useState<undefined | ApiError>();

  const createChapter = useApiMutation<CreateChapterMutation, CreateChapterInput>(mutations.createChapter);
  const updateChapter = useApiMutation<UpdateChapterMutation, UpdateChapterInput>(mutations.updateChapter);

  const newChapterState = { title, body, authorNotesAfter, authorNotesBefore, isPublished };
  const originalChapterState = pick(chapter, ["title", "body", "authorNotesAfter", "authorNotesBefore", "isPublished"]);
  const hasChanged = !isEqual(newChapterState, originalChapterState);

  const close = () => {
    if (hasChanged) {
      const shouldClose = window.confirm("You have unsaved changes. Are you sure you want to continue?");
      if (!shouldClose) {
        return;
      }
    }
    closeDrawer();
  };

  const saveChapter = () => {
    if (chapter.id) {
      updateChapter.mutate(
        { id: chapter.id, title, body, isPublished, authorNotesBefore, authorNotesAfter },
        {
          onSuccess: () => handleSuccess(),
          onError: (err) => setError(err),
        },
      );
    } else {
      createChapter.mutate(
        { storyID: id, title, body, order: chapter.order!, isPublished, authorNotesBefore, authorNotesAfter },
        {
          onSuccess: () => handleSuccess(),
          onError: (err) => setError(err),
        },
      );
    }
  };

  function handleSuccess() {
    queryClient.invalidateQueries("chaptersByOrder");
    queryClient.invalidateQueries("listFaveStorys");
    queryClient.invalidateQueries("listStorys");
    queryClient.invalidateQueries("readStoriesByAuthor");
    closeDrawer();
  }

  const handleUploadImage = async (
    image: File,
    onSuccess: (imageUrl: string) => void,
    onError: (errorMessage: string) => void,
  ) => {
    const { name, size, type } = image;
    //Check file size
    if (size > 1024 * 1024 * 3) {
      onError("Image is too large. Please keep it under 3mb.");
      return;
    }
    //Check that it is an image
    if (!["image/png", "image/jpeg", "image/gif"].includes(type)) {
      onError("Please make sure the image is one of these types: PNG, JPG, GIF.");
      return;
    }

    try {
      const result = await Storage.put(`chapters/${chapter.id}/${name}`, image, {
        contentType: type,
        cacheControl: "max-age=31536000",
      });
      const { key } = result;
      const imageURL = generatePublicURL(key);
      onSuccess(imageURL);
    } catch (e) {
      onError(e.message);
    }
  };

  const simpleMDEOptions = useMemo(() => {
    return {
      autofocus: false,
      spellChecker: spellCheck,
      uploadImage: true,
      placeholder: "Type here...",
      showIcons: ["heading-1", "heading-2", "heading-3", "code", "horizontal-rule", "clean-block", "upload-image"],
      hideIcons: ["heading", "image"],
      status: ["autosave", "lines", "words", "upload-image"],
      previewClass: "markdown",
      previewImagesInEditor: true,
      imageUploadFunction: handleUploadImage,
      renderingConfig: {
        singleLineBreaks: true,
        sanitizerFunction: function (renderedHTML) {
          return DOMPurify.sanitize(renderedHTML, {
            ALLOWED_TAGS: [
              "h1",
              "h2",
              "h3",
              "b",
              "br",
              "p",
              "code",
              "a",
              "img",
              "ul",
              "ol",
              "li",
              "blockquote",
              "em",
              "hr",
            ],
          });
        },
      },
    } as SimpleMDE.Options;
  }, [spellCheck]);

  return (
    <div className="top-0 right-0 fixed h-screen w-screen max-w-screen-lg bg-gray-50 shadow-2xl overflow-y-auto border-l z-20 dark:bg-gray-700 dark:border-gray-600">
      <div className="flex justify-end p-1 fixed right-0 mr-4">
        <button
          onClick={close}
          className="block h-8 w-8 text-gray-500 hover:text-pink-600 hover:bg-gray-200 dark:hover:bg-gray-800 rounded-full"
        >
          <CloseIcon />
        </button>
      </div>
      <Prompt when={hasChanged} message="You have unsaved changes. Are you sure you want to continue?" />
      <div className="p-10">
        <div className="flex justify-between items-center">
          <h2 className="text-xl">Edit Chapter</h2>
          <div className="flex space-x-3 items-center">
            {error && error.errors.length > 0 && (
              <span className="bg-red-200 text-red-700 rounded px-2 py-1">Error: {error.errors[0].message}</span>
            )}
            <label className="flex items-center space-x-3 border py-1 pl-1 pr-2 rounded shadow cursor-pointer bg-white dark:bg-gray-800 dark:border-gray-600">
              <input
                type="checkbox"
                name="spellcheck"
                checked={isPublished}
                onChange={() => setIsPublished((old) => !old)}
                className="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none"
              />
              <span className="text-gray-900 dark:text-gray-100">Published</span>
            </label>
            <button
              onClick={saveChapter}
              className="btn-default"
              disabled={createChapter.isLoading || updateChapter.isLoading}
            >
              {hasChanged && (
                <>
                  <span className="animate-ping absolute ml-[34px] mt-[-8px] mar h-3 w-3 block bg-pink-200 rounded-full"></span>
                  <span className="absolute ml-[34px] mt-[-8px] mar h-3 w-3 block bg-pink-600 rounded-full"></span>
                </>
              )}
              <span>Save</span>
            </button>
          </div>
        </div>
        <div className="flex flex-col">
          <label htmlFor="title" className="py-2">
            Title
          </label>
          <input name="title" className="input" value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
        <label className="flex items-center space-x-3 mt-3">
          <input
            type="checkbox"
            name="spellcheck"
            checked={spellCheck}
            onChange={() => setSpellCheck((old) => !old)}
            className="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none "
          />
          <span className="text-gray-900 font-medium dark:text-white">Spell Check</span>
        </label>
        <div className="pt-4">
          <SimpleMDEReact value={body} onChange={(value) => setBody(value)} options={simpleMDEOptions} />
        </div>
        <div className="flex flex-col">
          <label htmlFor="title" className="py-2">
            Author Notes: Before Chapter
          </label>
          <textarea
            name="title"
            className="input"
            value={authorNotesBefore}
            onChange={(e) => setAuthorNotesBefore(e.target.value)}
          ></textarea>
        </div>
        <div className="flex flex-col">
          <label htmlFor="title" className="py-2">
            Author Notes: After Chapter
          </label>
          <textarea
            name="title"
            className="input"
            value={authorNotesAfter}
            onChange={(e) => setAuthorNotesAfter(e.target.value)}
          ></textarea>
        </div>
      </div>
    </div>
  );
};

export default ChapterDrawer;
