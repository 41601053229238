import React from "react";
import { useParams } from "react-router";
import { Author, Story } from "../../API";
import useGetQuery from "../../api/useGetQuery";
import useListQuery from "../../api/useListQuery";
import StoryList, { LinkTo } from "../../components/StoryList/StoryList";
import { getAuthor, listStorys } from "../../graphql/queries";
import FaveAuthorButton from "./FaveAuthorButton";

const AuthorShow: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const author = useGetQuery<Author>(getAuthor, "getAuthor", id);
  const query = useListQuery<Story>(listStorys, "listStorys", { filter: { authorID: { eq: id } } });

  return (
    <div className="flex">
      <div className=" side-bar">{author.data && <FaveAuthorButton authorName={author.data.name!} />}</div>
      <div className="p-4 w-full">
        <div className="pt-3.5 h-12 flex justify-between">
          <h1 className="text-lg font-bold">{author.data?.name}'s Stories</h1>
        </div>
        <div>{query.data && <StoryList data={query.data} linkTo={LinkTo.Show} />}</div>
      </div>
    </div>
  );
};

export default AuthorShow;
