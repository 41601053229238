import { AmplifyAuthenticator, AmplifySignIn, AmplifySignOut, AmplifySignUp } from "@aws-amplify/ui-react";
import CloseIcon from "@material-ui/icons/Close";
import Amplify from "aws-amplify";
import classNames from "classnames";
import React from "react";
import { useSetRecoilState } from "recoil";
import { modalAtom } from "../../atoms/atoms";
import awsconfig from "../../aws-exports";
import Message from "../../components/Message/Message";

Amplify.configure(awsconfig);

interface Props {
  isModal: boolean;
}

const Authenticator: React.FC<Props> = ({ isModal }) => {
  const setModal = useSetRecoilState(modalAtom);

  const wrapperClass = classNames(
    {
      "mt-10": !isModal,
    },
    {
      "fixed flex justify-center items-center z-20 w-full bg-black bg-opacity-50 h-screen": isModal,
    },
  );
  return (
    <>
      <div className={wrapperClass}>
        <Message />
        <div>
          {isModal && (
            <div className="flex justify-end">
              <button
                className="text-black fixed mt-2 mr-2 z-40 hover:text-pink-600"
                onClick={() => setModal(undefined)}
              >
                <CloseIcon fontSize="large" />
              </button>
            </div>
          )}
          <AmplifyAuthenticator>
            <div className="w-40 mx-auto mt-40">
              <AmplifySignOut />
            </div>
            <AmplifySignUp
              slot="sign-up"
              usernameAlias="email"
              formFields={[
                {
                  type: "email",
                  label: "Email",
                  placeholder: "Email",
                  required: true,
                },
                {
                  type: "password",
                  label: "Password",
                  placeholder: "Password",
                  required: true,
                },
              ]}
            />
            <AmplifySignIn slot="sign-in" usernameAlias="email" />
          </AmplifyAuthenticator>
        </div>
      </div>
    </>
  );
};

export default Authenticator;
