type ListItem = { key: string; doc_count: number };

export const sortByArray = (items: ListItem[], sortArray: string[]) => {
  items.sort((a, b) => {
    const aIndex = sortArray.indexOf(a.key);
    const bIndex = sortArray.indexOf(b.key);
    return aIndex - bIndex;
  });
  return items;
};
