import classNames from "classnames";
import React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

interface Props extends Omit<NavLinkProps, "to"> {
  to: string;
  children: React.ReactNode;
}

const NavigationLink: React.FC<Props> = ({ to, children, ...rest }) => {
  const navClasses = classNames(
    "p-2 rounded hover:bg-gray-200 dark:hover:bg-gray-900 transition duration-300 flex items-center space-x-2",
  );
  const activeNavClasses = classNames("p-2 bg-gray-200 dark:bg-gray-900 rounded shadow-inner");
  return (
    <NavLink to={to} className={navClasses} activeClassName={activeNavClasses} {...rest}>
      {children}
    </NavLink>
  );
};

export default NavigationLink;
