import React from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { modalAtom } from "../atoms/atoms";
import ProtectedRoute from "../components/ProtectedRoute/ProtectedRoute";
import useAuthState from "../hooks/useAuthState";
import AdminLayout from "../layout/AdminLayout";
import DashboardLayout from "../layout/DashboardLayout";
import DefaultLayout from "../layout/DefaultLayout";
import AdminDashboard from "../pages/AdminDashboard/AdminDashboard";
import Authenticator from "../pages/Authenticator/Authenticator";
import AuthorShow from "../pages/AuthorShow/AuthorShow";
import Dashboard from "../pages/Dashboard/Dashboard";
import Donate from "../pages/Donate/Donate";
import FavoriteStories from "../pages/FavoriteStories/FavoriteStories";
import FollowedAuthors from "../pages/FollowedAuthors/FollowedAuthors";
import Home from "../pages/Home/Home";
import MyStories from "../pages/MyStories/MyStories";
import Profile from "../pages/Profile/Profile";
import Recent from "../pages/Recent/Recent";
import StoryCategoryIndex from "../pages/StoryCategory/StoryCategoryIndex";
import EditStory from "../pages/StoryForm/EditStory";
import NewStory from "../pages/StoryForm/NewStory";
import StoryIndex from "../pages/StoryIndex/StoryIndex";
import StoryShow from "../pages/StoryShow/StoryShow";
import SuggestionIndex from "../pages/SuggestionIndex/SuggestionIndex";
import SuggestionNew from "../pages/SuggestionNew/SuggestionNew";
import SuggestionShow from "../pages/SuggestionShow/SuggestionShow";
import history from "../util/history";
import { routes } from "./routes";

const UnhandledError: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error?.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

const errorHandler = (error: Error, info: { componentStack: string }) => {
  // Do something with the error
  // E.g. log to an error logging client here
};

const App: React.FC = () => {
  const modal = useRecoilValue(modalAtom);
  const [authState] = useAuthState();

  if (!authState) {
    return <div className="flex justify-center items-center pt-20 text-gray-400 h-screen">Loading...</div>;
  }

  return (
    <ErrorBoundary FallbackComponent={UnhandledError} onError={errorHandler}>
      {modal && modal}
      <Router history={history}>
        <DefaultLayout>
          <Switch>
            <Route path={routes.path} exact component={Home} />
            <Route path={routes.authenticate.path} component={Authenticator} />
            <Route path={routes.recent.path} component={Recent} />
            <Route path={routes.stories.show.chapter.path} component={StoryShow} />
            <Route path={routes.stories.show.path} component={StoryShow} />
            <Route path={routes.stories.path} component={StoryIndex} />
            <Route path={routes.authors.show.path} component={AuthorShow} />
            <Route path={routes.donate.path} component={Donate} />
            <Route path={routes.profile.path} component={Profile} />
            <ProtectedRoute path={routes.chapters.show.suggestion.path} comp={SuggestionNew} />
            <ProtectedRoute path={routes.admin.path}>
              <AdminLayout>
                <Switch>
                  <ProtectedRoute
                    path={routes.admin.storyCategories.path}
                    comp={StoryCategoryIndex}
                    redirectTo={routes.admin.path}
                    requiredGroup="admin"
                    redirectMessage="You do not have access to story categories."
                  />
                  <ProtectedRoute
                    path={routes.admin.path}
                    comp={AdminDashboard}
                    redirectMessage="Please sign in to view admin dashboard"
                  />
                </Switch>
              </AdminLayout>
            </ProtectedRoute>
            <Route path={routes.dashboard.path}>
              <DashboardLayout>
                <Switch>
                  <ProtectedRoute path={routes.dashboard.myStories.new.path} comp={NewStory} />
                  <ProtectedRoute path={routes.dashboard.myStories.edit.path} comp={EditStory} />
                  <ProtectedRoute path={routes.dashboard.myStories.path} component={MyStories} />
                  <ProtectedRoute path={routes.dashboard.suggestions.show.path} component={SuggestionShow} />
                  <ProtectedRoute path={routes.dashboard.suggestions.path} component={SuggestionIndex} />
                  <ProtectedRoute path={routes.dashboard.favoriteStories.path} comp={FavoriteStories} />
                  <ProtectedRoute path={routes.dashboard.followedAuthors.path} comp={FollowedAuthors} />
                  <ProtectedRoute
                    path={routes.dashboard.path}
                    comp={Dashboard}
                    redirectMessage="Please sign in to view your dashboard"
                  />
                </Switch>
              </DashboardLayout>
            </Route>
            <Redirect to={routes.path} />
          </Switch>
        </DefaultLayout>
      </Router>
    </ErrorBoundary>
  );
};

export default App;
