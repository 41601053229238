import React from "react";
import { useRecoilValue } from "recoil";
import { userAtom } from "../../atoms/atoms";
import Message from "../../components/Message/Message";

const AdminDashboard: React.FC = () => {
  const userState = useRecoilValue(userAtom);
  console.log(userState);
  return (
    <div className="p-4">
      <Message />
      <h1>Test</h1>
    </div>
  );
};

export default AdminDashboard;
