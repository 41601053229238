import { Storage } from "aws-amplify";
import DOMPurify from "dompurify";
import SimpleMDE from "easymde";
import "easymde/dist/easymde.min.css";
import React, { useMemo } from "react";
import SimpleMDEReact from "react-simplemde-editor";
import generatePublicURL from "../../util/generatePublicURL";

interface Props {
  value: string;
  setValue: (string: string) => void;
  uploadLocation: string;
  spellCheck?: boolean;
}

const MarkDownEditor: React.FC<Props> = ({ value, setValue, uploadLocation, spellCheck }) => {
  const handleUploadImage = async (
    image: File,
    onSuccess: (imageUrl: string) => void,
    onError: (errorMessage: string) => void,
  ) => {
    const { name, size, type } = image;
    //Check file size
    if (size > 1024 * 1024 * 3) {
      onError("Image is too large. Please keep it under 3mb.");
      return;
    }
    //Check that it is an image
    if (!["image/png", "image/jpeg", "image/gif"].includes(type)) {
      onError("Please make sure the image is one of these types: PNG, JPG, GIF.");
      return;
    }

    try {
      const result = await Storage.put(`${uploadLocation}/${name}`, image, {
        contentType: type,
        cacheControl: "max-age=31536000",
      });
      const { key } = result;
      const imageURL = generatePublicURL(key);
      onSuccess(imageURL);
    } catch (e) {
      onError(e.message);
    }
  };

  const simpleMDEOptions = useMemo(() => {
    return {
      autofocus: false,
      spellChecker: spellCheck,
      uploadImage: true,
      placeholder: "Type here...",
      showIcons: ["heading-1", "heading-2", "heading-3", "code", "horizontal-rule", "clean-block", "upload-image"],
      hideIcons: ["heading", "image"],
      status: ["autosave", "lines", "words", "upload-image"],
      previewClass: "markdown",
      previewImagesInEditor: true,
      imageUploadFunction: handleUploadImage,
      renderingConfig: {
        singleLineBreaks: true,
        sanitizerFunction: function (renderedHTML) {
          return DOMPurify.sanitize(renderedHTML, {
            ALLOWED_TAGS: [
              "h1",
              "h2",
              "h3",
              "b",
              "br",
              "p",
              "code",
              "a",
              "img",
              "ul",
              "ol",
              "li",
              "blockquote",
              "em",
              "hr",
            ],
          });
        },
      },
    } as SimpleMDE.Options;
  }, [spellCheck]);

  return <SimpleMDEReact value={value} onChange={(value) => setValue(value)} options={simpleMDEOptions} />;
};

export default MarkDownEditor;
