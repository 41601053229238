import { API } from "aws-amplify";
import { useMutation } from "react-query";

export interface ApiError {
  data: null | string;
  errors: ErrorDetail[];
}

interface ErrorDetail {
  message: string;
  path: string | null;
}

interface ApiResponse {
  data: unknown;
}

function useApiMutation<Mutation, Input>(mutation: string) {
  const queryMutation = useMutation<Mutation, ApiError, Input>(async (input) => {
    const result = (await API.graphql({
      query: mutation,
      variables: { input },
    })) as ApiResponse;

    return result.data as Mutation;
  });

  return queryMutation;
}

export default useApiMutation;
