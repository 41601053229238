import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  Chapter,
  ChaptersByOrderQueryVariables,
  CreateChapterInput,
  ModelSortDirection,
  UpdateChapterInput,
  UpdateChapterMutation,
} from "../../API";
import useApiMutation from "../../api/useApiMutation";
import useListQuery from "../../api/useListQuery";
import * as mutations from "../../graphql/mutations";
import { chaptersByOrder } from "../../graphql/queries";
import { routes } from "../../ui/routes";
import ChapterDrawer from "./ChapterDrawer";

const ChapterList: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [chapters, setChapters] = useState<Chapter[]>([]);
  const [selectedChapter, setSelectedChapter] = useState<null | CreateChapterInput>(null);
  const [scrollPos, setScrollPos] = useState(0);

  //API INTERACTIONS
  const updateChapter = useApiMutation<UpdateChapterMutation, UpdateChapterInput>(mutations.updateChapter);
  const chaptersQuery = useListQuery<Chapter, ChaptersByOrderQueryVariables>(chaptersByOrder, "chaptersByOrder", {
    storyID: id,
    sortDirection: ModelSortDirection.ASC,
  });

  useEffect(() => {
    if (chaptersQuery.data) {
      setChapters(chaptersQuery.data);
    }
  }, [chaptersQuery.data]);

  function reorder(list: Chapter[], startIndex: number, endIndex: number) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.forEach((chapter, index) => {
      updateChapter.mutate({ id: chapter.id!, order: index });
    });
    return result;
  }

  function onDragEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const orderedChapters = reorder(chapters, result.source.index, result.destination.index);
    setChapters(orderedChapters);
  }

  const addChapter = () => {
    const newChapter: CreateChapterInput = {
      title: "",
      body: "",
      storyID: id,
      order: chapters.length,
      isPublished: false,
    };
    setSelectedChapter(newChapter);
  };

  const openDrawer = (chapter: Chapter) => {
    const scrollY = window.scrollY;
    setScrollPos(scrollY);
    document.body.style.top = `-${scrollY}px`;
    document.body.style.position = "fixed";
    document.body.style.paddingRight = "15px";
    setSelectedChapter(chapter);
    // const root = document.querySelector("#root");
    // root?.classList.add("h-screen", "overflow-y-hidden");
  };

  const closeDrawer = () => {
    setSelectedChapter(null);
    document.body.style.position = "";
    document.body.style.top = "";
    document.body.style.paddingRight = "";
    // const root = document.querySelector("#root");
    // root?.classList.remove("h-screen", "overflow-y-hidden");
    window.scroll(0, scrollPos);
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="flex justify-between items-center py-3">
          <p className="py-2">
            Chapters {updateChapter.isLoading && <span className="text-xs text-pink-600">Saving...</span>}
          </p>
          <button onClick={addChapter} className="btn-default">
            Add Chapter
          </button>
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {chapters.map((chapter, index) => (
                  <Draggable key={chapter.id} draggableId={chapter.id!} index={index}>
                    {(provided) => {
                      const publishClass = classNames(
                        "h-3 w-3 inline-block mr-2 rounded-full",
                        {
                          "bg-green-500": chapter.isPublished,
                        },
                        {
                          "bg-yellow-500": !chapter.isPublished,
                        },
                      );
                      return (
                        <div
                          className="flex border rounded shadow items-center bg-white mb-2 dark:bg-gray-800 dark:border-gray-600 overflow-hidden"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <span
                            className="bg-gray-100 border-r px-1 py-1.5 mr-2 text-gray-400 dark:bg-gray-800 dark:border-gray-600"
                            {...provided.dragHandleProps}
                          >
                            <DragIndicatorIcon />
                          </span>
                          <button
                            onClick={() => openDrawer(chapter)}
                            className="hover:text-pink-700 hover:underline flex items-center w-full"
                          >
                            <span
                              className={publishClass}
                              title={chapter.isPublished ? "Published" : "Not Published"}
                            ></span>

                            <p className="flex-grow text-left">{chapter.title}</p>
                          </button>
                          <Link
                            className="text-pink-600 hover:underline pr-2 flex items-center space-x-1"
                            target="_blank"
                            to={routes.stories.show.chapter.with({ id: id, chapterIndex: chapter.order })}
                          >
                            <span>Preview</span>
                            <OpenInNewIcon fontSize="small" />
                          </Link>
                        </div>
                      );
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      {selectedChapter && <ChapterDrawer chapter={selectedChapter} closeDrawer={closeDrawer} />}
    </>
  );
};

export default ChapterList;
