/* eslint-disable @typescript-eslint/no-explicit-any */
import { MultiDropdownList } from "@appbaseio/reactivesearch";
import { queryFormatSearch } from "@appbaseio/reactivesearch/lib/types";
import classNames from "classnames";
import React from "react";
import { reactiveList } from "./StoryFilters";

interface Props {
  name: string;
  showSearch?: boolean;
  dataField: string;
  queryFormat?: queryFormatSearch;
  transformData?: (...args: any[]) => any;
}

interface SkeletonProps {
  label?: string;
  noResults?: boolean;
}

const SkeletonFilter: React.FC<SkeletonProps> = ({ label, noResults = false }) => {
  const inputClasses = classNames(
    "h-[42px] bg-white border border-gray-300 mt-2 flex items-center text-sm pl-3 text-gray-400 dark:bg-gray-800 dark:border-gray-600",
    { "animate-pulse": !noResults },
  );
  return (
    <div>
      <p className="dark:text-white">{label}</p>
      <div className={inputClasses}>
        {noResults && "No Results"}
        {!noResults && "Loading..."}
      </div>
    </div>
  );
};

export const defaultQuery = () => ({
  query: {
    match: {
      isPublished: true,
    },
  },
});

const StoryFilterMultiDropdownList: React.FC<Props> = ({
  name,
  showSearch = false,
  dataField,
  queryFormat = "or",
  transformData,
}) => {
  return (
    <div className="h-20" key={queryFormat}>
      <MultiDropdownList
        componentId={name}
        dataField={dataField}
        defaultQuery={defaultQuery}
        title={name}
        showSearch={showSearch}
        showCount={true}
        queryFormat={queryFormat}
        transformData={transformData}
        loader={<SkeletonFilter label={name} />}
        react={{ and: reactiveList.filter((item) => item !== name) }}
        URLParams
        innerClass={{
          title: "dark:text-white",
          select: "input",
          list: "dark:bg-gray-800",
        }}
        renderError={(error: string) => (
          <div>
            Something went wrong!
            <br />
            Error details
            <br />
            {error}
          </div>
        )}
        renderNoResults={() => <SkeletonFilter label={name} noResults />}
      />
    </div>
  );
};

export default StoryFilterMultiDropdownList;
