import React, { useEffect, useState } from "react";

interface Props {
  initState: StoryCategory;
  buttonText?: string;
  onSubmit: (payload: StoryCategory) => void;
}

export interface StoryCategory {
  name: string;
  description: string;
}

const StoryCategoryForm: React.FC<Props> = ({ initState, buttonText, onSubmit }) => {
  const [state, setState] = useState<StoryCategory>({ name: "", description: "" });

  useEffect(() => {
    setState(initState);
  }, [initState]);

  return (
    <>
      <div className="flex flex-col">
        <label htmlFor="name" className="py-2">
          Name
        </label>
        <input
          name="name"
          className="input"
          value={state.name}
          onChange={(e) => setState({ ...state, name: e.target.value })}
        />
      </div>
      <div className="flex flex-col">
        <label htmlFor="description" className="py-2">
          Description
        </label>
        <textarea
          name="description"
          className="input"
          value={state.description}
          onChange={(e) => setState({ ...state, description: e.target.value })}
        ></textarea>
      </div>
      <button onClick={() => onSubmit(state)} className="mt-4 btn-default">
        {buttonText ? buttonText : "Save"}
      </button>
    </>
  );
};

export default StoryCategoryForm;
