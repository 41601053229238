import BookmarksTwoToneIcon from "@material-ui/icons/BookmarksTwoTone";
import React from "react";
import { Link } from "react-router-dom";
import { FaveStory, ListFaveStorysQueryVariables } from "../../API";
import useCurrentAuthor from "../../api/useCurrentAuthor";
import useListQuery from "../../api/useListQuery";
import { listFaveStorys } from "../../graphqlcustom/queries";
import { routes } from "../../ui/routes";
import DashboardCard from "./DashboardCard";

const ChapterUpdates: React.FC = () => {
  const currentAuthor = useCurrentAuthor();
  const query = useListQuery<FaveStory, ListFaveStorysQueryVariables>(
    listFaveStorys,
    "listFaveStorys",
    { filter: { authorID: { eq: currentAuthor?.id }, hasUnreadChapters: { eq: true } } },
    { enabled: !!currentAuthor, staleTime: 5 * 60 * 1000 },
  );

  return (
    <DashboardCard
      title="Chapter Updates"
      subtitle="New chapters from your favorite stories"
      query={query}
      emptyIcon={<BookmarksTwoToneIcon />}
      renderItem={(item) => {
        const unreadCount = item.totalChapters - item.readChapters;
        return (
          <div key="item.id" className="text-sm">
            <Link
              to={routes.stories.show.chapter.with({ id: item.storyID, chapterIndex: item.readChapters })}
              className="text-pink-600 hover:underline"
            >
              {item.Story.title}
            </Link>{" "}
            has {unreadCount} unread chapters
          </div>
        );
      }}
    />
  );
};

export default ChapterUpdates;
