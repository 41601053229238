/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAuthor = /* GraphQL */ `
  mutation CreateAuthor(
    $input: CreateAuthorInput!
    $condition: ModelAuthorConditionInput
  ) {
    createAuthor(input: $input, condition: $condition) {
      id
      name
      nameLowerCase
      cognitoUsername
      createdAt
      updatedAt
      owner
      FaveStories {
        nextToken
      }
      Ratings {
        nextToken
      }
      Stories {
        nextToken
      }
    }
  }
`;
export const updateAuthor = /* GraphQL */ `
  mutation UpdateAuthor(
    $input: UpdateAuthorInput!
    $condition: ModelAuthorConditionInput
  ) {
    updateAuthor(input: $input, condition: $condition) {
      id
      name
      nameLowerCase
      cognitoUsername
      createdAt
      updatedAt
      owner
      FaveStories {
        nextToken
      }
      Ratings {
        nextToken
      }
      Stories {
        nextToken
      }
    }
  }
`;
export const deleteAuthor = /* GraphQL */ `
  mutation DeleteAuthor(
    $input: DeleteAuthorInput!
    $condition: ModelAuthorConditionInput
  ) {
    deleteAuthor(input: $input, condition: $condition) {
      id
      name
      nameLowerCase
      cognitoUsername
      createdAt
      updatedAt
      owner
      FaveStories {
        nextToken
      }
      Ratings {
        nextToken
      }
      Stories {
        nextToken
      }
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      typename
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      typename
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      typename
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const createChapter = /* GraphQL */ `
  mutation CreateChapter(
    $input: CreateChapterInput!
    $condition: ModelChapterConditionInput
  ) {
    createChapter(input: $input, condition: $condition) {
      id
      title
      body
      authorNotesBefore
      authorNotesAfter
      isPublished
      order
      storyID
      createdAt
      updatedAt
      owner
      Story {
        id
        title
        description
        authorID
        categories
        rating
        growth
        shrink
        sizeRoles
        isComplete
        isPublished
        score
        hotScore
        chapterCount
        wordCount
        authorDisplayName
        lastChapterAdded
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const updateChapter = /* GraphQL */ `
  mutation UpdateChapter(
    $input: UpdateChapterInput!
    $condition: ModelChapterConditionInput
  ) {
    updateChapter(input: $input, condition: $condition) {
      id
      title
      body
      authorNotesBefore
      authorNotesAfter
      isPublished
      order
      storyID
      createdAt
      updatedAt
      owner
      Story {
        id
        title
        description
        authorID
        categories
        rating
        growth
        shrink
        sizeRoles
        isComplete
        isPublished
        score
        hotScore
        chapterCount
        wordCount
        authorDisplayName
        lastChapterAdded
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const deleteChapter = /* GraphQL */ `
  mutation DeleteChapter(
    $input: DeleteChapterInput!
    $condition: ModelChapterConditionInput
  ) {
    deleteChapter(input: $input, condition: $condition) {
      id
      title
      body
      authorNotesBefore
      authorNotesAfter
      isPublished
      order
      storyID
      createdAt
      updatedAt
      owner
      Story {
        id
        title
        description
        authorID
        categories
        rating
        growth
        shrink
        sizeRoles
        isComplete
        isPublished
        score
        hotScore
        chapterCount
        wordCount
        authorDisplayName
        lastChapterAdded
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      body
      delta
      authorID
      authorDisplayName
      storyID
      chapterID
      parentID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      body
      delta
      authorID
      authorDisplayName
      storyID
      chapterID
      parentID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      body
      delta
      authorID
      authorDisplayName
      storyID
      chapterID
      parentID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createFaveAuthor = /* GraphQL */ `
  mutation CreateFaveAuthor(
    $input: CreateFaveAuthorInput!
    $condition: ModelFaveAuthorConditionInput
  ) {
    createFaveAuthor(input: $input, condition: $condition) {
      id
      favedBy
      authorID
      createdAt
      updatedAt
      Author {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const updateFaveAuthor = /* GraphQL */ `
  mutation UpdateFaveAuthor(
    $input: UpdateFaveAuthorInput!
    $condition: ModelFaveAuthorConditionInput
  ) {
    updateFaveAuthor(input: $input, condition: $condition) {
      id
      favedBy
      authorID
      createdAt
      updatedAt
      Author {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const deleteFaveAuthor = /* GraphQL */ `
  mutation DeleteFaveAuthor(
    $input: DeleteFaveAuthorInput!
    $condition: ModelFaveAuthorConditionInput
  ) {
    deleteFaveAuthor(input: $input, condition: $condition) {
      id
      favedBy
      authorID
      createdAt
      updatedAt
      Author {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const createFaveAuthorStoryUpdate = /* GraphQL */ `
  mutation CreateFaveAuthorStoryUpdate(
    $input: CreateFaveAuthorStoryUpdateInput!
    $condition: ModelFaveAuthorStoryUpdateConditionInput
  ) {
    createFaveAuthorStoryUpdate(input: $input, condition: $condition) {
      id
      storyID
      authorID
      isNew
      createdAt
      updatedAt
      Author {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      owner
      Story {
        id
        title
        description
        authorID
        categories
        rating
        growth
        shrink
        sizeRoles
        isComplete
        isPublished
        score
        hotScore
        chapterCount
        wordCount
        authorDisplayName
        lastChapterAdded
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const updateFaveAuthorStoryUpdate = /* GraphQL */ `
  mutation UpdateFaveAuthorStoryUpdate(
    $input: UpdateFaveAuthorStoryUpdateInput!
    $condition: ModelFaveAuthorStoryUpdateConditionInput
  ) {
    updateFaveAuthorStoryUpdate(input: $input, condition: $condition) {
      id
      storyID
      authorID
      isNew
      createdAt
      updatedAt
      Author {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      owner
      Story {
        id
        title
        description
        authorID
        categories
        rating
        growth
        shrink
        sizeRoles
        isComplete
        isPublished
        score
        hotScore
        chapterCount
        wordCount
        authorDisplayName
        lastChapterAdded
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const deleteFaveAuthorStoryUpdate = /* GraphQL */ `
  mutation DeleteFaveAuthorStoryUpdate(
    $input: DeleteFaveAuthorStoryUpdateInput!
    $condition: ModelFaveAuthorStoryUpdateConditionInput
  ) {
    deleteFaveAuthorStoryUpdate(input: $input, condition: $condition) {
      id
      storyID
      authorID
      isNew
      createdAt
      updatedAt
      Author {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      owner
      Story {
        id
        title
        description
        authorID
        categories
        rating
        growth
        shrink
        sizeRoles
        isComplete
        isPublished
        score
        hotScore
        chapterCount
        wordCount
        authorDisplayName
        lastChapterAdded
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const createFaveStory = /* GraphQL */ `
  mutation CreateFaveStory(
    $input: CreateFaveStoryInput!
    $condition: ModelFaveStoryConditionInput
  ) {
    createFaveStory(input: $input, condition: $condition) {
      id
      storyID
      authorID
      totalChapters
      readChapters
      hasUnreadChapters
      createdAt
      updatedAt
      Author {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      owner
      Story {
        id
        title
        description
        authorID
        categories
        rating
        growth
        shrink
        sizeRoles
        isComplete
        isPublished
        score
        hotScore
        chapterCount
        wordCount
        authorDisplayName
        lastChapterAdded
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const updateFaveStory = /* GraphQL */ `
  mutation UpdateFaveStory(
    $input: UpdateFaveStoryInput!
    $condition: ModelFaveStoryConditionInput
  ) {
    updateFaveStory(input: $input, condition: $condition) {
      id
      storyID
      authorID
      totalChapters
      readChapters
      hasUnreadChapters
      createdAt
      updatedAt
      Author {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      owner
      Story {
        id
        title
        description
        authorID
        categories
        rating
        growth
        shrink
        sizeRoles
        isComplete
        isPublished
        score
        hotScore
        chapterCount
        wordCount
        authorDisplayName
        lastChapterAdded
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const deleteFaveStory = /* GraphQL */ `
  mutation DeleteFaveStory(
    $input: DeleteFaveStoryInput!
    $condition: ModelFaveStoryConditionInput
  ) {
    deleteFaveStory(input: $input, condition: $condition) {
      id
      storyID
      authorID
      totalChapters
      readChapters
      hasUnreadChapters
      createdAt
      updatedAt
      Author {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      owner
      Story {
        id
        title
        description
        authorID
        categories
        rating
        growth
        shrink
        sizeRoles
        isComplete
        isPublished
        score
        hotScore
        chapterCount
        wordCount
        authorDisplayName
        lastChapterAdded
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const createMention = /* GraphQL */ `
  mutation CreateMention(
    $input: CreateMentionInput!
    $condition: ModelMentionConditionInput
  ) {
    createMention(input: $input, condition: $condition) {
      id
      mentionerID
      mentionedID
      mentionedIDandStatus
      commentID
      status
      storyID
      chapterID
      mentionText
      createdAt
      updatedAt
      Mentioner {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      Chapter {
        id
        title
        body
        authorNotesBefore
        authorNotesAfter
        isPublished
        order
        storyID
        createdAt
        updatedAt
        owner
      }
      Comment {
        id
        body
        delta
        authorID
        authorDisplayName
        storyID
        chapterID
        parentID
        createdAt
        updatedAt
        owner
      }
      owner
      Story {
        id
        title
        description
        authorID
        categories
        rating
        growth
        shrink
        sizeRoles
        isComplete
        isPublished
        score
        hotScore
        chapterCount
        wordCount
        authorDisplayName
        lastChapterAdded
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const updateMention = /* GraphQL */ `
  mutation UpdateMention(
    $input: UpdateMentionInput!
    $condition: ModelMentionConditionInput
  ) {
    updateMention(input: $input, condition: $condition) {
      id
      mentionerID
      mentionedID
      mentionedIDandStatus
      commentID
      status
      storyID
      chapterID
      mentionText
      createdAt
      updatedAt
      Mentioner {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      Chapter {
        id
        title
        body
        authorNotesBefore
        authorNotesAfter
        isPublished
        order
        storyID
        createdAt
        updatedAt
        owner
      }
      Comment {
        id
        body
        delta
        authorID
        authorDisplayName
        storyID
        chapterID
        parentID
        createdAt
        updatedAt
        owner
      }
      owner
      Story {
        id
        title
        description
        authorID
        categories
        rating
        growth
        shrink
        sizeRoles
        isComplete
        isPublished
        score
        hotScore
        chapterCount
        wordCount
        authorDisplayName
        lastChapterAdded
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const deleteMention = /* GraphQL */ `
  mutation DeleteMention(
    $input: DeleteMentionInput!
    $condition: ModelMentionConditionInput
  ) {
    deleteMention(input: $input, condition: $condition) {
      id
      mentionerID
      mentionedID
      mentionedIDandStatus
      commentID
      status
      storyID
      chapterID
      mentionText
      createdAt
      updatedAt
      Mentioner {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      Chapter {
        id
        title
        body
        authorNotesBefore
        authorNotesAfter
        isPublished
        order
        storyID
        createdAt
        updatedAt
        owner
      }
      Comment {
        id
        body
        delta
        authorID
        authorDisplayName
        storyID
        chapterID
        parentID
        createdAt
        updatedAt
        owner
      }
      owner
      Story {
        id
        title
        description
        authorID
        categories
        rating
        growth
        shrink
        sizeRoles
        isComplete
        isPublished
        score
        hotScore
        chapterCount
        wordCount
        authorDisplayName
        lastChapterAdded
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const createRating = /* GraphQL */ `
  mutation CreateRating(
    $input: CreateRatingInput!
    $condition: ModelRatingConditionInput
  ) {
    createRating(input: $input, condition: $condition) {
      id
      storyID
      authorID
      rating
      createdAt
      updatedAt
      Author {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      owner
      Story {
        id
        title
        description
        authorID
        categories
        rating
        growth
        shrink
        sizeRoles
        isComplete
        isPublished
        score
        hotScore
        chapterCount
        wordCount
        authorDisplayName
        lastChapterAdded
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const updateRating = /* GraphQL */ `
  mutation UpdateRating(
    $input: UpdateRatingInput!
    $condition: ModelRatingConditionInput
  ) {
    updateRating(input: $input, condition: $condition) {
      id
      storyID
      authorID
      rating
      createdAt
      updatedAt
      Author {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      owner
      Story {
        id
        title
        description
        authorID
        categories
        rating
        growth
        shrink
        sizeRoles
        isComplete
        isPublished
        score
        hotScore
        chapterCount
        wordCount
        authorDisplayName
        lastChapterAdded
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const deleteRating = /* GraphQL */ `
  mutation DeleteRating(
    $input: DeleteRatingInput!
    $condition: ModelRatingConditionInput
  ) {
    deleteRating(input: $input, condition: $condition) {
      id
      storyID
      authorID
      rating
      createdAt
      updatedAt
      Author {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      owner
      Story {
        id
        title
        description
        authorID
        categories
        rating
        growth
        shrink
        sizeRoles
        isComplete
        isPublished
        score
        hotScore
        chapterCount
        wordCount
        authorDisplayName
        lastChapterAdded
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const createReadStory = /* GraphQL */ `
  mutation CreateReadStory(
    $input: CreateReadStoryInput!
    $condition: ModelReadStoryConditionInput
  ) {
    createReadStory(input: $input, condition: $condition) {
      id
      storyID
      authorID
      maxChapterIndex
      createdAt
      updatedAt
      Author {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      owner
      Story {
        id
        title
        description
        authorID
        categories
        rating
        growth
        shrink
        sizeRoles
        isComplete
        isPublished
        score
        hotScore
        chapterCount
        wordCount
        authorDisplayName
        lastChapterAdded
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const updateReadStory = /* GraphQL */ `
  mutation UpdateReadStory(
    $input: UpdateReadStoryInput!
    $condition: ModelReadStoryConditionInput
  ) {
    updateReadStory(input: $input, condition: $condition) {
      id
      storyID
      authorID
      maxChapterIndex
      createdAt
      updatedAt
      Author {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      owner
      Story {
        id
        title
        description
        authorID
        categories
        rating
        growth
        shrink
        sizeRoles
        isComplete
        isPublished
        score
        hotScore
        chapterCount
        wordCount
        authorDisplayName
        lastChapterAdded
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const deleteReadStory = /* GraphQL */ `
  mutation DeleteReadStory(
    $input: DeleteReadStoryInput!
    $condition: ModelReadStoryConditionInput
  ) {
    deleteReadStory(input: $input, condition: $condition) {
      id
      storyID
      authorID
      maxChapterIndex
      createdAt
      updatedAt
      Author {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      owner
      Story {
        id
        title
        description
        authorID
        categories
        rating
        growth
        shrink
        sizeRoles
        isComplete
        isPublished
        score
        hotScore
        chapterCount
        wordCount
        authorDisplayName
        lastChapterAdded
        createdAt
        updatedAt
        owner
      }
    }
  }
`;
export const createStory = /* GraphQL */ `
  mutation CreateStory(
    $input: CreateStoryInput!
    $condition: ModelStoryConditionInput
  ) {
    createStory(input: $input, condition: $condition) {
      id
      title
      description
      authorID
      categories
      rating
      growth
      shrink
      sizeRoles
      isComplete
      isPublished
      score
      hotScore
      chapterCount
      wordCount
      authorDisplayName
      lastChapterAdded
      createdAt
      updatedAt
      Author {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      Chapters {
        nextToken
      }
      FaveStories {
        nextToken
      }
      Ratings {
        nextToken
      }
      owner
    }
  }
`;
export const updateStory = /* GraphQL */ `
  mutation UpdateStory(
    $input: UpdateStoryInput!
    $condition: ModelStoryConditionInput
  ) {
    updateStory(input: $input, condition: $condition) {
      id
      title
      description
      authorID
      categories
      rating
      growth
      shrink
      sizeRoles
      isComplete
      isPublished
      score
      hotScore
      chapterCount
      wordCount
      authorDisplayName
      lastChapterAdded
      createdAt
      updatedAt
      Author {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      Chapters {
        nextToken
      }
      FaveStories {
        nextToken
      }
      Ratings {
        nextToken
      }
      owner
    }
  }
`;
export const deleteStory = /* GraphQL */ `
  mutation DeleteStory(
    $input: DeleteStoryInput!
    $condition: ModelStoryConditionInput
  ) {
    deleteStory(input: $input, condition: $condition) {
      id
      title
      description
      authorID
      categories
      rating
      growth
      shrink
      sizeRoles
      isComplete
      isPublished
      score
      hotScore
      chapterCount
      wordCount
      authorDisplayName
      lastChapterAdded
      createdAt
      updatedAt
      Author {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      Chapters {
        nextToken
      }
      FaveStories {
        nextToken
      }
      Ratings {
        nextToken
      }
      owner
    }
  }
`;
export const createSuggestion = /* GraphQL */ `
  mutation CreateSuggestion(
    $input: CreateSuggestionInput!
    $condition: ModelSuggestionConditionInput
  ) {
    createSuggestion(input: $input, condition: $condition) {
      id
      toID
      authorID
      storyID
      chapterID
      body
      status
      message
      reply
      createdAt
      updatedAt
      Author {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      Chapter {
        id
        title
        body
        authorNotesBefore
        authorNotesAfter
        isPublished
        order
        storyID
        createdAt
        updatedAt
        owner
      }
      Story {
        id
        title
        description
        authorID
        categories
        rating
        growth
        shrink
        sizeRoles
        isComplete
        isPublished
        score
        hotScore
        chapterCount
        wordCount
        authorDisplayName
        lastChapterAdded
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const updateSuggestion = /* GraphQL */ `
  mutation UpdateSuggestion(
    $input: UpdateSuggestionInput!
    $condition: ModelSuggestionConditionInput
  ) {
    updateSuggestion(input: $input, condition: $condition) {
      id
      toID
      authorID
      storyID
      chapterID
      body
      status
      message
      reply
      createdAt
      updatedAt
      Author {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      Chapter {
        id
        title
        body
        authorNotesBefore
        authorNotesAfter
        isPublished
        order
        storyID
        createdAt
        updatedAt
        owner
      }
      Story {
        id
        title
        description
        authorID
        categories
        rating
        growth
        shrink
        sizeRoles
        isComplete
        isPublished
        score
        hotScore
        chapterCount
        wordCount
        authorDisplayName
        lastChapterAdded
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
export const deleteSuggestion = /* GraphQL */ `
  mutation DeleteSuggestion(
    $input: DeleteSuggestionInput!
    $condition: ModelSuggestionConditionInput
  ) {
    deleteSuggestion(input: $input, condition: $condition) {
      id
      toID
      authorID
      storyID
      chapterID
      body
      status
      message
      reply
      createdAt
      updatedAt
      Author {
        id
        name
        nameLowerCase
        cognitoUsername
        createdAt
        updatedAt
        owner
      }
      Chapter {
        id
        title
        body
        authorNotesBefore
        authorNotesAfter
        isPublished
        order
        storyID
        createdAt
        updatedAt
        owner
      }
      Story {
        id
        title
        description
        authorID
        categories
        rating
        growth
        shrink
        sizeRoles
        isComplete
        isPublished
        score
        hotScore
        chapterCount
        wordCount
        authorDisplayName
        lastChapterAdded
        createdAt
        updatedAt
        owner
      }
      owner
    }
  }
`;
