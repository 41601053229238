const removeTagFromString = (text: string, tag: string, keepInnerText: boolean) => {
  const textLength = text.length;
  //Start tag
  const startTag = `<${tag.toLowerCase()}>`;
  const startTagLength = startTag.length;
  const startTagIndex = text.indexOf(startTag);

  //End tag
  const endTag = `</${tag.toLowerCase()}>`;
  const endTagLength = endTag.length;
  const endTagIndex = text.indexOf(endTag);
  const innerText = keepInnerText ? text.slice(startTagIndex + startTagLength, endTagIndex) : "";

  return text.slice(0, startTagIndex) + innerText + text.slice(endTagIndex + endTagLength, textLength);
};

export default removeTagFromString;
