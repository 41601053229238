import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { NavLink, useParams } from "react-router-dom";
import {
  Chapter,
  CreateReadStoryInput,
  CreateReadStoryMutation,
  ReadStory,
  UpdateReadStoryInput,
  UpdateReadStoryMutation,
} from "../../API";
import useApiMutation from "../../api/useApiMutation";
import useCurrentAuthor from "../../api/useCurrentAuthor";
import useGetQuery from "../../api/useGetQuery";
import * as mutations from "../../graphql/mutations";
import { getReadStory } from "../../graphql/queries";
import { routes } from "../../ui/routes";

interface Props {
  chapters: Chapter[];
}
const ChapterNavigation: React.FC<Props> = ({ chapters }) => {
  const queryClient = useQueryClient();
  const { id, chapterIndex } = useParams<{ id: string; chapterIndex?: string }>();
  const currentAuthor = useCurrentAuthor();
  const compositeID = id + currentAuthor?.id;
  const readQuery = useGetQuery<ReadStory>(getReadStory, "getReadStory", compositeID, {
    enabled: !!currentAuthor && !!id,
  });
  const createReadStory = useApiMutation<CreateReadStoryMutation, CreateReadStoryInput>(mutations.createReadStory);
  const updateReadStory = useApiMutation<UpdateReadStoryMutation, UpdateReadStoryInput>(mutations.updateReadStory);

  useEffect(() => {
    if (readQuery.data === undefined || chapterIndex === undefined || !currentAuthor || !currentAuthor.id) {
      return;
    }

    //Create ReadStory Record
    if (readQuery.data === null) {
      createReadStory.mutate(
        {
          id: compositeID,
          authorID: currentAuthor.id,
          storyID: id,
          maxChapterIndex: parseInt(chapterIndex),
        },
        {
          onSuccess: (data) => {
            queryClient.setQueryData(["getReadStory", compositeID], data.createReadStory);
            queryClient.invalidateQueries("readStoriesByAuthor");
          },
        },
      );
      return;
    }

    //Update ReadStory Record
    if (
      readQuery.data &&
      readQuery.data.maxChapterIndex !== undefined &&
      readQuery.data.maxChapterIndex < parseInt(chapterIndex)
    ) {
      updateReadStory.mutate(
        { id: compositeID, maxChapterIndex: parseInt(chapterIndex) },
        {
          onSuccess: (data) => {
            queryClient.setQueryData(["getReadStory", compositeID], data.updateReadStory);
            queryClient.invalidateQueries("readStoriesByAuthor");
          },
        },
      );
      return;
    }
  }, [readQuery.data, chapterIndex]);

  return (
    <>
      <h2 className="font-bold border-b dark:border-gray-600 py-2">Chapters ({chapters?.length})</h2>
      {chapters?.map((chapter) => {
        const isUnread = Boolean(chapter.order && readQuery.data && chapter.order > readQuery.data.maxChapterIndex!);
        return (
          <div key={chapter.id}>
            <NavLink
              className="hover:text-pink-600 hover:underline flex space-x-2 items-center"
              to={routes.stories.show.chapter.with({ id: id, chapterIndex: chapter.order })}
              activeClassName="text-pink-600"
            >
              {currentAuthor && (
                <div className="-mt-0.5">
                  {isUnread ? (
                    <RadioButtonUncheckedIcon style={{ fontSize: 16 }} />
                  ) : (
                    <CheckCircleIcon style={{ fontSize: 16 }} />
                  )}
                </div>
              )}
              <div>{chapter.title}</div>
            </NavLink>
          </div>
        );
      })}
    </>
  );
};

export default ChapterNavigation;
