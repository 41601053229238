import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { CreateStoryInput, CreateStoryMutation } from "../../API";
import useApiMutation from "../../api/useApiMutation";
import useCurrentAuthor from "../../api/useCurrentAuthor";
import * as mutations from "../../graphql/mutations";
import { routes } from "../../ui/routes";

const NewStory: React.FC = () => {
  const history = useHistory();
  const currentAuthor = useCurrentAuthor();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const createStory = useApiMutation<CreateStoryMutation, CreateStoryInput>(mutations.createStory);
  const addStory = async () => {
    if (!currentAuthor || !currentAuthor.name) {
      alert("Must be logged in to add a story");
      return;
    }
    createStory.mutate(
      {
        authorID: currentAuthor?.id,
        title: title,
        description: description,
        score: 0,
        isPublished: false,
        authorDisplayName: currentAuthor.name,
      },
      {
        onSuccess: (data) => history.push(routes.dashboard.myStories.edit.with({ id: data.createStory?.id })),
      },
    );
  };
  return (
    <div className=" max-w-screen-lg mx-auto mt-10 px-4">
      <div>
        <Link to={routes.dashboard.myStories.path} className="flex items-center text-pink-600 py-2">
          <ArrowBackIosIcon fontSize="small" />
          My Stories
        </Link>
        <h1 className="text-3xl">New Story</h1>
        <div className="bg-gray-50 rounded-lg shadow-lg p-8 my-8">
          <div className="flex flex-col">
            <label htmlFor="title" className="py-2">
              Title
            </label>
            <input
              name="title"
              className="border p-2 rounded shadow-inner"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="description" className="py-2">
              Description
            </label>
            <textarea
              rows={6}
              name="description"
              className="border p-2 rounded shadow-inner"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </div>
          <div className="flex items-center justify-center py-12 text-center">
            <div>
              <button onClick={addStory} className="btn-primary btn-lg">
                Save Story and Start Adding Chapters
              </button>
              <p className="text-gray-600 italic text-center mt-4">
                Don't worry, your story will remain unpublished until you are ready
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewStory;
