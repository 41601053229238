import React from "react";

const Donate: React.FC = () => {
  return (
    <>
      <div className=" flex justify-center items-center">
        <div className="py-40">
          <h1 className="text-3xl">Support Giantess.land</h1>
          <p className="py-6">Please consider donating to help with hosting costs to keep the site ad free!</p>
          <a
            className="donate-with-crypto rounded shadow p-3 bg-pink-600 text-white"
            href="https://commerce.coinbase.com/checkout/648cb65e-c48b-411f-900d-8a9d9ab4c6a6"
          >
            Donate with Crypto
          </a>
          <script src="https://commerce.coinbase.com/v1/checkout.js?version=201807"></script>
        </div>
      </div>
    </>
  );
};

export default Donate;
