import classNames from "classnames";
import { useEffect, useState } from "react";
import { Author, ListAuthorsQueryVariables } from "../../API";
import useListQuery from "../../api/useListQuery";
import { listAuthors } from "../../graphql/queries";

interface Props {
  onSave: (name: string, nameIsTaken: boolean) => void;
}

const AuthorNameForm: React.FC<Props> = ({ onSave }) => {
  const [name, setName] = useState("");
  const authorQuery = useListQuery<Author, ListAuthorsQueryVariables>(
    listAuthors,
    "listAuthors",
    {
      filter: { nameLowerCase: { eq: name.toLowerCase() } },
    },
    {
      cacheTime: 0,
    },
  );
  const nameIsTaken = authorQuery.data && authorQuery.data.length > 0;

  useEffect(() => {
    authorQuery.refetch();
  }, [name]);

  const inputClasses = classNames("input", { "border-red-600 bg-red-50 dark:bg-red-900": nameIsTaken });

  return (
    <>
      <div className="flex flex-col py-2">
        <label htmlFor="displayName" className="py-2 font-bold text-sm">
          Display Name
        </label>
        <input name="displayName" className={inputClasses} value={name} onChange={(e) => setName(e.target.value)} />
      </div>
      <p className="py-3 text-sm text-red-600">{nameIsTaken && <>Sorry, this name is already taken</>}</p>

      <button
        onClick={() => onSave(name, Boolean(nameIsTaken))}
        className="btn-primary"
        disabled={!name || nameIsTaken || authorQuery.isFetching}
      >
        {authorQuery.isFetching ? "Loading..." : "Save"}
      </button>
    </>
  );
};

export default AuthorNameForm;
