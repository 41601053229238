import { cloneDeep, reverse } from "lodash";
import { getIdMapping } from "./getIdMapping";

export function createTree<T extends { parentID?: string | null; createdAt?: string; id?: string; children?: T[] }>(
  array: ReadonlyArray<T>,
) {
  const idMapping = getIdMapping<T>([...array]);
  const root = [] as T[] & { children?: T[] };
  const data = cloneDeep(array);

  data.forEach((el) => {
    // Handle the root elements
    if (el.parentID === null || el.parentID === undefined) {
      root.push(el);
      return;
    }
    // Use our mapping to locate the parent element in our data array
    const parentEl = data[idMapping[el.parentID]];
    // Add our current el to its parent's `children` array
    parentEl.children = [...(parentEl.children || []), el];
  });

  return reverse(root);
}
