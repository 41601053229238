import React from "react";
import { Link } from "react-router-dom";
import { Story } from "../../API";
import useCurrentAuthor from "../../api/useCurrentAuthor";
import useListQuery from "../../api/useListQuery";
import StoryCard, { LinkTo } from "../../components/StoryList/StoryList";
import { listStorys } from "../../graphql/queries";
import { routes } from "../../ui/routes";

const MyStories: React.FC = () => {
  const currentAuthor = useCurrentAuthor();
  const query = useListQuery<Story>(
    listStorys,
    "listStorys",
    { filter: { authorID: { eq: currentAuthor?.id } } },
    { enabled: !!currentAuthor },
  );

  return (
    <div className="p-4">
      <div className="pt-3.5 flex justify-between">
        <h1 className="text-lg font-bold">My Stories</h1>
        <Link to={routes.dashboard.myStories.new.path} className="border rounded py-1 px-2">
          Add Story
        </Link>
      </div>
      <div>{query.data && <StoryCard data={query.data} linkTo={LinkTo.Edit} />}</div>
    </div>
  );
};

export default MyStories;
