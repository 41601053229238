import React from "react";
import { useSetRecoilState } from "recoil";
import { Category, ModelSortDirection } from "../../API";
import useListQuery from "../../api/useListQuery";
import { modalAtom } from "../../atoms/atoms";
import { categoriesSrtByName } from "../../graphql/queries";
import StoryCategoryEdit from "./StoryCategoryEdit";
import StoryCategoryNew from "./StoryCategoryNew";

const StoryCategoryIndex: React.FC = () => {
  const setModal = useSetRecoilState(modalAtom);

  const query = useListQuery<Category>(categoriesSrtByName, "categoriesSrtByName", {
    typename: "Category",
    sortDirection: ModelSortDirection.ASC,
  });

  return (
    <div className="p-4">
      <div className="flex justify-between pt-3.5">
        <h1 className="text-lg font-bold">Story Categories</h1>
        <button onClick={() => setModal(<StoryCategoryNew />)} className="btn-default">
          Add Category
        </button>
      </div>
      {query.data?.map((cat) => (
        <div key={cat.id}>
          <button
            onClick={() => setModal(<StoryCategoryEdit cat={cat} />)}
            className="hover:underline text-pink-600 block"
          >
            {cat.name}
          </button>
          <p className="text-sm text-gray-700  dark:text-gray-300">{cat.description}</p>
        </div>
      ))}
    </div>
  );
};

export default StoryCategoryIndex;
