import classNames from "classnames";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useQueryClient } from "react-query";
import { useParams } from "react-router";
import rehypeRaw from "rehype-raw";
import diff from "rich-text-diff";
import {
  Suggestion,
  SUGGESTION_STATUS,
  UpdateChapterInput,
  UpdateChapterMutation,
  UpdateSuggestionInput,
  UpdateSuggestionMutation,
} from "../../API";
import useApiMutation from "../../api/useApiMutation";
import useGetQuery from "../../api/useGetQuery";
import MarkDownEditor from "../../components/MarkDownEditor/MarkDownEditor";
import * as mutations from "../../graphql/mutations";
import { getSuggestion } from "../../graphql/queries";
import { routes } from "../../ui/routes";
import history from "../../util/history";
import removeTagFromString from "../../util/removeTagFromString";

enum MODE {
  suggestion,
  preview,
}

const SuggestionShow: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const [result, setResult] = useState("");
  const [preview, setPreview] = useState("");
  const [mode, setMode] = useState<MODE>(MODE.suggestion);
  const [reply, setReply] = useState("");
  const [replyIsDiff, setReplyIsDiff] = useState(false);
  const suggestionQuery = useGetQuery<Suggestion>(getSuggestion, "getSuggestion", id);

  const updateChapter = useApiMutation<UpdateChapterMutation, UpdateChapterInput>(mutations.updateChapter);
  const updateSuggestion = useApiMutation<UpdateSuggestionMutation, UpdateSuggestionInput>(mutations.updateSuggestion);

  const updateReply = () => {
    updateSuggestion.mutate(
      { id, reply },
      {
        onSuccess: (data) => {
          queryClient.setQueryData(["getSuggestion", id], data.updateSuggestion);
        },
      },
    );
  };

  const reject = () => {
    updateSuggestion.mutate(
      { id, status: SUGGESTION_STATUS.REJECTED },
      {
        onSuccess: (data) => {
          queryClient.setQueryData(["getSuggestion", id], data.updateSuggestion);
          queryClient.invalidateQueries("suggestionsByToID");
          history.push(routes.dashboard.suggestions.path);
        },
      },
    );
  };

  const publishChapter = () => {
    if (!suggestionQuery.data?.chapterID) return;
    updateSuggestion.mutate(
      { id, status: SUGGESTION_STATUS.MERGED, reply },
      {
        onSuccess: (data) => {
          queryClient.setQueryData(["getSuggestion", id], data.updateSuggestion);
        },
      },
    );
    updateChapter.mutate(
      { id: suggestionQuery.data?.chapterID, body: preview },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries("chaptersByOrder");
          queryClient.invalidateQueries("suggestionsByToID");
          queryClient.invalidateQueries("getSuggestion");
          history.push(routes.dashboard.suggestions.path);
        },
      },
    );
  };

  useEffect(() => {
    setReplyIsDiff(reply !== suggestionQuery.data?.reply);
  }, [reply, suggestionQuery.data]);

  useEffect(() => {
    const original = suggestionQuery.data?.Chapter?.body;
    const suggestion = suggestionQuery.data?.body;
    if (!!original && !!suggestion) {
      setResult(diff(original, suggestion));
    }
    if (suggestionQuery.data?.reply) {
      setReply(suggestionQuery.data?.reply);
    }
  }, [suggestionQuery.data]);

  const toggleElement = (el: HTMLElement) => {
    if (!el) return;
    if (el.style && el.style.textDecoration === "line-through") {
      el.style.textDecoration = "";
      el.style.opacity = "1";
    } else {
      el.style.textDecoration = "line-through";
      el.style.opacity = ".5";
    }
  };

  const handleClick = (e: any) => {
    const currentElement = e.target;
    const previousElement = currentElement.previousSibling;
    const nextElement = currentElement.nextSibling;
    //Toggle Element display
    if (currentElement) {
      toggleElement(currentElement);
    }
    if (previousElement && previousElement.tagName === "DEL") {
      toggleElement(previousElement);
    }
    if (nextElement && nextElement.tagName === "INS") {
      toggleElement(nextElement);
    }
  };

  const installEventListeners = (suggestionElements: NodeListOf<Element>) => {
    for (let i = 0; i < suggestionElements.length; i++) {
      const el = suggestionElements[i] as HTMLElement;
      el.addEventListener("click", handleClick);
      el.title = "Toggle suggestion";
      if (el.tagName === "DEL") {
        el.style.textDecoration = "line-through";
        el.style.opacity = ".5";
      }
    }
  };

  const removeEventListeners = (suggestionElements: NodeListOf<Element>) => {
    for (let i = 0; i < suggestionElements.length; i++) {
      const el = suggestionElements[i];
      el.removeEventListener("click", handleClick);
    }
  };

  useEffect(() => {
    const suggestionElements = document.querySelectorAll("del,ins");
    if (result) {
      installEventListeners(suggestionElements);
    }
    return () => {
      removeEventListeners(suggestionElements);
    };
  }, [result]);

  const switchView = (mode: MODE) => {
    setMode(mode);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const merge = () => {
    let merged = result;
    const suggestionElements = document.querySelectorAll("del,ins");

    for (let i = 0; i < suggestionElements.length; i++) {
      const el = suggestionElements[i] as HTMLElement;
      merged = removeTagFromString(merged, el.tagName, el.style.textDecoration !== "line-through");
    }
    setPreview(merged);
    switchView(MODE.preview);
  };

  const previewClass = classNames({ hidden: mode !== MODE.preview });
  const suggestionClass = classNames({ hidden: mode !== MODE.suggestion });

  return (
    <>
      <h1 className="px-4 py-4 font-bold text-2xl">
        {suggestionQuery.data?.Story?.title} - {suggestionQuery.data?.Chapter?.title}
      </h1>
      <div className="flex flex-col xl:flex-row">
        <div className="px-4 lg:w-2/3">
          <div className={previewClass}>
            <div className="pb-4">
              <h1 className="font-bold text-lg">Preview Merged Document</h1>
              <p className="text-sm text-gray-600 dark:text-gray-400">
                Make sure everything looks good before publishing your updates, especially formatting
              </p>
            </div>
            <div className="not-sticky">
              <MarkDownEditor
                value={preview}
                setValue={setPreview}
                uploadLocation={`chapters/${suggestionQuery.data?.chapterID}`}
                spellCheck={false}
              />
            </div>
          </div>
          <div className={suggestionClass}>
            <div className="flex justify-between pb-4">
              <div>
                <h1 className="font-bold text-lg">Approve/Deny Suggestions Below</h1>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  Click the suggestions below to toggle them between approved/denied.
                </p>
              </div>
              <div>
                {suggestionQuery.data?.status === SUGGESTION_STATUS.PENDING && (
                  <button onClick={reject} className="btn-default">
                    Reject Suggestion
                  </button>
                )}
              </div>
            </div>
            <div className="markdown">
              <ReactMarkdown
                allowedElements={[
                  "strong",
                  "em",
                  "p",
                  "h1",
                  "h2",
                  "h3",
                  "pre",
                  "code",
                  "blockquote",
                  "ul",
                  "ol",
                  "li",
                  "img",
                  "a",
                  "hr",
                  "ins",
                  "del",
                ]}
                rehypePlugins={[rehypeRaw]}
              >
                {result}
              </ReactMarkdown>
            </div>
          </div>
        </div>
        <div className="p-4 w-full xl:w-1/3">
          <div className="border rounded p-4">
            <h3 className="font-bold">Message from {suggestionQuery.data?.Author?.name}</h3>
            <p className="text-sm ">{suggestionQuery.data?.message}</p>
          </div>
          <div className="border rounded p-4 mt-4">
            <h3 className="font-bold">Write a Reply</h3>
            <textarea
              className="input  w-full my-2"
              rows={6}
              value={reply}
              onChange={(e) => setReply(e.target.value)}
            ></textarea>
            <div className="flex justify-between">
              <span className="text-sm text-gray-600 dark:text-gray-400 italic">
                {replyIsDiff && "Please save your response"}
              </span>
              <button disabled={!replyIsDiff} onClick={updateReply} className="btn-primary">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="p-4">
        <div className="flex justify-between w-2/3">
          {mode === MODE.preview && (
            <>
              <button onClick={() => switchView(MODE.suggestion)} className="btn-default btn-lg">
                Back to Suggestion View
              </button>
              <button onClick={publishChapter} className="btn-primary btn-lg mr-2">
                Publish Updated Chapter
              </button>
            </>
          )}
          {mode === MODE.suggestion && (
            <div>
              <button onClick={merge} className="btn-primary btn-lg">
                Preview Merged Results
              </button>
              <p className="mt-2 text-sm italic text-gray-500">Preview merged document and make manual edits</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SuggestionShow;
