import React from "react";

interface Props {
  rows: number;
}

const SkeletonStoryCard: React.FC<Props> = ({ rows }) => {
  const data = Array.from(Array(rows).keys());
  const spanClasses = (additionalClasses: string) =>
    `inline-block bg-pink-100 dark:bg-gray-500 rounded animate-pulse mr-1 ${additionalClasses}`;
  return (
    <div className="grid grid-flow-row grid-cols-1 gap-4 mt-4 pt-8">
      {data.map((item: unknown, index: number) => (
        <div key={index} className="border-t pt-4">
          <div className="font-bold">
            <span className={spanClasses("h-5 w-36")}></span>
            <span className={spanClasses("h-5 w-4")}></span>
            <span className={spanClasses("h-5 w-20")}></span>
          </div>

          <p className=" max-w-screen-md text-sm text-gray-600 ">
            <span className={spanClasses("h-4 w-14")}></span>
            <span className={spanClasses("h-4 w-16")}></span>
            <span className={spanClasses("h-4 w-4")}></span>
            <span className={spanClasses("h-4 w-36")}></span>
            <span className={spanClasses("h-4 w-20")}></span>
            <span className={spanClasses("h-4 w-12")}></span>
            <span className={spanClasses("h-4 w-6")}></span>
            <span className={spanClasses("h-4 w-20")}></span>
            <span className={spanClasses("h-4 w-5")}></span>
            <span className={spanClasses("h-4 w-20")}></span>
            <span className={spanClasses("h-4 w-12")}></span>
            <span className={spanClasses("h-4 w-8")}></span>
            <span className={spanClasses("h-4 w-20")}></span>
            <span className={spanClasses("h-4 w-36")}></span>
            <span className={spanClasses("h-4 w-5")}></span>
            <span className={spanClasses("h-4 w-12")}></span>
            <span className={spanClasses("h-4 w-36")}></span>
            <span className={spanClasses("h-4 w-4")}></span>
            <span className={spanClasses("h-4 w-20")}></span>
            <span className={spanClasses("h-4 w-12")}></span>
            <span className={spanClasses("h-4 w-36")}></span>
            <span className={spanClasses("h-4 w-20")}></span>
            <span className={spanClasses("h-4 w-12")}></span>
            <span className={spanClasses("h-4 w-36")}></span>
            <span className={spanClasses("h-4 w-4")}></span>
            <span className={spanClasses("h-4 w-20")}></span>
            <span className={spanClasses("h-4 w-20")}></span>
            <span className={spanClasses("h-4 w-12")}></span>
          </p>
          <div className="flex space-x-10">
            <div className="text-gray-500 text-xs flex flex-col py-2 space-y-0.5">
              <p>
                <span className={spanClasses("h-4 w-10")}></span>
                <span className={spanClasses("h-4 w-6")}></span>
              </p>
              <p>
                <span className={spanClasses("h-4 w-14")}></span>
                <span className={spanClasses("h-4 w-20")}></span>
              </p>
              <p>
                <span className={spanClasses("h-4 w-12")}></span>
                <span className={spanClasses("h-4 w-20")}></span>
              </p>
            </div>
            <div className="text-gray-500 text-xs flex flex-col py-2 space-y-0.5">
              <p>
                <span className={spanClasses("h-4 w-14")}></span>
                <span className={spanClasses("h-4 w-6")}></span>
              </p>
              <p>
                <span className={spanClasses("h-4 w-10")}></span>
                <span className={spanClasses("h-4 w-6")}></span>
              </p>
              <p>
                <span className={spanClasses("h-4 w-8")}></span>
                <span className={spanClasses("h-4 w-6")}></span>
              </p>
            </div>
            <div className="text-gray-500 text-xs flex flex-col py-2 space-y-0.5">
              <p>
                <span className={spanClasses("h-4 w-12")}></span>
                <span className={spanClasses("h-4 w-20")}></span>
              </p>
              <p>
                <span className={spanClasses("h-4 w-12")}></span>
                <span className={spanClasses("h-4 w-16")}></span>
              </p>
            </div>
          </div>
          <div className="flex space-x-1.5 items-center py-2">
            <span className={spanClasses("h-5 w-10")}></span>
            <span className={spanClasses("h-5 w-12")}></span>
            <span className={spanClasses("h-5 w-20")}></span>
            <span className={spanClasses("h-5 w-14")}></span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SkeletonStoryCard;
