import React from "react";
import { Link } from "react-router-dom";
import { routes } from "../../ui/routes";
import ChapterUpdates from "./ChapterUpdates";
import PendingSuggestions from "./PendingSuggestions";
import StoryUpdates from "./StoryUpdates";

const Dashboard: React.FC = () => {
  return (
    <div className="p-4">
      <div className="pt-3.5 flex justify-between">
        <h1 className="text-lg font-bold">Dashboard</h1>
        <Link to={routes.dashboard.myStories.new.path} className="btn-primary">
          Add Story
        </Link>
      </div>
      <div className="grid xl:grid-cols-2 grid-flow-row gap-4 py-4">
        <ChapterUpdates />
        <StoryUpdates />
        <PendingSuggestions />
      </div>
    </div>
  );
};

export default Dashboard;
