/* eslint-disable jsx-a11y/anchor-is-valid */

import { AuthState } from "@aws-amplify/ui-components";
import AccountCircleTwoToneIcon from "@material-ui/icons/AccountCircleTwoTone";
import CollectionsBookmarkTwoToneIcon from "@material-ui/icons/CollectionsBookmarkTwoTone";
import ExploreTwoToneIcon from "@material-ui/icons/ExploreTwoTone";
import MenuBookTwoToneIcon from "@material-ui/icons/MenuBookTwoTone";
import classNames from "classnames";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import useCurrentAuthor from "../api/useCurrentAuthor";
import { authAtom, isAdminAtom, userAtom } from "../atoms/atoms";
import DarkModeSwitch from "../components/DarkModeSwitch/DarkModeSwitch";
import { routes } from "../ui/routes";
import NavigationLink from "./NavigationLink";

interface Props {
  children: React.ReactNode;
}

const DefaultLayout: React.FC<Props> = ({ children }) => {
  const [t] = useTranslation();
  const authState = useRecoilValue(authAtom);
  const user = useRecoilValue(userAtom);
  const currentAuthor = useCurrentAuthor();
  const isAdmin = useRecoilValue(isAdminAtom);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);

  const navClasses = classNames("p-2 rounded hover:bg-gray-200 dark:hover:bg-gray-900 transition duration-300");
  return (
    <div>
      <header className="bg-white dark:bg-gray-800 px-3   shadow border-b dark:border-gray-600 fixed top-0 w-full z-10">
        <nav className="flex justify-between py-3">
          <div className="flex space-x-2">
            <Link to={routes.root.path} className={navClasses}>
              <CollectionsBookmarkTwoToneIcon className="text-pink-700 mr-2" />
              {t("common.siteName")}
            </Link>
            <NavigationLink to={routes.dashboard.path}>
              <ExploreTwoToneIcon />
              <span>Dashboard</span>
            </NavigationLink>
            <NavigationLink to={routes.stories.path}>
              <MenuBookTwoToneIcon />
              <span>Stories</span>
            </NavigationLink>
          </div>
          <div className="flex space-x-2 items-center">
            <NavigationLink to={routes.donate.path}>
              <span>Support The Site</span>
            </NavigationLink>
            {isAdmin && (
              <NavigationLink to={routes.admin.path}>
                <span>Admin Setup</span>
              </NavigationLink>
            )}
            {authState !== AuthState.SignedIn && (
              <Link to={routes.authenticate.path} className="py-2 px-2 bg-pink-700 rounded text-white shadow">
                Login/Register
              </Link>
            )}
            {authState === AuthState.SignedIn && user && (
              <NavigationLink to={routes.profile.path}>
                <span>{currentAuthor?.name}</span>
                <AccountCircleTwoToneIcon />
              </NavigationLink>
            )}
            <DarkModeSwitch />
          </div>
        </nav>
      </header>
      <div className="pt-16 min-h-screen">{children}</div>
      <footer className="py-10 bg-gray-700 dark:bg-gray-900 text-white border-t dark:border-gray-600">
        <p className="text-sm max-w-screen-md text-center  text-gray-300 mx-auto px-12">
          Disclaimer: All publicly recognizable characters, settings, etc. are the property of their respective owners.
          The original characters and plot are the property of the author. No money is being made from this work. No
          copyright infringement is intended.
        </p>
      </footer>
    </div>
  );
};

export default DefaultLayout;
