import classNames from "classnames";
import moment from "moment";
import React from "react";
import { Comment } from "../../API";
import CommentForm from "./CommentForm";

interface Props {
  comment: Comment & {
    children?: Comment[];
  };
  chapterID: string;
  showForm: boolean;
}

const CommentItem: React.FC<Props> = ({ comment, chapterID, showForm }) => {
  const rootClasses = classNames({ "border rounded dark:border-gray-600": showForm });
  const cardClasses = classNames({ "p-3 bg-white dark:bg-gray-700 ": showForm });

  return (
    <div className={rootClasses}>
      <div className={cardClasses}>
        <div className="flex justify-between">
          <div className="text-sm font-bold">
            {comment.authorDisplayName} · {moment(comment.createdAt).fromNow()}
          </div>
        </div>
        <div className="ql-snow">
          <div
            className="text-sm py-2 break-words ql-editor pl-0"
            dangerouslySetInnerHTML={{ __html: comment.body! }}
          ></div>
        </div>
      </div>

      {/* CHILDREN */}
      {comment.children && (
        <div className="bg-gray-50 dark:bg-gray-800 p-4 border-t dark:border-gray-600 flex flex-col space-y-4">
          {comment.children.map((child) => (
            <div className="">
              <CommentItem
                key={child.id}
                comment={{ ...child, __typename: "Comment" }}
                chapterID={chapterID}
                showForm={false}
              />
            </div>
          ))}{" "}
        </div>
      )}
      {showForm && (
        <div className="bg-gray-50  dark:bg-gray-800 dark:border-gray-600 border-t p-4">
          <CommentForm chapterID={chapterID} parentID={comment.id} showForm={showForm} rootForm={false} />
        </div>
      )}
    </div>
  );
};

export default CommentItem;
