import React from "react";
import { Story } from "../../API";
import useListQuery from "../../api/useListQuery";
import { listStorys } from "../../graphql/queries";

const Recent: React.FC = () => {
  const query = useListQuery<Story>(listStorys, "listStorys", {});

  return (
    <div className="container mt-10">
      <h1 className="text-3xl">Recent</h1>
      {query.data?.map((story) => (
        <div key={story.id}>
          <h2 className="font-bold">{story.title}</h2>
          <p>{story.description}</p>
        </div>
      ))}
    </div>
  );
};

export default Recent;
