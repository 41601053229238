export const displayRange = (values?: string[] | null) => {
  if (!values) {
    return "none";
  }
  const firstValue = values[0];
  const lastValue = values[values.length - 1];
  if (firstValue === lastValue) {
    return firstValue;
  }
  return `${firstValue} to ${lastValue}`;
};
