interface Props {
  label: string;
  checked: boolean;
  onChange: (value: boolean) => void;
  className?: string;
}

const ToggleButton: React.FC<Props> = ({ label, checked, onChange, className }) => {
  return (
    <label className={`flex items-center space-x-3 cursor-pointer ${className}`}>
      <input
        type="checkbox"
        name={label}
        checked={checked}
        onChange={() => onChange(!checked)}
        className="form-tick appearance-none h-6 w-6 border  border-gray-300 dark:border-gray-600 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none cursor-pointer"
      />
      <span className="text-gray-900 dark:text-gray-300">{label}</span>
    </label>
  );
};

export default ToggleButton;
