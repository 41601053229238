/* eslint-disable react-hooks/exhaustive-deps */
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteTwoToneIcon from "@material-ui/icons/FavoriteTwoTone";
import classNames from "classnames";
import React, { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router";
import { useSetRecoilState } from "recoil";
import {
  CreateFaveStoryInput,
  CreateFaveStoryMutation,
  DeleteFaveStoryInput,
  DeleteFaveStoryMutation,
  FaveStory,
  UpdateFaveStoryInput,
  UpdateFaveStoryMutation,
} from "../../API";
import useApiMutation from "../../api/useApiMutation";
import useCurrentAuthor from "../../api/useCurrentAuthor";
import useGetQuery from "../../api/useGetQuery";
import { modalAtom } from "../../atoms/atoms";
import * as mutations from "../../graphql/mutations";
import { getFaveStory } from "../../graphql/queries";
import Authenticator from "../Authenticator/Authenticator";

interface Props {
  totalChapterCount: number;
}

const FaveStoryButton: React.FC<Props> = ({ totalChapterCount }) => {
  const queryClient = useQueryClient();
  const { id, chapterIndex } = useParams<{ id: string; chapterIndex: string }>();
  const readChapters = parseInt(chapterIndex) + 1;
  const setModal = useSetRecoilState(modalAtom);

  const currentAuthor = useCurrentAuthor();
  const compositeID = `${id}#${currentAuthor ? currentAuthor.id : ""}`;
  const faveStoryQuery = useGetQuery<FaveStory>(getFaveStory, "getFaveStory", compositeID, {
    enabled: !!currentAuthor && !!id,
  });

  const createFave = useApiMutation<CreateFaveStoryMutation, CreateFaveStoryInput>(mutations.createFaveStory);
  const updateFave = useApiMutation<UpdateFaveStoryMutation, UpdateFaveStoryInput>(mutations.updateFaveStory);
  const deleteFave = useApiMutation<DeleteFaveStoryMutation, DeleteFaveStoryInput>(mutations.deleteFaveStory);

  const isFave = Boolean(faveStoryQuery.data);

  const invalidateQueries = () => {
    queryClient.invalidateQueries("listFaveStorys");
    queryClient.invalidateQueries("getFaveStory");
    queryClient.invalidateQueries("faveStoriesByAuthor");
  };

  const toggleFave = () => {
    if (!currentAuthor || !currentAuthor.id) {
      setModal(<Authenticator isModal={true} />);
      return;
    }
    if (!isFave) {
      createFave.mutate(
        {
          id: compositeID,
          storyID: id,
          authorID: currentAuthor.id,
          totalChapters: totalChapterCount,
          readChapters: readChapters,
          hasUnreadChapters: totalChapterCount > readChapters,
        },
        {
          onSuccess: () => invalidateQueries(),
        },
      );
    } else {
      deleteFave.mutate(
        { id: compositeID },
        {
          onSuccess: () => invalidateQueries(),
        },
      );
    }
  };

  //Update the read count if current chapter is greater than what is saved
  useEffect(() => {
    if (!isFave || !faveStoryQuery.data || !faveStoryQuery.data.readChapters) {
      return;
    }
    if (readChapters > faveStoryQuery.data.readChapters) {
      updateFave.mutate(
        {
          id: compositeID,
          readChapters: readChapters,
          hasUnreadChapters: totalChapterCount > readChapters,
        },
        {
          onSuccess: () => {
            invalidateQueries();
          },
        },
      );
    }
  }, [isFave, faveStoryQuery.data, readChapters]);

  const buttonClasses = classNames("flex-1", {
    "text-red-600": isFave,
  });

  return (
    <button onClick={toggleFave} className={buttonClasses}>
      {isFave ? <FavoriteIcon /> : <FavoriteTwoToneIcon />}
    </button>
  );
};
export default FaveStoryButton;
