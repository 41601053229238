import { capitalize } from "lodash";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Suggestion, SuggestionsByToIDQueryVariables, SUGGESTION_STATUS } from "../../API";
import useCurrentAuthor from "../../api/useCurrentAuthor";
import useListQuery from "../../api/useListQuery";
import { suggestionsByToID } from "../../graphqlcustom/queries";
import { routes } from "../../ui/routes";

const SuggestionIndex: React.FC = () => {
  const currentAuthor = useCurrentAuthor();
  const [filterStatus, setFilterStatus] = useState(SUGGESTION_STATUS.PENDING);

  const query = useListQuery<Suggestion, SuggestionsByToIDQueryVariables>(
    suggestionsByToID,
    "suggestionsByToID",
    { toID: currentAuthor?.id || "", filter: { status: { eq: filterStatus } } },
    { enabled: !!currentAuthor?.id },
  );

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilterStatus(e.target.value as SUGGESTION_STATUS);
  };

  return (
    <div className="p-4">
      <div className="pt-3.5 h-12 flex justify-between items-center">
        <h1 className="text-lg font-bold">{capitalize(filterStatus)} Suggestions</h1>
        <select className="input w-32 h-12" onBlur={handleFilterChange} onChange={handleFilterChange}>
          {Object.keys(SUGGESTION_STATUS).map((status) => (
            <option value={status}>{capitalize(status)}</option>
          ))}
        </select>
      </div>
      <div className="border-t pt-4 mt-4">
        {query.data &&
          query.data.map((suggestion) => (
            <div key={suggestion.id}>
              <Link
                className="text-pink-600 hover:underline"
                to={routes.dashboard.suggestions.show.with({ id: suggestion.id })}
              >
                {suggestion.Story?.title} has a suggestion
              </Link>{" "}
              from {suggestion.Author?.name}
              <p>{suggestion?.message}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default SuggestionIndex;
