import Brightness2TwoToneIcon from "@material-ui/icons/Brightness2TwoTone";
import WbSunnyTwoToneIcon from "@material-ui/icons/WbSunnyTwoTone";
import React, { useState } from "react";

const DarkModeSwitch: React.FC = () => {
  const [theme, setTheme] = useState<string>(localStorage.theme);
  const toggle = () => {
    const theme = localStorage.theme;
    if (theme === "dark") {
      localStorage.theme = "light";
      document.body.classList.remove("dark");
      setTheme("light");
    } else {
      localStorage.theme = "dark";
      document.body.classList.add("dark");
      setTheme("dark");
    }
  };
  return (
    <div>
      {/* <button onClick={toggle}>Toggle</button> */}
      <button
        onClick={toggle}
        className="transition duration-500 ease-in-out rounded-full p-2"
        title="Toggle dark mode"
      >
        {theme === "dark" ? <WbSunnyTwoToneIcon /> : <Brightness2TwoToneIcon />}
      </button>
    </div>
  );
};

export default DarkModeSwitch;
