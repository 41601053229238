import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router";
import { FaveAuthorStoryUpdate, UpdateFaveAuthorStoryUpdateInput, UpdateFaveAuthorStoryUpdateMutation } from "../API";
import useApiMutation from "../api/useApiMutation";
import useCurrentAuthor from "../api/useCurrentAuthor";
import useGetQuery from "../api/useGetQuery";
import { updateFaveAuthorStoryUpdate } from "../graphql/mutations";
import { getFaveAuthorStoryUpdate } from "../graphql/queries";

const useFaveAuthorStoryUpdate = () => {
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const currentAuthor = useCurrentAuthor();
  const compositeID = `${currentAuthor ? currentAuthor.id : ""}#${id}`;
  const query = useGetQuery<FaveAuthorStoryUpdate>(getFaveAuthorStoryUpdate, "getFaveAuthorStoryUpdate", compositeID, {
    enabled: !!currentAuthor && !!id,
  });
  const update = useApiMutation<UpdateFaveAuthorStoryUpdateMutation, UpdateFaveAuthorStoryUpdateInput>(
    updateFaveAuthorStoryUpdate,
  );

  //Mark FaveAuthorStoryUpdate as not new if any found
  useEffect(() => {
    if (query.data && query.data.isNew) {
      update.mutate({ id: compositeID, isNew: false });
      queryClient.invalidateQueries("listFaveAuthorStoryUpdates");
      queryClient.invalidateQueries("getFaveAuthorStoryUpdate");
    }
  }, [query.data, compositeID]);
};

export default useFaveAuthorStoryUpdate;
