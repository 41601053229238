import EditTwoToneIcon from "@material-ui/icons/EditTwoTone";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import rehypeRaw from "rehype-raw";
import { Chapter, ChaptersByOrderQueryVariables, ModelSortDirection, Story } from "../../API";
import useCurrentAuthor from "../../api/useCurrentAuthor";
import useGetQuery from "../../api/useGetQuery";
import useListQuery from "../../api/useListQuery";
import { isAdminAtom } from "../../atoms/atoms";
import Comments from "../../components/Comments/Comments";
import { chaptersByOrder, getStory } from "../../graphql/queries";
import useFaveAuthorStoryUpdate from "../../hooks/useFaveAuthorStoryUpdate";
import { routes } from "../../ui/routes";
import ChapterNavigation from "./ChapterNavigation";
import FaveStoryButton from "./FaveStoryButton";
import StoryRating from "./StoryRating";

const StoryShow: React.FC = () => {
  useFaveAuthorStoryUpdate();
  const history = useHistory();
  const isAdmin = useRecoilValue(isAdminAtom);
  const currentAuthor = useCurrentAuthor();
  const { id, chapterIndex } = useParams<{ id: string; chapterIndex?: string }>();
  const prevIndex = parseInt(chapterIndex ?? "0") - 1;
  const nextIndex = parseInt(chapterIndex ?? "-1") + 1;
  const [chapter, setChapter] = useState<null | Chapter>(null);
  //Redirect to first chapter if no chapter is passed
  if (!chapterIndex) {
    history.replace(routes.stories.show.chapter.with({ id: id, chapterIndex: 0 }));
  }

  const storyQuery = useGetQuery<Story>(getStory, "getStory", id);
  const chaptersQuery = useListQuery<Chapter, ChaptersByOrderQueryVariables>(chaptersByOrder, "chaptersByOrder", {
    storyID: id,
    sortDirection: ModelSortDirection.ASC,
    filter: { isPublished: { eq: true } },
  });

  useEffect(() => {
    if (chaptersQuery.data && chapterIndex) {
      setChapter(chaptersQuery.data[parseInt(chapterIndex)]);
    }
  }, [chapterIndex, chaptersQuery.data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [chapterIndex]);

  const changeChapter = (delta: number) => {
    const newIndex = !chapter || !chapter.order ? 1 : chapter.order + delta;
    history.push(routes.stories.show.chapter.with({ id: id, chapterIndex: newIndex }));
  };

  if (!storyQuery.data || storyQuery.isLoading || !chaptersQuery.data) {
    return <div className="flex items-center justify-center">Loading...</div>;
  }

  const canEditStory = (!!currentAuthor && currentAuthor.id === storyQuery.data?.authorID) || isAdmin;

  return (
    <div className="">
      <div className="flex">
        <div className=" side-bar">
          {canEditStory && (
            <Link
              className="btn-default btn-lg flex space-x-2 justify-center"
              to={routes.dashboard.myStories.edit.with({ id: id })}
            >
              <span>Edit Story</span>
              <EditTwoToneIcon />
            </Link>
          )}
          <ChapterNavigation chapters={chaptersQuery.data} />
        </div>
        <div className="p-4 w-full">
          <div className="h-14 flex justify-between items-center">
            <h1 className="text-lg font-bold">
              {storyQuery.data.title} - {chapter?.title}{" "}
              <span className="text-sm font-normal">
                by{" "}
                <Link
                  to={routes.authors.show.with({ id: storyQuery.data.authorID })}
                  className="text-pink-600 hover:underline"
                >
                  {storyQuery.data.authorDisplayName}
                </Link>
              </span>
            </h1>
          </div>
          <div className="flex items-start space-x-4">
            <div className="w-full  max-w-screen-md">
              <div className="markdown">
                {chapter && chapter.body && (
                  <ReactMarkdown
                    allowedElements={[
                      "strong",
                      "em",
                      "p",
                      "h1",
                      "h2",
                      "h3",
                      "pre",
                      "code",
                      "blockquote",
                      "ul",
                      "ol",
                      "li",
                      "img",
                      "a",
                      "hr",
                      "ins",
                      "del",
                    ]}
                    rehypePlugins={[rehypeRaw]}
                  >
                    {chapter.body!}
                  </ReactMarkdown>
                )}
              </div>
              {chapterIndex && chaptersQuery.data && (
                <div className="flex justify-between">
                  <button
                    onClick={() => {
                      changeChapter(-1);
                    }}
                    className="btn-default text-lg"
                    disabled={prevIndex < 0}
                  >
                    Prev
                  </button>
                  <button
                    onClick={() => {
                      changeChapter(1);
                    }}
                    className="btn-default text-lg"
                    disabled={nextIndex > chaptersQuery.data.length - 1}
                  >
                    Next
                  </button>
                </div>
              )}
              {chapter && chapter.id && (
                <div className="mt-4 p-2 flex items-center justify-between border border-pink-600 bg-pink-50 dark:bg-gray-700 rounded">
                  <p className="text-sm text-pink-700 dark:text-white italic">
                    If you noticed any typos, issues, etc, channel your inner editor and make a suggestion!
                  </p>
                  <Link to={routes.chapters.show.suggestion.with({ id: chapter.id })} className="btn-default btn-lg">
                    Make a Suggestion!
                  </Link>
                </div>
              )}
              {chapter && chapter.id && <Comments chapterID={chapter.id} />}
            </div>
            <div className="sticky top-[5rem] flex flex-col space-y-2">
              <div className="flex flex-col space-y-3 p-4 w-16 border dark:border-gray-600 rounded ">
                <StoryRating />
                <FaveStoryButton totalChapterCount={chaptersQuery.data.length} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoryShow;
