/* eslint-disable @typescript-eslint/no-explicit-any */
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded";
import ArrowUpwardRoundedIcon from "@material-ui/icons/ArrowUpwardRounded";
import classNames from "classnames";
import React, { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router";
import { useSetRecoilState } from "recoil";
import {
  CreateRatingInput,
  CreateRatingMutation,
  DeleteRatingInput,
  DeleteRatingMutation,
  FaveStory,
  Rating,
  Story,
  UpdateRatingInput,
  UpdateRatingMutation,
} from "../../API";
import useApiMutation from "../../api/useApiMutation";
import useCurrentAuthor from "../../api/useCurrentAuthor";
import useGetQuery from "../../api/useGetQuery";
import { modalAtom } from "../../atoms/atoms";
import * as mutations from "../../graphql/mutations";
import { getFaveStory, getRating, getStory } from "../../graphql/queries";
import Authenticator from "../Authenticator/Authenticator";

const StoryRating: React.FC = () => {
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string; chapterIndex?: string }>();
  const currentAuthor = useCurrentAuthor();
  const compositeID = `${id}#${currentAuthor ? currentAuthor.id : ""}`;
  const setModal = useSetRecoilState(modalAtom);

  const storyQuery = useGetQuery<Story>(getStory, "getStory", id);
  const ratingQuery = useGetQuery<Rating>(getRating, "getRating", compositeID, {
    enabled: !!currentAuthor && !!id,
  });
  const createRating = useApiMutation<CreateRatingMutation, CreateRatingInput>(mutations.createRating);
  const updateRating = useApiMutation<UpdateRatingMutation, UpdateRatingInput>(mutations.updateRating);
  const deleteRating = useApiMutation<DeleteRatingMutation, DeleteRatingInput>(mutations.deleteRating);

  const hasVoted = Boolean(ratingQuery.data);
  const userRating =
    hasVoted && ratingQuery && ratingQuery.data && ratingQuery.data.rating ? ratingQuery.data.rating : 0;
  const isPositive = userRating > 0;
  const isNegative = userRating < 0;

  const faveStoryQuery = useGetQuery<FaveStory>(getFaveStory, "getFaveStory", compositeID, {
    enabled: !!currentAuthor && !!id,
  });

  const vote = (rating: number) => {
    if (!currentAuthor || !currentAuthor.id) {
      setModal(<Authenticator isModal={true} />);
      return;
    }
    //If they click on button they already clicked, delete the vote
    if (rating === userRating) {
      deleteRating.mutate(
        { id: compositeID },
        {
          onSuccess: () => optimisticallyUpdateRating(rating * -1),
        },
      );
      return;
    }

    //Update if they are changing there vote
    if (hasVoted) {
      updateRating.mutate(
        { id: compositeID, rating: rating },
        {
          onSuccess: () => optimisticallyUpdateRating(rating * 2),
        },
      );
      return;
    }

    //Create a new rating
    createRating.mutate(
      { id: compositeID, rating: rating, storyID: id, authorID: currentAuthor.id },
      {
        onSuccess: () => optimisticallyUpdateRating(rating),
      },
    );
  };

  function optimisticallyUpdateRating(adjustment: number) {
    queryClient.setQueryData<Story>(["getStory", id], (old: any) => ({ ...old, score: old.score + adjustment }));
    ratingQuery.refetch();
  }

  // If user faves story, ensure rating is positive
  useEffect(() => {
    if (faveStoryQuery.data && (ratingQuery?.data?.rating || 0) <= 0) {
      vote(1);
    }
  }, [faveStoryQuery.data, ratingQuery.data]);

  const upVoteClass = classNames({ "text-green-600": isPositive });
  const downVoteClass = classNames({ "text-red-600": isNegative });

  return (
    <>
      <button onClick={() => vote(1)} className={upVoteClass}>
        <ArrowUpwardRoundedIcon />
      </button>
      <span className="text-center">{storyQuery.data && storyQuery.data.score}</span>
      <button onClick={() => vote(-1)} className={downVoteClass}>
        <ArrowDownwardRoundedIcon />
      </button>
    </>
  );
};

export default StoryRating;
