import React from "react";
import { FaveStory, Story } from "../../API";
import useCurrentAuthor from "../../api/useCurrentAuthor";
import useListQuery from "../../api/useListQuery";
import StoryList, { LinkTo } from "../../components/StoryList/StoryList";
import { listFaveWithFullStory } from "../../graphqlcustom/queries";

const FavoriteStories: React.FC = () => {
  const currentAuthor = useCurrentAuthor();
  const query = useListQuery<FaveStory>(
    listFaveWithFullStory,
    "listFaveStorys",
    { filter: { authorID: { eq: currentAuthor?.id } } },
    { enabled: !!currentAuthor },
  );
  const storys: Story[] = !query.data ? [] : (query.data.map((fave) => ({ ...fave.Story })) as Story[]);
  const filteredStorys = storys.filter((story) => story.isPublished);

  return (
    <div className="p-4">
      <div className="pt-3.5 h-12 flex justify-between">
        <h1 className="text-lg font-bold">Favorite Stories</h1>
      </div>
      <div>{query.data && <StoryList data={filteredStorys} linkTo={LinkTo.Show} />}</div>
    </div>
  );
};

export default FavoriteStories;
